<template>
  <div v-html="description" />
</template>
<script setup lang="ts">
import { computed, inject } from 'vue';
import { i18n } from 'i18next';
import { getNameForWhSize } from '@/helpers/formattingHelper.js';
const intl: i18n = inject('i18n') as i18n;

interface Props {
  data: any;
}
const props = defineProps<Props>();

const description = computed(() =>
  intl.t(
    `FINDING.${props.data.CATEGORY}.TYPES.${props.data.INFO.type}.DESCRIPTION`,
    {
      entity: `${props.data.ROOT_CAUSE_ENTITY}`,
      current:
        props.data?.INFO?.current ||
        getNameForWhSize(props.data?.INFO['Current WH Size']),
      optimal:
        props.data?.INFO?.optimal ||
        getNameForWhSize(props.data?.INFO['Optimal WH Size']),
    }
  )
);
</script>
