<template>
  <div
    ref="toast"
    v-if="show"
    tabindex="0"
    @keyup.esc="close"
    class="z-50 absolute top-14 right-5 flex p-4 mb-4 rounded-lg"
    role="alert"
    :class="color.background"
  >
    <IconSuccess
      v-if="type === ToastType.SUCCESS"
      class="flex-shrink-0 w-5 h-5"
      :class="color.text"
    />
    <IconError
      v-else-if="type === ToastType.ERROR"
      class="flex-shrink-0 w-5 h-5"
      :class="color.text"
    />
    <IconWarning
      v-else-if="type === ToastType.WARNING"
      class="flex-shrink-0 w-5 h-5"
      :class="color.text"
    />
    <IconInfo v-else class="flex-shrink-0 w-5 h-5" :class="color.text" />
    <div
      class="ml-3 text-sm font-medium md:min-w-[250px] min-w-0"
      :class="color.text"
    >
      {{ message }}
    </div>
    <button
      @click="close"
      type="button"
      class="ml-2 -mx-1.5 -my-1.5 rounded-lg focus:ring-2 p-1.5 inline-flex h-8 w-8"
      :class="color.button"
      aria-label="Close"
    >
      <svg
        aria-hidden="true"
        class="w-5 h-5"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
          clip-rule="evenodd"
        ></path>
      </svg>
    </button>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { ToastType } from '@/types';
import { type, show, message } from '@/stores/toastState.js';
import IconError from '@/components/icons/IconError.vue';
import IconInfo from '@/components/icons/IconInfo.vue';
import IconSuccess from '@/components/icons/IconSuccess.vue';
import IconWarning from '@/components/icons/IconWarning.vue';

function open() {
  show.value = true;
}

function close() {
  show.value = false;
}

const color = computed(() => {
  if (type.value === ToastType.ERROR) {
    return {
      background: ['bg-red-100', 'dark:bg-red-20'],
      text: ['text-red-700', 'dark:text-red-800'],
      button: [
        'bg-red-100',
        'text-red-500',
        'focus:ring-red-400',
        'hover:bg-red-200',
        'dark:bg-red-200',
        'dark:text-red-600',
        'dark:hover:bg-red-300',
      ],
    };
  } else if (type.value === ToastType.WARNING) {
    return {
      background: ['bg-yellow-100', 'dark:bg-yellow-200'],
      text: ['text-yellow-700', 'dark:text-yellow-800'],
      button: [
        'bg-yellow-100',
        'text-yellow-500',
        'focus:ring-yellow-400',
        'hover:bg-yellow-200',
        'dark:bg-yellow-200',
        'dark:text-yellow-600',
        'dark:hover:bg-yellow-300',
      ],
    };
  } else if (type.value === ToastType.SUCCESS) {
    return {
      background: ['bg-green-100', 'dark:bg-green-200'],
      text: ['text-green-700', 'dark:text-green-800'],
      button: [
        'bg-green-100',
        'text-green-500',
        'focus:ring-green-400',
        'hover:bg-green-200',
        'dark:bg-green-200',
        'dark:text-green-600',
        'dark:hover:bg-green-300',
      ],
    };
  } else if (type.value === ToastType.INFO) {
    return {
      background: ['bg-blue-100', 'dark:bg-blue-200'],
      text: ['text-blue-700', 'dark:text-blue-800'],
      button: [
        'bg-blue-100',
        'text-blue-500',
        'focus:ring-blue-400',
        'hover:bg-blue-200',
        'dark:bg-blue-200',
        'dark:text-blue-600',
        'dark:hover:bg-blue-300',
      ],
    };
  }
  return {
    // default
    background: ['bg-gray-100', 'dark:bg-gray-700'],
    text: ['text-gray-700', 'dark:text-gray-300'],
    button: [
      'bg-gray-100',
      'text-gray-500',
      'focus:ring-gray-400',
      'hover:bg-gray-200',
      'dark:bg-gray-700',
      'dark:text-gray-300',
      'dark:hover:bg-gray-800',
      'dark:hover:text-white',
    ],
  };
});
</script>
