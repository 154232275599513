<template>
  <div>
    <div class="border-b border-gray-300 mb-2" v-if="!simple">
      <h3 class="font-semibold">Dismiss Finding</h3>
    </div>
    <div>
      <h4>Reasons for dismissal</h4>
      <div
        class="flex justify-start mt-4"
        v-for="reason in reasons"
        :key="reason.id"
      >
        <input
          :id="reason.name"
          type="checkbox"
          :value="reason.id"
          v-model="selectedReasons"
          name="default-radio"
          class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
        />
        <label
          :for="reason.name"
          class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >{{ reason.name }}</label
        >
      </div>
      <div class="flex mt-4 items-center">
        <span class="mr-2 w-fit">Other reasons:</span
        ><input type="text" class="flex-1" v-model="reasonOther" />
      </div>
      <div class="my-6">
        <h4>Notes</h4>
        <textarea v-model="note" class="w-full" rows="2" />
      </div>
      <div class="justify-between flex">
        <button class="secondary" @click="cancel">Cancel</button>
        <button class="primary" @click="confirm">Dismiss</button>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { DismissReason } from '@/types.js';
import { i18n } from 'i18next';
import { inject, ref } from 'vue';
import { api } from '@/services/apiClient.js';
import { currentOrg } from '@/stores/userState.js';
import { track } from '@/services/analytics.js';

interface Props {
  findingData: any;
  simple?: boolean;
}
const props = defineProps<Props>();
interface Emits {
  (eventName: 'cancel'): void;
  (eventName: 'confirm'): void;
}

const emit = defineEmits<Emits>();

const intl: i18n = inject('i18n') as i18n;
const selectedReasons = ref([]);
const reasonOther = ref([]);
const note = ref([]);
const reasons: { id: DismissReason; name: string }[] = [];

for (const reason in DismissReason) {
  if (isNaN(Number(reason))) continue;
  reasons.push({
    name: intl.t(`FINDING_ACTION.DISMISS_REASON.${DismissReason[reason]}`),
    id: Number(reason),
  });
}

function cancel() {
  emit('cancel');
}
async function confirm() {
  const data = props.findingData;
  if (Array.isArray(data)) {
    await api.post(`/findings/dismiss`, {
      findings: data,
      note: note.value,
      reasons: selectedReasons.value,
      reasonOther: reasonOther.value,
      org: currentOrg.value?.uuid,
    });
  } else {
    await api.post(`/findings/${data.FINDING_ID}/dismiss`, {
      findingId: data.FINDING_ID,
      estAnnualSavingUsd: data.ESTIMATE_ANNUAL_SAVING_USD,
      rootCauseEntity: data.ROOT_CAUSE_ENTITY,
      findingCategory: data.CATEGORY,
      note: note.value,
      reasons: selectedReasons.value,
      reasonOther: reasonOther.value,
      org: currentOrg.value?.uuid,
    });
    track('Finding Card Dismissed', data);
  }
  emit('confirm');
}
</script>

<style scoped></style>
