<template>
  <div
    class="justify-center items-center px-4 pb-4 w-full flex flex-col"
    v-if="orgs.length > 1"
  >
    <select
      v-model="currentOrgUuid"
      class="w-full"
      @change="reloadPage"
      v-if="!isDemoOrg"
    >
      <option v-for="org in orgs" :value="org.uuid" :key="org.uuid">
        {{ org.accountName }}
      </option>
    </select>
  </div>
</template>

<script setup lang="ts">
import type { Organization } from '@/types.js';

import { computed, customRef, nextTick, ref, watch } from 'vue';
import { api } from '@/services/apiClient.js';
import { currentOrg, parentOrg, user } from '@/stores/userState.js';
import { useRouter } from 'vue-router';
import { ToastType } from '@/types';
import { showToast } from '@/helpers/toastHelper';
import { logError } from '@/services/errorTracker.js';
import {
  isDemoOrg,
  publicView,
  reload,
} from '@/stores/uiState.js';
import Toggle from '@/components/base/Toggle.vue';

const router = useRouter();
const orgs = computed<Array<Organization>>(
  () => parentOrg.value?.childOrgs ?? []
);
const currentOrgUuid = ref(currentOrg.value?.uuid);

async function reloadPage() {
  currentOrg.value =
    orgs.value.find(o => o.uuid === currentOrgUuid.value) ?? null;
  const me = await api.get('/me', { params: { org: currentOrg.value?.uuid } });
  const userInfo = me.data.user;
  userInfo.org = me.data.org;
  user.value = userInfo;
  await nextTick();
  reload(router);
}
</script>

<style scoped></style>
