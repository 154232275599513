<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-80 -80 650 650"
    width="100%"
    height="100%"
    fill="currentColor"
  >
    <path
      d="M201.216 91.648v219.136a16.578 16.578 0 0 1-5.632 12.8 20.817 20.817 0 0 1-12.8 5.632v164.352a17.367 17.367 0 0 1-5.12 12.8A19.857 19.857 0 0 1 164.352 512H18.432a16.578 16.578 0 0 1-12.8-5.632A20.817 20.817 0 0 1 0 493.568v-145.92L71.168 97.792q2.047-6.144 8.7-6.144zm91.136 0v200.7h-72.7v-200.7zm219.648 256v145.92a16.578 16.578 0 0 1-5.632 12.8 20.817 20.817 0 0 1-12.8 5.632h-145.92a16.578 16.578 0 0 1-12.8-5.632 20.817 20.817 0 0 1-5.632-12.8V329.216a16.578 16.578 0 0 1-12.8-5.632 20.817 20.817 0 0 1-5.632-12.8V91.648h121.344q6.656 0 8.7 6.144zM210.432 9.216v64H109.568v-64a9 9 0 0 1 2.56-6.656A9 9 0 0 1 118.784 0h82.432a9 9 0 0 1 6.656 2.56 9 9 0 0 1 2.56 6.656zm192 0v64H301.568v-64a9 9 0 0 1 2.56-6.656A9 9 0 0 1 310.784 0h82.432a9 9 0 0 1 6.656 2.56 9 9 0 0 1 2.56 6.656z"
    />
  </svg>
</template>
