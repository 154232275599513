import type { TooltipInfo } from '@/types.js';
import { chartTooltipData } from '@/stores/uiState.js';
import { track } from '@/services/analytics.js';

export function sumBy(
  rows: any[],
  groupColumn: string,
  aggregateColumn: string
): any {
  return rows.reduce(function (res, value) {
    if (!res[value[groupColumn]]) {
      res[value[groupColumn]] = {
        [groupColumn]: value[groupColumn],
        [aggregateColumn]: 0,
      };
    }
    res[value[groupColumn]][aggregateColumn] += value[aggregateColumn];
    return res;
  }, {});
}

export function runningAverage(values: number[]): number[] {
  let currentSum = 0;
  let currentCount = 0;
  const result: number[] = [];
  values.forEach(value => {
    currentCount++;
    currentSum += value;
    result.push(parseFloat(String(currentSum)) / currentCount);
  });
  return result;
}
export function movingAverage(values: number[], period: number): number[] {
  let i = 0;
  let sum = 0;
  const means = new Float64Array(values.length).fill(NaN);
  for (let n = Math.min(period - 1, values.length); i < n; ++i) {
    sum += values[i];
  }
  for (let n = values.length; i < n; ++i) {
    sum += values[i];
    means[i] = sum / period;
    sum -= values[i - period + 1];
  }
  return [].slice.call(means);
}

export function groupBy(data: Record<string, unknown>[], prop: string) {
  const grouped: Record<string, unknown[]> = {};
  for (let i = 0; i < data.length; i++) {
    const p: any = data[i][prop];
    if (!grouped[p]) {
      grouped[p] = [];
    }
    grouped[p].push(data[i]);
  }
  return grouped;
}

export function makeInteractive(chart: HTMLElement, options: any) {
  Array.from(chart.getElementsByTagName('title')).forEach(titleElement => {
    if (!titleElement) return;
    const parent = titleElement?.parentElement;
    if (!parent) return;
    const html = (titleElement as HTMLElement).innerHTML;
    const jsonString =
      html.endsWith(')') && html.includes('} (')
        ? html.split('} (')[0] + '}'
        : html;

    if (options.idProp) {
      parent.dataset.id = JSON.parse(jsonString ?? '{}')[options.idProp];
    } else if (options.idPropFunction) {
      parent.dataset.id = options.idPropFunction(
        JSON.parse(jsonString ?? '{}')
      );
    }
    parent.dataset.info = jsonString;
    if (options.selectionProp) {
      try {
        parent.dataset.selectionId =
          JSON.parse(jsonString)[options.selectionProp];
      } catch (e) {
        console.log(jsonString);
      }
    }
    (titleElement as HTMLElement).innerHTML = '';

    parent.addEventListener('click', (e: MouseEvent) => {
      if (options.onClick)
        options.onClick(e, JSON.parse(parent?.dataset?.info ?? '{}'));
    });
    if (!options.clickOnly) {
      parent.addEventListener('mouseout', () => {
        const currentValue = chartTooltipData.value;
        setTimeout(() => {
          if (
            currentValue == chartTooltipData.value &&
            !chartTooltipData.value?.sticky
          ) {
            chartTooltipData.value = null;
          }
        }, 100);
      });

      parent.addEventListener('mouseover', (e: MouseEvent) => {
        if (!chartTooltipData.value?.sticky)
          chartTooltipData.value = getDataPoint(e, options);
        const chartName = (options?.tooltipRenderer?.__name ?? '').split(
          'Renderer'
        )[0];
        if (chartName)
          track('Chart Interacted', {
            Chart: chartName,
          });
      });
    }
    if (options.className) parent.classList.add(options.className);
  });
  chart.querySelectorAll('div > span').forEach(legendItem => {
    const selectValue = legendItem.textContent;
    legendItem.addEventListener('mouseover', e =>
      chart
        .querySelectorAll(`[data-id="${selectValue}"]`)
        .forEach(el => el.classList.add('selected'))
    );
    legendItem.addEventListener('mouseout', e =>
      chart
        .querySelectorAll(`[data-id="${selectValue}"]`)
        .forEach(el => el.classList.remove('selected'))
    );
  });

  function getDataPoint(e: MouseEvent, options: any): TooltipInfo | null {
    if (!e?.currentTarget) return null;
    const target = e?.currentTarget as HTMLElement;

    return {
      info: JSON.parse(target.dataset?.info ?? '{}'),
      left: e.clientX + 10,
      top: e.clientY + 10,
      renderer: options.tooltipRenderer,
      color: target.getAttribute('fill') ?? undefined,
      data: options.data,
      grouping: options.grouping,
    };
  }
}
export function appendNoDataText(
  chartWrapper: HTMLElement,
  { top = '50%', left = '50%', transform = 'translate(-50%, -50%)' }: any = {}
) {
  let noDataText = chartWrapper.querySelector('.no-data-text') as HTMLElement;

  if (!noDataText) {
    noDataText = document.createElement('div');
    noDataText.classList.add('no-data-text');
    noDataText.style.position = 'absolute';
    noDataText.style.top = top;
    noDataText.style.left = left;
    noDataText.style.transform = transform;
    noDataText.style.fontSize = '18px';
    noDataText.style.color = 'black'; // Customize the color as needed
    noDataText.textContent = 'Nothing to show!'; // Add the text content

    chartWrapper.appendChild(noDataText);
  }
}

export function removeNoDataText(chartWrapper: HTMLElement) {
  const noDataText = chartWrapper.querySelector('.no-data-text') as HTMLElement;

  if (noDataText) {
    chartWrapper.removeChild(noDataText);
  }
}

export const categoryColors = [
  '#4e79a7',
  '#f28e2c',
  '#e15759',
  '#76b7b2',
  '#59a14f',
  '#edc949',
  '#af7aa1',
  '#9c755f',
  '#bab0ab',
  '#ff9da7',
];
// [
//   '#1f77b4',
//   '#ff7f0e',
//   '#2ca02c',
//   '#98df8a',
//   '#d62728',
//   '#ff9896',
//   '#9467bd',
//   '#c5b0d5',
//   '#8c564b',
//   '#e377c2',
//   '#f7b6d2',
//   '#7f7f7f',
//   '#bcbd22',
//   '#17becf',
//   '#9edae5',
// ];
