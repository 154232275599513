import { AccessControl, Query } from 'accesscontrol';
import { Feature, type Organization, type User } from '@/types.js';
import { computed, ref, watch } from 'vue';
import { load, store } from '@/services/localStorage.js';

import { api } from '@/services/apiClient';

import { datadogRum } from '@datadog/browser-rum';
import { experimental } from '@/stores/uiState.js';

let access = new AccessControl({});
export const orgKey = 'currentOrg';
export const parentOrgKey = 'parentOrg';
export let isSuperAdmin = false;

const userKey = 'user';
export const user = ref<User | null>(load('user'));

if (user.value) setupUser();
export const parentOrg = ref<Organization | null>(load(parentOrgKey));
export const currentOrg = ref<Organization | null>(load(orgKey));
export const costPerCredit = computed(
  () => (currentOrg.value?.costPerCredit ?? 300) / 100.0
);
export const paidAccount = computed<boolean>(() => currentOrg.value?.paid);
export const trialAccount = computed<boolean>(
  () => new Date(currentOrg.value?.trialEnd) > new Date() && !paidAccount.value
);
export const activeAccount = computed<boolean>(
  () => trialAccount.value || paidAccount.value
);

setPermissions();

watch(currentOrg, newOrg => {
  store(orgKey, newOrg), { deep: true };
});

watch(parentOrg, newOrg => {
  store(parentOrgKey, newOrg), { deep: true };
});

watch(
  user,
  userInfo => {
    store(userKey, userInfo);
    setupUser();
  },
  { deep: true }
);

export function getPermissions(): Query | null {
  return user.value?.role ? access.can(user.value.role.toString()) : null;
}

export function hasFeature(feature: Feature): boolean {
  const experimentalFeatures = [];

  const experimentalCheck = experimentalFeatures.includes(feature)
    ? experimental.value
    : true;
  return (user.value?.features ?? []).includes(feature) && experimentalCheck;
}

function setPermissions() {
  if (user.value?.permissions) {
    access = new AccessControl({
      [user.value.role]: user.value.permissions,
    });
  }
}

export async function reloadOrg() {
  const result = await api.get(`/admin/orgs/${currentOrg.value?.uuid}`);
  currentOrg.value = result.data;
  store(orgKey, currentOrg.value);
}

export async function updateSettings(settings: any) {
  const response = await api.patch('/me/settings', settings);
  user.value!.settings = response.data;
}

export function getSetting(settingKey: string) {
  return user.value?.settings ? user.value?.settings[settingKey] : undefined;
}

export async function switchOrg(orgUuid: string) {
  if (currentOrg.value && currentOrg.value.uuid != orgUuid) {
    currentOrg.value.uuid = orgUuid;
    await reloadOrg();
  }
}

function setupUser() {
  setPermissions();
  isSuperAdmin = getPermissions()?.readAny('org').granted;
  datadogRum.setUser({ email: user.value.email, id: user.value.uuid });
}
