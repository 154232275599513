<template>
  <button class="btn btn-ghost loading p-0 min-h-fit h-fit"></button>
</template>

<script setup lang="ts"></script>

<style scoped>
button {
  color: var(--link-color);
}

.loading {
  text-decoration: none;
  color: #fff;
  width: 1px;
  height: 1px;
  background-color: rgba(49, 169, 224, 0.8);
  margin: 0 auto;
  border-radius: 100px;
  animation: ripple 1s linear infinite;
}

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 rgba(49, 169, 224, 0.5),
      0 0 0 10px rgba(49, 169, 224, 0.5);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(49, 169, 224, 0.5),
      0 0 0 30px rgba(49, 169, 224, 0);
  }
}
</style>
