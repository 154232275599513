<template>
  <span v-if="whSize">{{ whSize }}</span>
</template>

<script setup lang="ts">
import {
  getNameForWhSize,
} from '@/helpers/formattingHelper.js';
import { computed } from 'vue';

interface Props {
  value: number | null;
}
const props = defineProps<Props>();
const whSize = computed(() => {
  return getNameForWhSize(Number(props.value));
});
</script>

<style scoped></style>
