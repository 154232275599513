<template>
  <div>
    <div
      class="w-full h-60 flex flex-col items-center justify-center positive-text"
      v-if="success"
    >
      <icon-check-circle class="h-20 w-20" />
      <div class="p-8 font-medium text-lg">Successfully applied.</div>
    </div>
    <div
      class="w-full h-60 flex flex-col items-center justify-center"
      v-if="loading"
    >
      <loading-icon class="h-20 w-20" />
      <div class="p-4 font-medium text-lg">Applying...</div>
    </div>
    <div v-if="!success && !loading">
      <div class="" v-if="simple">
        <h4 class="font-semibold">Apply Finding</h4>
      </div>
      <div class="bg-muted border-b border-gray-300" v-else>
        <h3 class="font-semibold">Apply Finding</h3>
      </div>
      <div class="">
        <code-tooltip-renderer
          :data="{ code }"
          :maskPII="false"
          v-if="code"
          class="mt-2"
        />

        <div class="justify-between flex mt-4">
          <button class="secondary" @click="cancel">Cancel</button>
          <button class="primary" @click="confirm">Apply</button>
        </div>
      </div>

      <div
        class="mt-6 bg-red-100 dark:bg-red-900 text-red-900 dark:text-red-100"
        v-if="error"
      >
        <h4 class="flex items-center">
          <icon-info class="h-5 w-5 mr-1" />Missing Permissions
        </h4>
        <span class="code">BLUESKY_USER</span>
        does not have the required permissions to apply this finding. Please
        contact the Bluesky team for instructions.
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { i18n } from 'i18next';
import { computed, inject, ref } from 'vue';
import { api } from '@/services/apiClient.js';
import { queryFunction } from '@/helpers/queryHelper.js';
import CodeTooltipRenderer from '@/components/renderers/tooltipRenderers/CodeTooltipRenderer.vue';
import { currentOrg } from '@/stores/userState.js';
import IconInfo from '@/components/icons/IconInfo.vue';
import { getNameForWhSize } from '@/helpers/formattingHelper.js';
import LoadingIcon from '@/components/base/LoadingIcon.vue';
import { load } from '@/services/localStorage.js';
import { showToast } from '@/helpers/toastHelper.js';
import { ToastType } from '@/types.js';
import IconCheckCircle from '@/components/icons/IconCheckCircle.vue';
import { pause } from '@/helpers/utils.js';
import { track } from '@/services/analytics.js';

interface Props {
  findingData: any;
  simple?: boolean;
}
const props = defineProps<Props>();
interface Emits {
  (eventName: 'cancel'): void;
  (eventName: 'confirm'): void;
}

const emit = defineEmits<Emits>();

const intl: i18n = inject('i18n') as i18n;
const note = ref([]);
const error = ref();
const success = ref(false);
const loading = ref(false);

const code = computed(
  () =>
    props.findingData?.SQL_FIX_CMD ||
    intl.t(
      `FINDING.${props.findingData.CATEGORY}.TYPES.${props.findingData.INFO.TYPE}.CODE`,
      '',
      {
        info: props.findingData.INFO,
        entity: `${props.findingData.ROOT_CAUSE_ENTITY}`,
        optimal:
          props.findingData?.INFO.optimal ??
          getNameForWhSize(props.findingData?.INFO['Optimal WH Size']),
      }
    ) ||
    (queryFunction[props.findingData.CATEGORY] &&
      queryFunction[props.findingData.CATEGORY](
        props.findingData.INFO,
        props.findingData.ROOT_CAUSE_ENTITY
      ))
);
function cancel() {
  emit('cancel');
}
async function confirm() {
  const data = props.findingData;
  error.value = false;
  loading.value = true;
  try {
    await api.post(`/apply-finding/${data.FINDING_ID}`, {
      findingId: data.FINDING_ID,
      org: currentOrg.value?.uuid,
    });
    track('Finding Card Applied', {
      ...data,
      'Button Clicked': 'Apply',
    });
    success.value = true;
    loading.value = false;
    await pause(1000);
    emit('confirm');
  } catch (e) {
    console.log(e);
    error.value = true;
    showToast(ToastType.ERROR, 'Something went wrong.', 3);
  }
  loading.value = false;
}
</script>

<style scoped></style>
