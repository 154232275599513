<template>
  <div v-html="description" class="whitespace-pre-wrap" />
</template>
<script setup lang="ts">
import { computed, inject } from 'vue';
import { i18n } from 'i18next';
const intl: i18n = inject('i18n') as i18n;

interface Props {
  data: any;
}
const props = defineProps<Props>();

const description = computed(() => {
  if (!props?.data?.CATEGORY || !props?.data?.INFO?.type) {
    return '';
  }
  return intl.t(
    `FINDING.${props.data?.CATEGORY}.TYPES.${props.data?.INFO?.type}.DESCRIPTION`,
    {
      entity: `${props.data?.ROOT_CAUSE_ENTITY}`,
      info: props.data?.INFO,
      lookbackDays: props.data?.LOOK_BACK_DAYS,
    }
  );
});
</script>
