<template>
  <div
    class="relative"
    style="border-radius: 5px; border: 1px solid #d6d6d6; background: #fff"
  >
    <teleport to="body">
      <div
        class="bg-red-300 absolute z-20 box-fill max-h-48 overflow-y-auto"
        v-if="showMentionOptions && mentionList.length > 0"
        :style="{
          left: currentPosition.left + 'px',
          bottom: currentPosition.bottom + currentPosition.height + 10 + 'px',
        }"
      >
        <div
          v-for="user in mentionList"
          :key="user"
          @click="mention(user)"
          class="cursor-pointer hover:bg-gray-100 px-3 p-2"
        >
          {{ user }}
        </div>
      </div>
    </teleport>
    <div
      ref="contentDiv"
      class="block w-full input h-full z-10 relative whitespace-normal min-h-[100px]"
      v-html="processed"
      :contenteditable="editing"
      @input="onInput"
    />
    <div
      class="absolute text-gray-300 inset-0 p-2 z-0"
      v-if="(processed?.length ?? 0) === 0"
    >
      Add a comment...
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, nextTick, ref } from 'vue';

import { formatComment } from '@/helpers/formattingHelper.js';
import { api } from '@/services/apiClient.js';
import { currentOrg } from '@/stores/userState.js';
import { logError } from '@/services/errorTracker.js';
import { getCoords } from '@/helpers/domHelper.js';
interface Props {
  modelValue: number;
  editing: boolean;
}

interface Emits {
  (eventName: 'update:modelValue', value: number): void;
  (eventName: 'keyup.enter'): void;
}

const props = defineProps<Props>();
const emit = defineEmits<Emits>();
const value = computed({
  get: () => props.modelValue,
  set: value => emit('update:modelValue', value),
});
const showMentionOptions = ref(false);
const contentDiv = ref();
const currentPosition = ref({});
const processed = computed(() => formatComment(props.modelValue));
let team: string[] = [];
const mentionList = ref<string[]>([]);
let currentWord = '';
let cursorPosition: any;
loadTeam();
async function loadTeam() {
  try {
    const response = await api.get(`/team`, {
      params: { org: currentOrg.value?.uuid },
    });
    team = response.data;
  } catch (e) {
    logError(e);
  }
}
function onInput() {
  const selection = window.getSelection();
  const node = selection.focusNode;

  const offset = selection.focusOffset;
  cursorPosition = getCursorPosition(contentDiv.value, node, offset, {
    pos: 0,
    done: false,
  });
  if (offset === 0) cursorPosition.pos += 0.5;

  value.value = contentDiv.value.innerText;
  nextTick(() => {
    setCursor();

    const node = selection?.anchorNode?.parentNode;
    showMentionOptions.value = node?.classList?.contains('username');
    if (showMentionOptions.value) {
      currentWord = (node?.textContent ?? '').split('@')[1] ?? '';
      currentWord = currentWord.toLowerCase();
      currentPosition.value = getCoords(node);
      console.log(currentWord);
      mentionList.value = team.filter(
        user =>
          user.toLowerCase().startsWith(currentWord) &&
          user.length > currentWord.length
      );
      console.log(mentionList);
    }
  });
  return true;
}

// get the cursor position from .editor start
function getCursorPosition(parent, node, offset, stat) {
  if (stat.done) return stat;

  let currentNode = null;
  if (parent.childNodes.length == 0) {
    stat.pos += parent.textContent.length;
  } else {
    for (let i = 0; i < parent.childNodes.length && !stat.done; i++) {
      currentNode = parent.childNodes[i];
      if (currentNode === node) {
        stat.pos += offset;
        stat.done = true;
        return stat;
      } else getCursorPosition(currentNode, node, offset, stat);
    }
  }
  return stat;
}

//find the child node and relative position and set it on range
function setCursorPosition(
  parent: HTMLElement,
  range: Range,
  state: { done: boolean; pos: number }
) {
  if (state.done) return range;

  if (parent.childNodes.length == 0) {
    if ((parent.textContent?.length ?? 0) >= state.pos) {
      range.setStart(parent, state.pos);
      state.done = true;
    } else {
      state.pos = state.pos - (parent.textContent?.length ?? 0);
    }
  } else {
    for (let i = 0; i < parent.childNodes.length && !state.done; i++) {
      const currentNode = parent.childNodes[i];
      setCursorPosition(currentNode as HTMLElement, range, state);
    }
  }
  return range;
}

function mention(user: string) {
  showMentionOptions.value = false;
  setCursor();
  user = user.toLowerCase().split(currentWord)[1];
  document.execCommand('insertText', false, user);
}

function setCursor() {
  const range = setCursorPosition(contentDiv.value, document.createRange(), {
    pos: cursorPosition.pos,
    done: false,
  });
  const selection = window.getSelection();
  range.collapse(true);
  selection.removeAllRanges();
  selection.addRange(range);
}
</script>
<style></style>
