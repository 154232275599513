<template>
  <div>
    <div class="px-4 py-2 bg-muted border-b border-gray-300" v-if="!simple">
      <h3 class="font-semibold">Undo Dismiss Finding</h3>
    </div>
    <div>
      <div class="my-4">
        <span>Are you sure you want to undo the dismissing of finding </span>
        <span>
          {{ $t(`FINDING.${props.findingData.CATEGORY}.ABBREVIATION`) }}-{{
            props.findingData.FINDING_ID
          }}?
        </span>
      </div>
      <div class="justify-between flex">
        <button class="secondary" @click="cancel">Cancel</button>
        <button class="primary" @click="confirm">Undo Dismiss</button>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { DismissReason, ToastType } from '@/types.js';
import { i18n } from 'i18next';
import { showToast } from '@/helpers/toastHelper.js';
import { inject, ref } from 'vue';
import { api } from '@/services/apiClient.js';
import { currentOrg } from '@/stores/userState.js';

interface Props {
  findingData: any;
  simple?: boolean;
}
const props = defineProps<Props>();
interface Emits {
  (eventName: 'cancel'): void;
  (eventName: 'confirm'): void;
}

const emit = defineEmits<Emits>();

const intl: i18n = inject('i18n') as i18n;
const selectedReasons = ref([]);
const reasonOther = ref([]);
const note = ref([]);
const reasons: { id: DismissReason; name: string }[] = [];

for (const reason in DismissReason) {
  if (isNaN(Number(reason))) continue;
  reasons.push({
    name: intl.t(`FINDING_ACTION.DISMISS_REASON.${DismissReason[reason]}`),
    id: Number(reason),
  });
}

function cancel() {
  emit('cancel');
}
async function confirm() {
  try {
    const data = props.findingData;
    await api.patch(`/findings/${data.FINDING_ID}/undismiss`, {
      findingId: data.FINDING_ID,
      rootCauseEntity: data.ROOT_CAUSE_ENTITY,
      org: currentOrg.value?.uuid,
      estAnnualSavingUsd: data.ESTIMATE_ANNUAL_SAVING_USD,
      findingCategory: data.CATEGORY,
    });
  } catch (e) {
    showToast(ToastType.ERROR, 'Failed to undo dismiss finding.');
    logError(e);
  }
  emit('confirm');
}
</script>

<style scoped></style>
