<template>
  <aside
    class="flex flex-col flex-1 w-48 pt-2 min-h-0 bg-white border-r border-gray-200 dark:bg-black dark:border-gray-700"
    aria-label="Sidebar"
  >
    <div class="flex-1 px-2">
      <ul class="menu">
        <template v-for="item in menuItems" :key="item.label">
          <side-bar-item :menu-item="item" v-if="!item.hidden" />
        </template>
      </ul>
    </div>
    <data-freshness class="flex justify-center items-center mb-8" />
    <account-selector />
    <admin-panel v-if="showOrgs" />
  </aside>
</template>

<script setup lang="ts">
import SideBarItem from './SideBarItem.vue';
import IconBinoc from '../icons/IconBinoc.vue';
import IconSession from '../icons/IconSession.vue';
import IconHome from '../icons/IconHome.vue';
import IconRequest from '../icons/IconRequest.vue';
import IconConfig from '../icons/IconConfig.vue';
import IconDatabase from '../icons/IconDatabase.vue';
import IconEye from '../icons/IconEye.vue';
import IconQuery from '../icons/IconQuery.vue';
import IconEmail from '../icons/IconEmail.vue';
import {
  currentOrg,
  getPermissions,
  hasFeature,
  user,
} from '@/stores/userState.js';
import DataFreshness from '@/components/base/DataFreshness.vue';
import { computed, inject, markRaw, nextTick, ref, watch } from 'vue';
import type { i18n } from 'i18next';
import type { MenuItem } from '@/types.js';
import { Feature, IndicatorLevel } from '@/types.js';
import { FindingArea, findingCategories } from '@/helpers/findingHelper.js';
import { api } from '@/services/apiClient.js';
import { useRoute } from 'vue-router';
import IconWarehouse from '@/components/icons/IconWarehouse.vue';
import { isDemoOrg } from '@/stores/uiState.js';
import AccountSelector from '@/components/AccountSelector.vue';
import AdminPanel from '@/components/AdminPanel.vue';

const intl = inject('i18n') as i18n;
const findingSummary = ref<Record<FindingArea, number>>({
  [FindingArea.QUERY]: 0,
  [FindingArea.STORAGE]: 0,
  [FindingArea.WAREHOUSE]: 0,
});
const can = getPermissions();
const showOrgs = can?.readAny('org').granted;
const warehouseMenu = computed(() => ({
  isCategory: false,
  label: intl.t('SIDEBAR.WAREHOUSES'),
  route: 'root-warehouses',
  icon: markRaw(IconWarehouse),
  badges:
    findingSummary.value[FindingArea.WAREHOUSE] > 0
      ? [
          {
            id: 1,
            value: findingSummary.value[FindingArea.WAREHOUSE],
            status: IndicatorLevel.RED,
          },
        ]
      : undefined,
  children: [],
  hidden: !hasFeature(Feature.SIDEBAR_WAREHOUSES),
}));
const queryMenu = {
  isCategory: false,
  label: intl.t('SIDEBAR.QUERIES'),
  route: 'queries',
  icon: markRaw(IconQuery),
  badges:
    findingSummary.value[FindingArea.QUERY] > 0
      ? [
          {
            id: 1,
            value: findingSummary.value[FindingArea.QUERY],
            status: IndicatorLevel.RED,
          },
        ]
      : undefined,
  children: [],
};

const sessionMenu = {
  isCategory: false,
  label: intl.t('SIDEBAR.SESSION'),
  route: 'sessions',
  icon: markRaw(IconSession),
  badges: undefined,
  children: [],
};
const storageMenu = {
  isCategory: false,
  label: intl.t('SIDEBAR.STORAGE'),
  route: 'storages',
  icon: markRaw(IconDatabase),
  badges:
    findingSummary.value[FindingArea.STORAGE] > 0
      ? [
          {
            id: 1,
            value: findingSummary.value[FindingArea.STORAGE],
            status: IndicatorLevel.RED,
          },
        ]
      : undefined,
  children: [],
};
const menuMap: Record<string, MenuItem> = {
  storage: storageMenu,
  warehouse: warehouseMenu.value,
  query: queryMenu,
  session: sessionMenu,
};

const menuItems = computed(() => [
  {
    isCategory: false,
    label: intl.t('SIDEBAR.HOME'),
    route: 'home',
    icon: markRaw(IconHome),
    hidden: !hasFeature(Feature.FINANCIALS),
  },
  {
    isCategory: true,
    label: intl.t('SIDEBAR.WORKLOAD_ANALYTICS'),

    children: [warehouseMenu.value, queryMenu, storageMenu, sessionMenu],
  },
  ...(isDemoOrg.value
    ? []
    : [
        {
          isCategory: true,
          label: intl.t('SIDEBAR.OPPORTUNITY'),
          hidden: !hasFeature(Feature.FINDINGS),
          children: [
            {
              isCategory: false,
              label: intl.t('SIDEBAR.FINDINGS'),
              route: 'open-findings',
              icon: markRaw(IconBinoc),
            },
            // {
            //   isCategory: false,
            //   label: intl.t('SIDEBAR.REQUEST'),
            //   route: 'request',
            //   icon: markRaw(IconRequest),
            // },
          ],
        },
      ]),
  {
    isCategory: true,
    label: intl.t('SIDEBAR.MONITORING'),
    hidden: !hasFeature(Feature.MONITORS),
    children: [
      {
        isCategory: false,
        label: intl.t('SIDEBAR.ALERT_INBOX'),
        route: 'alerts',
        icon: markRaw(IconEye),
      },
      {
        isCategory: false,
        label: intl.t('SIDEBAR.MONITORS'),
        route: 'monitors',
        icon: markRaw(IconConfig),
      },

      {
        isCategory: false,
        label: 'Weekly Digest (Beta)',
        route: 'subscription',
        icon: markRaw(IconEmail),
      },
    ],
  },
]);

const route = useRoute();
watch(
  () => route.path,
  () => {
    Object.values(menuMap).forEach((menu: MenuItem) => {
      menu.children!.length = 0;
    });
    if (
      ['query', 'storage', 'warehouse', 'session'].includes(
        route.name as string
      )
    ) {
      menuMap[route.name as string].children?.push({
        isCategory: false,
        path: route.path,
        label:
          route.name === 'storage'
            ? (Object.values(route.params)[2] as string)
            : (Object.values(route.params)[0] as string),
      });
    }
  }
);
</script>

<style scoped>
.menu .menu-title > * {
  @apply text-sm pl-2 text-gray-400 pt-4 pb-3;
}
</style>
