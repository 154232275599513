<template>
  <router-link
    :to="{
      name: 'warehouse',
      params: { orgUuid: currentOrg?.uuid, warehouse },
    }"
    v-if="warehouse"
  >
    <span v-if="fullName">{{ warehouse }}</span>
    <string-renderer :value="warehouse" :max-width="maxWidth" v-else />
    <span v-if="count > 1" class="tag clean text-xs ml-0.5"
      >+{{ count - 1 }}</span
    >
  </router-link>
</template>

<script setup lang="ts">
import StringRenderer from '@/components/renderers/cellRenderers/StringRenderer.vue';
import { computed } from 'vue';
import { currentOrg } from '@/stores/userState.js';

interface Props {
  value: string | string[] | null;
  maxWidth?: number;
  fullName?: boolean;
}
const props = defineProps<Props>();

const count = computed(() =>
  typeof props.value === 'object' ? props.value.length : 0
);
const warehouse = computed(() => {
  if (typeof props.value === 'object' && props.value != null) {
    return props.value[0];
  } else {
    return props.value;
  }
});
</script>

<style scoped></style>
