import type { Component, Raw } from 'vue';

export enum ListFilterOperators {
  ARRAY_CONTAINS = 'ARRAY CONTAINS',
  CONTAINS = 'ILIKE',
  NOT_CONTAINS = 'NOT ILIKE',
  IS_NULL = 'IS NULL',
  IS_NOT_NULL = 'IS NOT NULL',
}

export enum FilterOperators {
  EQUAL = '=',
  NOT_EQUAL = '<>',
  LESS = '<=',
  GREATER = '>=',
  IN = 'IN',
  CONTAINS = 'ILIKE',
  NOT_CONTAINS = 'NOT ILIKE',
  IS_NULL = 'IS NULL',
  IS_NOT_NULL = 'IS NOT NULL',
}

export interface TabInfo {
  name: string;
  path: string;
  badge?: number;
  experimental?: boolean;
}
export interface LocalTabInfo {
  name: string;
  component?: Component;
  badge?: number;
  value?: unknown; //prop passed to the component
}

export interface DataFilter {
  operator: FilterOperators;
  value?: string | number | string[];
  column: string;
  raw?: string | number | string[];
  displayName?: string;
}

export interface DataSort {
  ascending: boolean;
  column: string;
}

export interface Column {
  visible: boolean;
  name: string;
  info?: Component;
  infoText?: string;
  suffix?: string;
}

export interface Organization {
  name: string;
  uuid: string;
  id: string;
  domain: string;
  number: number;
  metadataDbName: string;
  budget: number;
  costPerCredit: number;
  snowflakeUrl: string;
  paid: boolean;
  created: string;
  trialEnd: string;
  accountLocator: string;
  accountName?: string;
  childOrgs: Array<Organization>;
  settings: any;
}

export interface User {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  role: string;
  org: Organization;
  uuid: string;
  permissions: unknown;
  settings?: any;
  features?: Feature[];
}

export interface HealthInfo {
  name: string;
  alertItems: number;
  warnItems: number;
  totalItems?: number;
  indicator: IndicatorLevel;
  categories: string[];
  saving: number;
}

export interface TooltipInfo {
  info: Record<string, string>;
  top: number | string;
  left: number | string;
  renderer: Raw<Component>;
  color?: string;
  target?: HTMLElement;
  sticky?: boolean;
  data?: any;
  grouping?: string;
  custom?: any;
  class?: string;
  style?: object;
  selectionClass?: string;
  onSelect?: any;
}

export interface CommentData {
  modifiedAt: string;
  uuid: string;
  content: string;
  context?: string;
  replies?: CommentData[];
  createdByEmail: string;
  createdAt: string;
  info: any;
}
export interface PositionedComment {
  x: number;
  y: number;
  comment: CommentData;
}

export enum CostUnit {
  DOLLAR,
  CREDIT,
}

export enum IndicatorLevel {
  GREEN,
  YELLOW,
  RED,
  BLUE,
  UNKNOWN,
}

export enum ToastType {
  SUCCESS,
  ERROR,
  WARNING,
  INFO,
}

export enum AppearanceMode {
  System = 'System',
  Light = 'Light',
  Dark = 'Dark',
}

export interface Subscriber {
  uuid: string;
  email: string;
  active: boolean;
  deleted: Date;
  created: Date;
}

export interface Option {
  name: string;
  value: string;
  isParent?: boolean;
}

export interface MenuItem {
  isCategory: boolean;
  label: string;
  path?: string;
  route?: string;
  icon?: Component;
  hidden?: boolean;
  badges?: {
    id: number | string;
    value: number | string;
    status: IndicatorLevel;
  }[];
  children?: MenuItem[];
}

export interface ColumnInfo {
  type: string;
  info?: Component;
  dbColumn?: string;
  noFilter?: boolean;
  noOrder?: boolean;
}

export interface Monitor {
  uuid: string;
  name: string;
  active: boolean;
  lastAlert: Date;
  created: Date;
  type: MonitorType;
  creator: string;
}

export interface MonitorThreshold {
  type: 'DOLLAR' | 'PERCENT';
  threshold: number;
}

export enum MonitorThresholdType {
  DOLLAR = 'DOLLAR',
  PERCENT = 'PERCENT',
}

export interface Alert {
  created: Date;
  fullId: string;
  archivedAt: Date | null;
  archivedBy: Date | null;
  lastRead: Date | null;
  properties: {
    FORECAST: number;
    SPEND: number;
    TS: Date;
    ITEM: string;
  };
}

export enum MonitorType {
  FINDING = 2,
}

export enum DismissReason {
  RISKY,
  WORTHLESS,
  UNIMPORTANT,
  DEBATABLE,
}

// NOTE: Please make sure the enum is consistent with the enum in API backend
export enum CommentEntityType {
  QUERY = 1,
  QSIG = 2,
  TABLE = 3,
  WH = 4,
  CHART = 5,
  FINDING = 6,
  ALERT = 7,
  SESSION = 8,
}
export enum CommentType {
  COMMENT,
  ANNOTATION,
}

export enum EntityType {
  QUERY = 'query',
  QSIG = 'qsig',
  TABLE = 'table',
  WH = 'wh',
  CHART = 'chart',
}
export enum ChartType {
  SCATTER = 'scatter',
  LINE = 'line',
  BAR = 'bar',
}
export enum Period {
  NONE = 'None',
  MONTH = 'Month',
  WEEK = 'Week',
  DAY = 'Day',
}

export enum IntegrationPartner {
  PAGERDUTY = 1,
  SLACK = 2,
}

export interface Integration {
  partnerId: IntegrationPartner;
  config: any;
}

export enum DateShortcuts {
  Days7,
  Days30,
  Days60,
  Days90,
  Days180,
  Days365,
  MonthToNow,
  YearToNow,
}

export interface LineageNode {
  id: string;
  name: string;
  title?: string;
  parentIds: string[];
  type: 'query' | 'table';
  selected?: boolean;
  frequency?: number | null;
  info: any;
}

export enum Priority {
  LOW = 0,
  MEDIUM = 1,
  HIGH = 2,
}

export enum OpenidProvider {
  GOOGLE = 'google',
  MICROSOFT = 'microsoft',
}

export enum Feature {
  SIDEBAR_WAREHOUSES = 'sidebar_warehouses',
  MONITORS = 'monitors',
  LINEAGE = 'lineage',
  FINANCIALS = 'financials',
  FINDINGS = 'findings',
  APPLY_FINDING = 'apply_finding',
}

export enum FindingPageMode {
  HEALTH_CHECK = 'HEALTH_CHECK',
  GUIDED_TOUR = 'GUIDED_TOUR',
}

export const WhSizeMap = {
  'X-Small': 1,
  Small: 2,
  Medium: 4,
  Large: 8,
  'X-Large': 16,
  '2X-Large': 32,
  '3X-Large': 64,
  '4X-Large': 128,
  '5X-Large': 256,
  '6X-Large': 512,
};
