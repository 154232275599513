<template>
  <div class="absolute bottom-0 left-0 bg-white">
    <div
      class="w-48 h-6 bg-primary-500 cursor-pointer"
      @click="show = !show"
    ></div>
    <div
      class="justify-center items-center p-4 w-full flex flex-col"
      v-show="show"
    >
      <div class="w-full">
        <div class="flex items-center justify-between">
          Public view<toggle v-model="publicView" />
        </div>
      </div>
      <button class="secondary m-4 w-full" @click="exitDemo" v-if="isDemoOrg">
        Exit Demo
      </button>
      <button class="secondary m-4 w-full" @click="enterDemo" v-else>
        Demo
      </button>

      <router-link
        :to="{ name: 'admin-home' }"
        class="link font-semibold text-lg m-4"
        >Admin Tools</router-link
      >
      <select
        v-model="currentOrgUuid"
        class="w-full"
        @change="reloadPage"
        v-if="!isDemoOrg"
      >
        <option v-for="org in orgs" :value="org.uuid" :key="org.uuid">
          {{ `${org.number} - ${publicView ? '' : org.name}` }}
        </option>
      </select>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Organization } from '@/types.js';

import { ref, watch } from 'vue';
import { api } from '@/services/apiClient.js';
import { currentOrg, parentOrg, user } from '@/stores/userState.js';
import { useRouter } from 'vue-router';
import { ToastType } from '@/types';
import { showToast } from '@/helpers/toastHelper';
import { logError } from '@/services/errorTracker.js';
import {
  experimental,
  isDemoOrg,
  publicView,
  reload,
} from '@/stores/uiState.js';
import Toggle from '@/components/base/Toggle.vue';
const show = ref(false);
const router = useRouter();
const orgs = ref<Organization[]>([]);
api
  .get('/admin/orgs')
  .then(result => {
    const data = result.data;
    data.forEach((d: Organization) => {
      d.number = Number(d.metadataDbName.split('_DP')[1]);
    });
    orgs.value = result.data.sort(
      (a: Organization, b: Organization) => a.number - b.number
    );
  })
  .catch(e => {
    logError(e);
    showToast(
      ToastType.ERROR,
      'Failed to fetch organization info. Please try logging in again.'
    );
  });
const currentOrgUuid = ref(currentOrg.value?.uuid);

watch(currentOrg, () => (currentOrgUuid.value = currentOrg.value?.uuid));

function exitDemo() {
  currentOrgUuid.value = orgs.value.find(o => o.name === 'Bluesky')?.uuid;
  reloadPage();
}

function enterDemo() {
  currentOrgUuid.value = orgs.value.find(o => o.name === 'Bluesky demo')?.uuid;
  reloadPage();
}
async function reloadPage() {
  currentOrg.value =
    orgs.value.find(o => o.uuid === currentOrgUuid.value) ?? null;
  parentOrg.value = currentOrg.value;
  const me = await api.get('/me', { params: { org: currentOrg.value?.uuid } });
  const userInfo = me.data.user;
  userInfo.org = me.data.org;
  user.value = userInfo;
  reload(router);
}

watch(
  isDemoOrg,
  () => document.body.classList.toggle('demo', isDemoOrg.value),
  { immediate: true }
);
</script>

<style scoped></style>
