<template>
  <pre class="relative pr-6 max-w-full overflow-auto">
    <codemirror
      :model-value="processedCode"
      :indent-with-tab="true"
      :tab-size="2"
      :disabled="true"
      :extensions="[sql()]"
    />
    <copy-button :code="processedCode" :copyFromParentTextContent="false" class="absolute top-0 right-0 h-4 w-4 m-0.5"/>
    </pre>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { Codemirror } from 'vue-codemirror';
import { sql } from '@codemirror/lang-sql';
import CopyButton from '@/components/base/CopyButton.vue';
import { addHintsForRemovedPII } from '@/helpers/formattingHelper.js';

const props = defineProps(['data', 'maskPII']);

const processedCode = computed(() => {
  return props.maskPII ? addHintsForRemovedPII(props.data.code) : props.data.code;
});
</script>
