<template>
  <div>
    <span v-html="recommendation" class="whitespace-pre-wrap" />
    <code-tooltip-renderer
      :data="{ code }"
      :maskPII="false"
      v-if="code"
      class="mt-2"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, inject } from 'vue';
import { i18n } from 'i18next';
import CodeTooltipRenderer from '@/components/renderers/tooltipRenderers/CodeTooltipRenderer.vue';
import { queryFunction } from '@/helpers/queryHelper.js';
const intl: i18n = inject('i18n') as i18n;
interface Props {
  data: any;
}
const props = defineProps<Props>();
const recommendation = computed(() => {
  if (props.data?.CATEGORY && props.data?.INFO?.type) {
    return intl.t(
      `FINDING.${props.data?.CATEGORY}.TYPES.${props.data?.INFO?.type}.RECOMMENDATION`,
      {
        entity: props.data?.WAREHOUSE_NAME ?? props.data?.ROOT_CAUSE_ENTITY,
        optimal: props.data?.INFO?.optimal || '',
        current: props.data?.INFO?.current || '',
        info: props.data?.INFO,
      }
    );
  } else {
    return '';
  }
});

const code = computed(() => {
  const c =
    props.data?.SQL_FIX_CMD ||
    intl.t(
      `FINDING.${props.data?.CATEGORY}.TYPES.${props.data?.INFO?.type}.CODE`,
      '',
      {
        entity: `${props.data.ROOT_CAUSE_ENTITY}`,
        info: props.data.INFO,
      }
    ) ||
    (queryFunction[props.data.CATEGORY] &&
      queryFunction[props.data.CATEGORY](
        props.data.INFO,
        props.data.ROOT_CAUSE_ENTITY
      ));
  return c;
});
</script>
