import { computed, ref, watch } from 'vue';
import { type Router } from 'vue-router';
import type { DataFilter, TooltipInfo } from '@/types.js';
import { AppearanceMode, FilterOperators } from '@/types.js';
import { load, remove, store } from '@/services/localStorage.js';
import { currentOrg } from '@/stores/userState.js';
import mitt from 'mitt';
import router from '@/routes.js';
import { getUrlParam } from '@/helpers/urlHelper.js';

export const globalEventBus = mitt();
const experimentalFlagKey = 'experimental-flag';
const publicViewFlagKey = 'public-flag';
export const openModals = ref(0);
export const openTrialExpireModal = ref(false);
export const paywallData = ref<any[] | null>(null);
export const experimental = ref(load(experimentalFlagKey) ?? false);
export const publicView = ref(load(publicViewFlagKey) ?? true);
export const appLoading = ref(false);

export const timeZone = ref('UTC');
watch(experimental, () => store(experimentalFlagKey, experimental.value));
watch(publicView, () => store(publicViewFlagKey, publicView.value));
export const hideCommonFilters = computed(
  () => router.currentRoute.value?.meta?.hideCommonFilters
);
export const globalFilters = ref<DataFilter[]>([]);
const globalFilterContext = ref<string>('');

export function setGlobalFilterContext(newContext: string) {
  globalFilterContext.value = newContext;
  loadFiltersFromUrl();
  if (globalFilters.value.length === 0) {
    globalFilters.value = load(filterKey()) ?? [];
  }
}

export function removeFromGlobalFilter(filter: DataFilter) {
  const index = globalFilters.value.findIndex(
    f =>
      f.column === filter.column &&
      (f.operator === filter.operator || f.operator === FilterOperators.EQUAL)
  );
  if (index >= 0) globalFilters.value.splice(index, 1);
  if (globalFilterContext.value?.length > 0)
    store(filterKey(), globalFilters.value);
}

export function addToGlobalFilter(filter: DataFilter, ignoreExisting = false) {
  if (!ignoreExisting) removeFromGlobalFilter(filter);
  globalFilters.value.push(filter);
  if (globalFilterContext.value?.length > 0)
    store(filterKey(), globalFilters.value);
}

function filterKey() {
  return `filters-v2-${globalFilterContext.value}`;
}
export const chartTooltipData = ref<TooltipInfo | null>(null);
export const windowWidth = ref(window.innerWidth);
export const windowHeight = ref(window.innerHeight);
export const largeScreen = computed(() => windowWidth.value > 1024);

window.addEventListener('resize', onResize);

function onResize() {
  windowWidth.value = window.innerWidth;
  windowHeight.value = window.innerHeight;
}
const modeKey = 'appearanceMode';

export const getAppearanceMode = function (): AppearanceMode {
  const mode = load(modeKey);
  if (!mode) return AppearanceMode.System;
  return mode;
};

export const appearanceMode = ref<AppearanceMode>(getAppearanceMode());

export const isDemoOrg = computed(
  () => currentOrg.value?.name === 'Bluesky demo'
);

watch(appearanceMode, (mode: AppearanceMode) => {
  if (mode == AppearanceMode.System) remove(modeKey);
  else store(modeKey, mode);
  applyAppearanceMode();
});

export const applyAppearanceMode = function () {
  const mode = appearanceMode.value;
  if (
    mode === AppearanceMode.Dark ||
    (mode === AppearanceMode.System &&
      window.matchMedia('(prefers-color-scheme: dark)').matches)
  ) {
    document.documentElement.classList.add('dark');
  } else {
    document.documentElement.classList.remove('dark');
  }
  //no dark mode for now:
  document.documentElement.classList.remove('dark');
};

export function loadFiltersFromUrl() {
  globalFilters.value = getUrlParam('filters') ?? [];
}

export function reload(router: Router) {
  const route = router.currentRoute.value;
  router.push({
    name: route.name as string,
    query: route.query,
    params: { ...route.params, orgUuid: currentOrg.value?.uuid },
  });
}
