export function orphanTable(info: any, entity?: string, formatted = true) {
  const type =
    info.table_type === 'VIEW'
      ? 'VIEW'
      : info.table_type === 'MATERIALIZED VIEW'
      ? 'MATERIALIZED VIEW'
      : 'TABLE';
  const restrict = type === 'TABLE' ? 'RESTRICT' : '';
  return formatted
    ? `DROP ${type} IF EXISTS <span class="entity">"${info.database_name}"."${info.schema_name}"."${info.table_name}"</span> ${restrict};`
    : `DROP ${type} IF EXISTS "${info.database_name}"."${info.schema_name}"."${info.table_name}" ${restrict};`;
}

export function autoSuspend(info: any, entity?: string, formatted = true) {
  const warehouse = entity;
  const optimal = info.OPTIMUM_AUTOSUSPEND ?? info.optimum_autosuspend;
  return `ALTER WAREHOUSE ${warehouse} SET AUTO_SUSPEND = ${optimal};`;
}

export function autoRecluster(info: any, entity?: string, formatted = true) {
  const table = entity;
  return `ALTER TABLE ${table} SUSPEND RECLUSTER`;
}

export function clusteringKey(info: any, entity?: string, formatted = true) {
  const table = entity;
  const to = info['Suggested clustering key'];
  return `ALTER TABLE ${table} CLUSTER BY (${to});`;
}

export function warehouseTimeout(info: any, entity?: string, formatted = true) {
  const optimal = info['Stmt timeout in secs'];
  return `ALTER WAREHOUSE ${entity} SET STATEMENT_TIMEOUT_IN_SECONDS = ${optimal}`;
}

export function maxCluster(info: any, entity?: string, formatted = true) {
  const optimal = info['OPTIMAL'] ?? 1;
  const minMax = info.Type === 'Maximized cluster mode' ? 'MIN' : 'MAX';
  return `ALTER WAREHOUSE ${entity} SET ${minMax}_CLUSTER_COUNT = ${optimal}`;
}

export function warehouseResize(info: any, entity?: string, formatted = true) {
  const optimal = info['optimal'];
  return `ALTER WAREHOUSE ${entity} SET WAREHOUSE_SIZE = '${optimal}'`;
}

export const queryFunction: Record<
  string,
  (info: any, entity?: string, formatted?: boolean) => string
> = {
  WAREHOUSE_AUTOSUSPEND_TUNING: autoSuspend,
  ORPHAN_TBALE: orphanTable,
  WAREHOUSE_STMT_TIMEOUT: warehouseTimeout,
  AUTO_RECLUSTERING_TUNING: autoRecluster,
  WAREHOUSE_MULTI_CLUSTER_SIZE_OPTIMIZATION: maxCluster,
  OPTIMAL_CLUSTERING_KEY: clusteringKey,
  OPTIMIZE_WAREHOUSE_SIZE: warehouseResize,
  ADVANCED_OPTIMIZE_WAREHOUSE_SIZE: warehouseResize,
};
