<template>
  <div v-html="description" />
</template>
<script setup lang="ts">
import { computed, inject } from 'vue';
import { i18n } from 'i18next';
const intl: i18n = inject('i18n') as i18n;

interface Props {
  data: any;
}
const props = defineProps<Props>();

const description = computed(() =>
  intl.t(`HOME.FINDINGS.DESCRIPTIONS.${props.data.CATEGORY}`, {
    entity: props.data?.ROOT_CAUSE_ENTITY || '',
    created_count_last_30days: props.data?.INFO?.created_count_last_30days || 'several',
  })
);
</script>
