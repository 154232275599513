<template>
  <div
    class="overflow-x-hidden"
    :class="{
      'w-128': !extended,
      'w-[50vw] bg-muted fixed top-0 bottom-0 right-0 shadow-lg  overflow-y-auto h-[100vh]':
        extended,
    }"
    v-if="findingData && shown"
  >
    <div v-if="extended || (!dismissing && !applying && !undoDismissing)">
      <div>
        <div class="px-4 py-2 bg-muted border-gray-300">
          <div class="justify-between flex items-center">
            <h3
              class="font-semibold"
              :class="extended ? 'flex items-center' : ''"
            >
              {{ findingData.name }}:
              <Component
                :is="rootCauseRenderer"
                :value="findingData.ROOT_CAUSE_ENTITY"
                :fully-qualified="true"
                :max-width="300"
                class="link ml-1"
              />
            </h3>
            <Comment
              v-if="comment"
              :entity="comment"
              :place-top-right="false"
              :full-version="true"
            />
          </div>
        </div>
        <div class="py-2 px-3">
          <div
            v-if="findingData.notification"
            class="rounded-md bg-orange-50 dark:bg-orange-800 text-xs px-2 py-1.5 mt-2 leading-relaxed border border-orange-200 dark:border-orange-700;"
          >
            Notification sent on
            <span class="font-semibold">{{
              findingData.notification.date
            }}</span>
            to
            <span class="font-semibold"
              >{{ findingData.notification.recipient }} ({{
                findingData.notification.department
              }})
            </span>
            <div class="py-2" v-if="findingData.notification.lastReminder">
              Last reminded on
              <span class="font-semibold">{{
                findingData.notification.lastReminder
              }}</span>
            </div>
            <div class="flex justify-end">
              <button
                class="secondary reminder"
                @click.stop="sendReminder"
                v-if="!sent"
              >
                Send reminder
              </button>
            </div>
          </div>

          <div v-if="extended">
            <div
              v-if="findingData?.CATEGORY && findingData?.INFO"
              class="box w-full"
            >
              <component
                :is="
                  descriptionRenderer[findingData.CATEGORY] ??
                  GenericDescriptionRenderer
                "
                :data="findingData"
              />
            </div>
            <div class="flex gap-4 my-4">
              <div class="w-6/12 box">
                <h4>Saving Opportunity</h4>
                <div class="whitespace-pre-wrap ml-4">
                  <div v-html="savingHtml" />

                  <div
                    v-if="
                      findingData.ESTIMATE_ANNUAL_SAVING_USD ||
                      findingData.INITIAL_SAVINGS_EST
                    "
                    class="my-4"
                  >
                    <span class="-ml-6">
                      <icon-check-circle
                        class="h-5 w-5 positive-text mr-1 inline"
                      />
                      Based on our calculations, this change may save&nbsp;
                      <span class="font-semibold positive-text align-middle">{{
                        formatCurrencySimple(
                          findingData.ESTIMATE_ANNUAL_SAVING_USD ??
                            findingData.INITIAL_SAVINGS_EST
                        )
                      }}</span
                      >&nbsp;per year.</span
                    >
                  </div>
                </div>
              </div>
              <div class="w-6/12 box">
                <div
                  v-if="!dismissing && !applying && !undoDismissing"
                  class="flex flex-col h-full relative"
                >
                  <h4>Recommendation</h4>
                  <component
                    :is="
                      recommendationRenderer[findingData.CATEGORY] ??
                      GenericRecommendationRenderer
                    "
                    :data="findingData"
                    class="flex-1"
                  />
                  <div v-if="extended" class="mt-4">
                    <div
                      class="flex justify-end mb-2"
                      v-if="props.showUndoDismiss || false"
                    >
                      <button
                        class="primary text-sm mr-2"
                        @click="undoDismissing = true"
                      >
                        <icon-eye-off class="h-5 w-5 mr-2" /> Undo Dismiss
                      </button>
                    </div>
                    <div
                      class="flex justify-end mb-2 gap-3"
                      v-if="!props.hideDismiss || false"
                    >
                      <button
                        class="secondary text-xs"
                        @click="dismissing = true"
                      >
                        Dismiss
                      </button>
                      <button
                        class="secondary text-xs"
                        @click="markAsApplied"
                        v-if="code && false"
                        :disabled="loading"
                      >
                        Mark as Applied
                      </button>
                      <button
                        class="primary text-xs"
                        @click="reviewAndApply"
                        v-if="code && hasFeature(Feature.APPLY_FINDING)"
                      >
                        Review and Apply
                      </button>
                    </div>
                  </div>
                  <component
                    v-if="
                      additionalRecommendationRenderer[findingData.CATEGORY]
                    "
                    :is="additionalRecommendationRenderer[findingData.CATEGORY]"
                    :data="findingData"
                    class="flex-1"
                  />
                  <loading-icon class="absolute inset-0 z-50" v-if="loading" />
                </div>

                <div v-if="undoDismissing">
                  <undo-dismiss-finding
                    :finding-data="findingData"
                    :simple="extended"
                    @cancel="undoDismissing = false"
                    @confirm="refreshData"
                  />
                </div>
                <div v-if="dismissing">
                  <dismiss-finding
                    :finding-data="findingData"
                    :simple="extended"
                    @cancel="dismissing = false"
                    @confirm="refreshData"
                  />
                </div>
                <div v-if="applying">
                  <apply-finding
                    :finding-data="findingData"
                    :simple="extended"
                    @cancel="applying = false"
                    @confirm="refreshData"
                  />
                </div>
              </div>
            </div>
            <div class="flex gap-4 my-4">
              <div class="w-6/12 box">
                <h4>Risks</h4>
                <div class="whitespace-pre-wrap">
                  {{
                    findingData?.CATEGORY &&
                    findingData?.INFO?.type &&
                    $t(
                      `FINDING.${findingData.CATEGORY}.TYPES.${findingData.INFO.type}.RISK`
                    )
                  }}
                </div>
              </div>
              <div class="w-6/12 box">
                <h4>Effort Level</h4>
                <div class="whitespace-pre-wrap">
                  {{
                    findingData?.CATEGORY &&
                    findingData?.INFO?.type &&
                    $t(
                      `FINDING.${findingData.CATEGORY}.TYPES.${findingData.INFO.type}.EFFORT`
                    )
                  }}
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="my-4">
              <div v-if="findingData.description">
                {{ findingData.description }}
              </div>
              <component
                v-else
                :is="
                  descriptionRenderer[findingData.CATEGORY] ??
                  GenericDescriptionRenderer
                "
                :data="findingData"
              />
            </div>
            <div class="my-4">
              <span class="font-semibold" v-if="findingData.recommendation">
                Recommendation:
              </span>
              <div v-if="findingData.recommendation">
                <div>{{ findingData.recommendation }}</div>
              </div>
              <component
                v-else
                :is="
                  recommendationRenderer[findingData.CATEGORY] ??
                  GenericRecommendationRenderer
                "
                :data="findingData"
              />
            </div>
            <div v-if="findingData.ESTIMATE_ANNUAL_SAVING_USD" class="my-4">
              <span class="font-semibold"
                >{{ $t('COMMON.ANNUAL_SAVINGS') }}:</span
              >&nbsp;<span
                class="bg-muted font-semibold px-1 py-0.5 border border-gray-100 rounded-lg"
                >{{
                  formatCurrencySimple(findingData.ESTIMATE_ANNUAL_SAVING_USD)
                }}</span
              >
            </div>
          </div>
          <div v-if="!extended">
            <div
              class="flex justify-end mb-2"
              v-if="props.showUndoDismiss || false"
            >
              <button
                class="primary text-sm mr-2"
                @click="undoDismissing = true"
              >
                <icon-eye-off class="h-5 w-5 mr-2" /> Undo Dismiss
              </button>
            </div>
            <div
              class="flex justify-end mb-2"
              v-if="!props.hideDismiss || false"
            >
              <button class="primary text-sm mr-2" @click="dismissing = true">
                <icon-eye-off class="h-5 w-5 mr-2" /> Dismiss
              </button>
              <button
                class="primary text-sm"
                @click="applying = true"
                v-if="code"
              >
                <icon-check class="h-5 w-5 mr-2" /> Review and Apply
              </button>
            </div>
          </div>
          <div
            class="flex justify-end items-center"
            v-if="findings?.length > 1"
          >
            <button
              class="btn-ghost compact"
              :disabled="index <= 0"
              @click="index--"
            >
              <icon-chevron-left class="h-5 w-5" />
            </button>
            <div class="mx-2 text-xs">
              Finding {{ index + 1 }} of {{ findings?.length }}
            </div>
            <button
              class="btn-ghost compact"
              :disabled="index >= findings?.length - 1"
              @click="index++"
            >
              <icon-chevron-right class="h-5 w-5" />
            </button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="undoDismissing && !extended">
      <undo-dismiss-finding
        class="p-4"
        :finding-data="findingData"
        @cancel="undoDismissing = false"
        @confirm="refreshData"
      />
    </div>
    <div v-if="dismissing && !extended">
      <dismiss-finding
        class="p-4"
        :finding-data="findingData"
        @cancel="dismissing = false"
        @confirm="refreshData"
      />
    </div>
    <div v-if="applying && !extended">
      <apply-finding
        class="p-4"
        :finding-data="findingData"
        @cancel="applying = false"
        @confirm="refreshData"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  computed,
  inject,
  nextTick,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from 'vue';
import type { Component } from 'vue';
import { formatCurrencySimple } from '@/helpers/formattingHelper.js';
import { i18n } from 'i18next';
import GenericRecommendationRenderer from '@/components/renderers/optimizationRenderers/GenericRecommendationRenderer.vue';
import OptimalClusteringKeyRecommendationRenderer from '@/components/renderers/optimizationRenderers/OptimalClusteringKeyRecommendationRenderer.vue';
import GenericDescriptionRenderer from '@/components/renderers/optimizationRenderers/GenericDescriptionRenderer.vue';
import WarehouseTimeoutRecommendationRenderer from '@/components/renderers/optimizationRenderers/WarehouseTimeoutRecommendationRenderer.vue';
import WarehouseSpaceShiftRecommendationRenderer from '@/components/renderers/optimizationRenderers/WarehouseSpaceShiftRecommendationRenderer.vue';
import StaleTableDescriptionRenderer from '@/components/renderers/optimizationRenderers/StaleTableDescriptionRenderer.vue';
import NonIncrementalDescriptionRenderer from '@/components/renderers/optimizationRenderers/NonIncrementalDescriptionRenderer.vue';
import PartitionPruningDescriptionRenderer from '@/components/renderers/optimizationRenderers/PartitionPruningDescriptionRenderer.vue';
import ColumnPruningDescriptionRenderer from '@/components/renderers/optimizationRenderers/ColumnPruningDescriptionRenderer.vue';
import MaxClusterRecommendationRenderer from '@/components/renderers/optimizationRenderers/MaxClusterRecommendationRenderer.vue';
import WhResizeRecommendationRenderer from '@/components/renderers/optimizationRenderers/WhResizeRecommendationRenderer.vue';
import WhResizeDescriptionRenderer from '@/components/renderers/optimizationRenderers/WhResizeDescriptionRenderer.vue';
import IconEyeOff from '@/components/icons/IconEyeOff.vue';
import IconChevronLeft from '@/components/icons/IconChevronLeft.vue';
import IconChevronRight from '@/components/icons/IconChevronRight.vue';
import IconCheck from '@/components/icons/IconCheck.vue';
import DismissFinding from '@/components/renderers/optimizationRenderers/DismissFinding.vue';
import UndoDismissFinding from '@/components/renderers/optimizationRenderers/UndoDismissFinding.vue';
import { queryFunction } from '@/helpers/queryHelper.js';
import { useRouter } from 'vue-router';
import ApplyFinding from '@/components/renderers/optimizationRenderers/ApplyFinding.vue';
import MaxClusterDescriptionRenderer from '@/components/renderers/optimizationRenderers/MaxClusterDescriptionRenderer.vue';
import { findingCategories } from '@/helpers/findingHelper.js';
import IconCheckCircle from '@/components/icons/IconCheckCircle.vue';
import SignatureRenderer from '@/components/renderers/cellRenderers/SignatureRenderer.vue';
import TableRenderer from '@/components/renderers/cellRenderers/TableRenderer.vue';
import WarehouseRenderer from '@/components/renderers/cellRenderers/WarehouseRenderer.vue';
import { CommentEntityType, CommentType, Feature, ToastType } from '@/types.js';
import { getCommentedEntities } from '@/data/commentData';
import Comment from '@/components/base/Comment.vue';
import { api } from '@/services/apiClient.js';
import { currentOrg, hasFeature } from '@/stores/userState.js';
import { pause } from '@/helpers/utils.js';
import { showToast } from '@/helpers/toastHelper.js';
import LoadingIcon from '@/components/base/LoadingIcon.vue';
import { track } from '@/services/analytics.js';

const intl: i18n = inject('i18n') as i18n;
const props = defineProps([
  'data',
  'findings',
  'hideDismiss',
  'showUndoDismiss',
]);
interface Emits {
  (eventName: 'resize'): void;
}

const emit = defineEmits<Emits>();
const shown = ref(true);
const extended = ref(false);
const loading = ref(false);
const index = ref(0);
const dismissing = ref(false);
const undoDismissing = ref(false);
const applying = ref(false);
const comment = ref<any>(null);

const findingData = computed(() => {
  const data = props.findings ? props.findings[index.value] : props.data;
  if (!data) return {};
  if (!data.name) {
    data.name = intl.t(`FINDING.${data.CATEGORY}.NAME`);
  }
  if (!data.INFO) {
    return data;
  }
  let type = data.INFO.type ?? data.INFO.Type ?? data.INFO.TYPE;
  if (!type) {
    type = intl.t(`FINDING.${data.CATEGORY}.DEFAULT_TYPE`);
  }
  data.INFO.TYPE = data.INFO.Type = data.INFO.type = type;
  return data;
});

watch(
  findingData,
  () => {
    dismissing.value = false;
    applying.value = false;
    extended.value =
      findingCategories[findingData.value.CATEGORY]?.extended ?? false;
    nextTick(() => emit('resize'));
  },
  { immediate: true }
);
const descriptionRenderer: Record<string, Component> = {
  WAREHOUSE_MULTI_CLUSTER_SIZE_OPTIMIZATION: MaxClusterDescriptionRenderer,
  NON_INCREMENTAL_TABLE: NonIncrementalDescriptionRenderer,
  INSUFFICIENT_COLUMN_PRUNING: ColumnPruningDescriptionRenderer,
  INSUFFICIENT_PARTITION_PRUNING: PartitionPruningDescriptionRenderer,
  OPTIMIZE_WAREHOUSE_SIZE: WhResizeDescriptionRenderer,
  ADVANCED_OPTIMIZE_WAREHOUSE_SIZE: WhResizeDescriptionRenderer,
};
const recommendationRenderer: Record<string, Component> = {
  WAREHOUSE_STMT_TIMEOUT: WarehouseTimeoutRecommendationRenderer,
  // WAREHOUSE_SPACE_SHIFTING: WarehouseSpaceShiftRecommendationRenderer,
  WAREHOUSE_MULTI_CLUSTER_SIZE_OPTIMIZATION: MaxClusterRecommendationRenderer,
  OPTIMIZE_WAREHOUSE_SIZE: WhResizeRecommendationRenderer,
  ADVANCED_OPTIMIZE_WAREHOUSE_SIZE: WhResizeRecommendationRenderer,
};

const additionalRecommendationRenderer: Record<string, Component> = {
  OPTIMAL_CLUSTERING_KEY: OptimalClusteringKeyRecommendationRenderer,
};

const sent = ref(false);

async function loadComment() {
  const response = await getCommentedEntities(CommentEntityType.FINDING, [
    findingData.value.FINDING_ID,
  ]);

  comment.value = {
    id: findingData.value.FINDING_ID,
    type: CommentEntityType.FINDING,
    value: response[0],
    header: `${intl.t(
      `HOME.FINDINGS.CATEGORIES_ABBREVIATION.${findingData.value?.CATEGORY}`
    )}-${findingData.value?.FINDING_ID}`,
  };
}

function sendReminder() {
  sent.value = true;
  props.data.notification.lastReminder = new Date().toISOString().split('T')[0];
}
const router = useRouter();
function refreshData() {
  router.go(0);
}

const savingHtml = computed(
  () =>
    findingData.value?.CATEGORY &&
    findingData.value?.INFO?.type &&
    intl.t(
      `FINDING.${findingData.value.CATEGORY}.TYPES.${findingData.value.INFO.type}.OPPORTUNITY`,
      {
        lookbackDays: findingData.value.LOOK_BACK_DAYS,
        saving: formatCurrencySimple(
          findingData.value.ESTIMATE_ANNUAL_SAVING_USD ??
            findingData.value.INITIAL_SAVINGS_EST
        ),
        info: findingData.value.INFO,
        entity: findingData.value.ROOT_CAUSE_ENTITY,
      }
    )
);

const rootCauseRenderer = computed(() => {
  const type = findingData.value.ENTITY_TYPE?.toLowerCase();
  if (type === 'warehouse') return WarehouseRenderer;
  else if (type === 'table') return TableRenderer;
  else return SignatureRenderer;
});

const code = computed(
  () =>
    queryFunction[findingData.value.CATEGORY] &&
    queryFunction[findingData.value.CATEGORY](
      findingData.value.INFO,
      findingData.value.ROOT_CAUSE_ENTITY
    )
);

const handleEscKey = (event: KeyboardEvent) => {
  if (event.key === 'Escape') {
    shown.value = false;
  }
};

onMounted(() => {
  window.addEventListener('keyup', handleEscKey);
  loadComment();
});

onUnmounted(() => {
  window.removeEventListener('keyup', handleEscKey);
});

async function markAsApplied() {
  const data = findingData.value;
  loading.value = true;
  try {
    await api.post(`/apply-finding/${data.FINDING_ID}`, {
      findingId: data.FINDING_ID,
      org: currentOrg.value?.uuid,
      done: true,
    });
    track('Finding Card Applied', {
      ...data,
      'Button Clicked': 'Mark as Applied',
    });
    showToast(ToastType.ERROR, 'Marked as applied.', 3);
    await pause(2000);
    refreshData();
  } catch (e) {
    console.log(e);
    showToast(ToastType.ERROR, 'Something went wrong.', 3);
  }
  loading.value = false;
}
function reviewAndApply() {
  applying.value = true;
  track('Finding Card Applied', {
    ...findingData.value,
    'Button Clicked': 'Apply and Review',
  });
}

track('Open Finding Card', findingData.value);
</script>
<style scoped>
/*w-196*/
.reminder {
  @apply text-xs px-2 py-1;
}
</style>
