import english from '@/eng.json';
import i18next from 'i18next';
import type { i18n } from 'i18next';
import type { App } from 'vue';
export let i18nProvider: i18n;
// This is a plugin for the internationalization-framework, i18next.
// See https://www.i18next.com for documentation.
export function i18nSetup(): i18n {
  i18next.init({
    lng: 'en',
    fallbackLng: 'en',
    debug: import.meta.env.DEV, // Print i18n logs only if running under dev environment
    resources: {
      en: {
        translation: english,
      },
    },
  });
  return i18next;
}
export default {
  install: (app: App) => {
    i18nProvider = i18nSetup();
    // inject method $t() which is globally available in all templates
    app.config.globalProperties.$t = (
      key: string,
      params?: Record<string, unknown>
    ) => {
      return i18nProvider.t(key, params);
    };

    // provide the plugin which can be injected into component's <script> block
    app.provide('i18n', i18nProvider);
  },
};
