<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="2 2 19 19"
    width="100%"
    height="100%"
    fill="currentColor"
  >
    <path d="M10,20V14H14V20H19V12H22L12,3L2,12H5V20H10Z" />
  </svg>
</template>
