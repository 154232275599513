import md5 from 'md5';
export function hash(message: string) {
  return md5(message);
  // const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
  // const hashBuffer = await crypto.subtle.digest('SHA-1', msgUint8); // hash the message
  // const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
  // const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
  // return hashHex;
}
export async function pause(ms: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms));
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const debounce = (fn: Function, delay: number) => {
  let timeout: any;

  return (...args: any[]) => {
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      fn(...args);
    }, delay);
  };
};

export function prod() {
  return import.meta.env.MODE == 'production';
}
export function testing() {
  return import.meta.env.MODE == 'test';
}
export function dev() {
  return import.meta.env.MODE == 'development';
}
export function envVar(prop: string) {
  return import.meta.env[prop];
}

export function validateEmail(email: string): boolean {
  const re =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

  return re.test(email) && email.split('@')[1].includes('.');
}

export function deepSearch(object: any, predicate: (obj: any) => boolean): any {
  if (predicate(object)) return object;

  for (let i = 0; i < Object.keys(object).length; i++) {
    const value = object[Object.keys(object)[i]];
    if (typeof value === 'object' && value != null) {
      const o = deepSearch(object[Object.keys(object)[i]], predicate);
      if (o != null) return o;
    }
  }
  return null;
}
