import { message, show, type } from '@/stores/toastState.js';

import type { ToastType } from '@/types';

export function showToast(
  toastType: ToastType,
  toastMessage: string,
  dismissSeconds?: number
) {
  type.value = toastType;
  message.value = toastMessage;
  show.value = true;

  dismissSeconds &&
    setTimeout(() => {
      show.value = false;
    }, dismissSeconds * 1000);
}

export function hideToast() {
  type.value = null;
  message.value = '';
  show.value = false;
}
