import { logError } from '@/services/errorTracker.js';
import { prod } from '@/helpers/utils.js';
import { api } from '@/services/apiClient.js';
import { currentOrg, user } from '@/stores/userState.js';
import UAParser from 'ua-parser-js';
import type { Router } from 'vue-router';
// Change this flag to true to enable tracking locally
const trackDev = true;
// Tracking must be enabled in production
const enableTracking = prod() || trackDev;
const parser = new UAParser();
const agentProps = {
  $browser: parser.getBrowser().name,
  $device: parser.getDevice().vendor,
  $os: parser.getOS().name,
};
import router from '@/routes.js';
export function track(event: string, eventProperties?: any) {
  try {
    if (!enableTracking || !user.value) return;
    const path = router?.currentRoute?.value?.fullPath;
    const pathName = router?.currentRoute?.value?.name;
    const properties = Object.assign(
      { ...agentProps, 'Current Page': path, 'Current Page Name': pathName },
      eventProperties
    );

    api.post('/use', {
      event,
      properties,
      org: currentOrg.value?.uuid,
    });
  } catch (e) {
    logError(e);
  }
}
