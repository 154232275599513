<template>
  <div
    class="chart-tooltip fixed bg-default p-4 rounded-md border-solid border-gray-400 my-shadow z-[9999] w-max max-w-[80vw] max-h-[100vh] overflow-y-auto"
    :class="chartTooltipData?.class"
    ref="tooltip"
    v-if="renderer"
    :style="
      Object.assign(
        {
          top,
          left,
          visibility,
        },
        chartTooltipData?.style ?? {}
      )
    "
  >
    <div class="flex justify-end">
      <icon-close
        class="w-6 h-6 mb-1 -mt-2 -mr-1 button minimal p-1"
        v-if="large"
        @click="close"
      />
    </div>
    <Component
      :is="renderer"
      v-bind="{
        data: tooltipData,
        color: chartTooltipData?.color,
        sticky: chartTooltipData?.sticky,
      }"
    />
  </div>
</template>

<script setup lang="ts">
import { chartTooltipData } from '@/stores/uiState.js';
import { nextTick, onMounted, ref, watch } from 'vue';
import type { Component } from 'vue';
import IconClose from '@/components/icons/IconClose.vue';
import { getAdjustedPosition, isWide } from '@/helpers/domHelper.js';

const tooltip = ref();
const large = ref(false);
const left = ref('0px');
const top = ref('0px');
const visibility = ref('hidden');
const renderer = ref<Component>();
const tooltipData = ref<Record<string, unknown>>();
document.addEventListener('click', hideIfClickedOut);

watch(chartTooltipData, () => {
  visibility.value = chartTooltipData.value ? 'visible' : 'hidden';
  if (!chartTooltipData?.value?.left || !chartTooltipData?.value?.top) return;
  tooltipData.value = chartTooltipData.value.info;
  renderer.value = chartTooltipData.value.renderer;
  const leftValue = chartTooltipData.value.left;
  const topValue = chartTooltipData.value.top;
  left.value = isNaN(leftValue) ? leftValue : leftValue + 'px';
  top.value = isNaN(topValue) ? topValue : topValue + 'px';

  nextTick(() => {
    if (!chartTooltipData.value || !tooltip.value) return;
    large.value = isWide(tooltip.value);
    const position = getAdjustedPosition(tooltip.value, {
      top: chartTooltipData.value.top,
      left: chartTooltipData.value.left,
    });
    left.value = `${position.left}px`;
    top.value = `${position.top}px`;
  });
});
function hideIfClickedOut(event: MouseEvent) {
  if (
    !tooltip.value?.contains(event.target) &&
    !chartTooltipData.value?.target?.contains(event.target as HTMLElement) &&
    chartTooltipData.value?.target !== event.target
  ) {
    const chartSelectionClass = chartTooltipData.value?.selectionClass;
    if (chartSelectionClass) {
      [...document.body.getElementsByClassName(chartSelectionClass)].forEach(
        el => el.classList.remove(chartSelectionClass)
      );
    }
    close();
  }
}
function onKeydown(e: KeyboardEvent) {
  if (e.key == 'Escape') close();
}

function close() {
  chartTooltipData.value = null;
}
onMounted(() => document.body.addEventListener('keydown', onKeydown));
</script>

<style scoped>
.chart-tooltip {
  will-change: top, left;
  transition-property: top, left;
  transition-duration: 0.3s;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
