<template>
  <label :for="id" class="inline-flex relative items-center cursor-pointer">
    <slot />
    <input
      type="checkbox"
      value=""
      :id="id"
      class="sr-only peer scale-75"
      v-model="value"
    />
    <span
      class="w-11 scale-75 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"
    ></span>
    <span class="ml-3 text-sm font-normal text-gray-900 dark:text-gray-300">
    </span>
  </label>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { randomString } from '@/helpers/stringHelper.js';

const id = randomString();
interface Props {
  modelValue: boolean | null;
}

interface Emits {
  (eventName: 'update:modelValue', value: boolean): void;
}

const props = defineProps<Props>();
const emit = defineEmits<Emits>();
const value = computed({
  get: () => props.modelValue,
  set: value => emit('update:modelValue', value ?? false),
});
</script>

<style scoped></style>
