<template>
  <div class="update-info-container custom-container">
    <div v-if="lastIngestionUpdated" class="text-gray-500 custom-item">
      <icon-clock class="h-5 w-5 inline mr-2" />
      <span class="text-xs">Ingestion last updated {{ lastIngestionUpdated }}</span>
    </div>
    <div v-if="lastFindingUpdated" class="text-gray-500 custom-item">
      <icon-clock class="h-5 w-5 inline mr-2" />
      <span class="text-xs">Findings last updated {{ lastFindingUpdated }}</span>
    </div>
  </div>
</template>

<style scoped>
.custom-container {
  display: block;
}
.custom-item {
  display: block;
  margin-bottom: 1rem; /* Adjust as needed for spacing */
}
</style>

<script setup lang="ts">
import { formatDateTimeFromString } from '@/helpers/formattingHelper';
import { api } from '@/services/apiClient';
import { currentOrg, user } from '@/stores/userState';
import { ref } from 'vue';
import IconClock from '@/components/icons/IconClock.vue';

const lastIngestionUpdated = ref();
const lastFindingUpdated = ref();

if (user.value !== null) loadDataFreshness();

async function loadDataFreshness() {
  async function updateFreshness(endpoint, reactiveVariable) {
    try {
      const result = await api.get(endpoint, {
        params: {
          org: currentOrg.value?.uuid,
        },
      });
      const datetime = result.data;
      reactiveVariable.value = formatDateTimeFromString(datetime);
    } catch (e) {
      console.error(e);
    }
  }

  await updateFreshness('/dataFreshness', lastIngestionUpdated);
  await updateFreshness('/FindingFreshness', lastFindingUpdated);
}
</script>
