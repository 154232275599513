<template>
  <input type="date" v-model="value" placeholder="from" />
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { Temporal } from 'temporal-polyfill';
import { timeZone } from '@/stores/uiState.js';
interface Props {
  modelValue: Temporal.Instant;
}

interface Emits {
  (eventName: 'update:modelValue', value: Temporal.Instant): void;
}

const props = defineProps<Props>();
const emit = defineEmits<Emits>();
const value = computed({
  get: () => props.modelValue && props.modelValue.toString().split('T')[0],
  set: value => {
    const parts = value.split('-');
    emit(
      'update:modelValue',
      new Temporal.PlainDate(
        Number(parts[0]),
        Number(parts[1]),
        Number(parts[2])
      )
        .toZonedDateTime(timeZone.value)
        .toInstant()
    );
  },
});
</script>
<style></style>
