import { datadogRum } from '@datadog/browser-rum';
import { prod } from '@/helpers/utils.js';

if (prod()) {
  datadogRum.init({
    applicationId: import.meta.env.VITE_DD_APP_ID,
    clientToken: import.meta.env.VITE_DD_CLIENT_TOEKEN,
    service: 'blsweb-front',
    sampleRate: 100,
    sessionReplaySampleRate: 100,
    trackInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
}
datadogRum.startSessionReplayRecording();
export function logError(error: unknown, context?: object) {
  console.error(error);
  try {
    if (import.meta.env.MODE != 'development') {
      datadogRum.addError(error, context);
    }
  } catch (e) {}
}
