<template>
  <div>
    <span v-html="recommendation" class="whitespace-pre-wrap" />
    <code-tooltip-renderer
      :data="{ code }"
      :maskPII="false"
      v-if="code"
      class="mt-2"
    />
    <div v-if="props.data?.INFO?.TYPE === 'DOWN_SIZE_WH_REMOVE_QS'">
      <div class="mt-4 mb-2 mx-2 font-semibold">Queries to move out</div>
      <table class="dui_table table border-collapse relative w-full">
        <thead>
          <tr>
            <th>Query Signature</th>
            <th>Recommended warehouse size</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="q in signatures" :key="q.signature">
            <td class="link"><signature-renderer :value="q.signature" /></td>
            <td><warehouse-size-renderer :value="q.optimal_wh_size" /></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, inject } from 'vue';
import { i18n } from 'i18next';
import CodeTooltipRenderer from '@/components/renderers/tooltipRenderers/CodeTooltipRenderer.vue';
import { queryFunction } from '@/helpers/queryHelper.js';
import SignatureRenderer from '@/components/renderers/cellRenderers/SignatureRenderer.vue';
import WarehouseSizeRenderer from '@/components/renderers/cellRenderers/WarehouseSizeRenderer.vue';
import { getNameForWhSize } from '@/helpers/formattingHelper.js';
import { logError } from '@/services/errorTracker.js';
const intl: i18n = inject('i18n') as i18n;
interface Props {
  data: any;
}
const props = defineProps<Props>();
const signatures = computed(() => {
  try {
    let parsed: any;
    if (props.data?.INFO?.QS) {
      if (typeof props.data?.INFO?.QS === 'string') {
        let str = props.data?.INFO?.QS;
        if (str[0] === '{') {
          str = str.replaceAll('{', ',{');
          str = '[' + str.substring(1) + ']';
        }
        parsed = JSON.parse(str);
      } else parsed = props.data?.INFO?.QS;

      if (Array.isArray(parsed)) return parsed;
      else return [parsed];
    }
  } catch (e) {
    logError(e);
  }
  return [];
});

const recommendation = computed(() =>
  intl.t(
    `FINDING.${props.data.CATEGORY}.TYPES.${props.data.INFO.TYPE}.RECOMMENDATION`,
    {
      entity: props.data.WAREHOUSE_NAME ?? props.data.ROOT_CAUSE_ENTITY,
      current:
        props.data?.INFO?.optimal ||
        getNameForWhSize(props.data?.INFO['Current WH Size']),
      optimal:
        props.data?.INFO?.current ||
        getNameForWhSize(props.data?.INFO['Optimal WH Size']),
    }
  )
);

const code = computed(() => {
  const c =
    intl.t(
      `FINDING.${props.data.CATEGORY}.TYPES.${props.data.INFO.TYPE}.CODE`,
      '',
      {
        entity: `${props.data.ROOT_CAUSE_ENTITY}`,
        optimal:
          props.data?.INFO.optimal ??
          getNameForWhSize(props.data?.INFO['Optimal WH Size']),
      }
    ) ||
    (queryFunction[props.data.CATEGORY] &&
      queryFunction[props.data.CATEGORY](
        props.data.INFO,
        props.data.ROOT_CAUSE_ENTITY
      ));
  return c;
});
</script>
