<template>
  <div
    v-if="paywallData"
    @click="paywallData = null"
    class="flex absolute items-center justify-center absolute z-[999] inset-0"
    style="background-color: rgba(0, 0, 0, 0.2)"
  >
    <div class="box w-[50vw] h-[50vh] relative">
      <div class="flex w-full items-start justify-end p-4 absolute inset-0">
        <button
          type="button"
          @click="paywallData = null"
          class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
        >
          <icon-close class="w-5 h-5" />
          <span class="sr-only">Close Paywall</span>
        </button>
      </div>
      <div class="flex flex-col items-center justify-center text-lg h-full">
        <icon-lock class="h-12 w-12 gray-400 m-8" />
        <div>This feature requires an active subscription.</div>
        <opportunity-renderer
          v-if="showAdminView"
          :value="{ findings: paywallData }"
          :row="{ BEHIND_PAYWALL: false }"
          :admin="true"
          class="mt-4"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import IconLock from '@/components/icons/IconLock.vue';
import { paywallData } from '@/stores/uiState.js';
import IconClose from '@/components/icons/IconClose.vue';
import OpportunityRenderer from '@/components/renderers/cellRenderers/OpportunityRenderer.vue';
import { getPermissions } from '@/stores/userState.js';
const can = getPermissions();
const showAdminView = can?.readAny('org').granted;
</script>

<style scoped></style>
