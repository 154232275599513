<template>
  <div
    id="topbar-search-form"
    class="relative lg:block lg:pl-2"
    ref="searchWrapper"
  >
    <label for="topbar-search" class="sr-only">Search</label>
    <div class="relative lg:w-96">
      <div
        class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none"
      >
        <icon-search />
      </div>
      <input
        type="text"
        name="search"
        id="topbar-search"
        class="block w-full pl-10 p-2.5"
        placeholder="Search warehouses, tables, queries"
        ref="searchBox"
        v-model="query"
        @input="findEntities"
        @keydown="select"
        @click="reviveResults"
        autocomplete="off"
      />
      <div
        v-if="results.length > 0"
        class="absolute top-10 left-0 h-auto box-fill overlay p-0.5 min-w-[100%] max-w-[30vw]"
      >
        <div
          v-for="(result, index) in results"
          :key="result.id"
          ref="resultElements"
          class="results px-2 py-2 button secondary borderless text-md font-medium"
          :class="index === selectedIndex ? 'selected' : ''"
        >
          <div
            class="flex items-stretch gap-2 border-2 rounded-lg border-white bg-default pr-2"
          >
            <div
              class="px-2 text-sm capitalize flex items-center rounded-l-lg bg-slate-100 min-w-[85px] justify-center"
            >
              <span class="text-xs font-semibold">{{
                typeName[result.type]
              }}</span>
            </div>
            <table-renderer
              :value="result.id"
              :full-name="true"
              v-if="result.type === EntityType.TABLE"
              class="w-full"
            />
            <signature-renderer
              :value="result.name"
              v-if="result.type === EntityType.QSIG"
              class="w-full"
            />
            <warehouse-renderer
              :full-name="true"
              :value="result.name"
              v-if="result.type === EntityType.WH"
              :max-width="500"
              class="w-full"
            />
            <query-id-renderer
              :value="result.name"
              v-if="result.type === EntityType.QUERY"
              class="w-full"
              :extended="true"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import IconSearch from '@/components/icons/IconSearch.vue';
import { api } from '@/services/apiClient.js';
import { currentOrg } from '@/stores/userState.js';
import TableRenderer from '@/components/renderers/cellRenderers/TableRenderer.vue';
import { EntityType } from '@/types.js';
import SignatureRenderer from '@/components/renderers/cellRenderers/SignatureRenderer.vue';
import WarehouseRenderer from '@/components/renderers/cellRenderers/WarehouseRenderer.vue';
import QueryIdRenderer from '@/components/renderers/cellRenderers/QueryIdRenderer.vue';
import { track } from '@/services/analytics.js';

const router = useRouter();
const searchBox = ref();
const searchWrapper = ref();
const resultElements = ref();
const selectedIndex = ref();
const query = ref('');
const results = ref<any[]>([]);
let oldResults: any[], oldQuery: string;

async function findEntities(event: InputEvent) {
  if (query.value?.length < 3) return;
  const result = await api.get('/findEntities', {
    params: {
      org: currentOrg.value?.uuid,
      query: query.value,
    },
  });
  results.value = result.data;
  oldResults = results.value;
  oldQuery = query.value;
  selectedIndex.value = undefined;

  track('Global Search', {
    Search: query.value,
    'While on Page': router.currentRoute.value.path,
  });
}
function select(event: KeyboardEvent) {
  const length = results.value?.length ?? 0;
  if (length === 0) return;
  if (event.key === 'ArrowUp') {
    if (selectedIndex.value === undefined || selectedIndex.value === 0)
      selectedIndex.value = length - 1;
    else selectedIndex.value -= 1;
  } else if (event.key === 'ArrowDown') {
    if (selectedIndex.value === undefined || selectedIndex.value === length - 1)
      selectedIndex.value = 0;
    else selectedIndex.value += 1;
  } else if (event.key === 'Enter' && selectedIndex.value >= 0) {
    router.push(
      resultElements.value[selectedIndex.value].children[0].attributes['href']
        .value
    );
    results.value = [];
  }
}

function reviveResults() {
  if (query.value === oldQuery) results.value = oldResults;
}

document.addEventListener('click', hideIfClickedOut);
function hideIfClickedOut(event: MouseEvent) {
  if (
    results.value &&
    searchBox.value !== event.target &&
    !searchWrapper.value?.target?.contains(event.target as HTMLElement) &&
    searchWrapper.value?.target !== event.target
  ) {
    results.value = [];
    selectedIndex.value = undefined;
  }
}

const typeName = {
  [EntityType.WH]: 'Warehouse',
  [EntityType.TABLE]: 'Table',
  [EntityType.QSIG]: 'Query Sig.',
  [EntityType.QUERY]: 'Query',
};
</script>
<style scoped>
.results.button.secondary {
  @apply bg-transparent;
}
.results.button.secondary:hover {
  @apply bg-white ring-2 ring-blue-500;
}
</style>
