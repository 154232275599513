import {
  activeAccount,
  currentOrg,
  hasFeature,
  isSuperAdmin,
  switchOrg,
  user,
} from '@/stores/userState.js';
import { createRouter, createWebHistory } from 'vue-router';
import { dev, testing } from '@/helpers/utils.js';

import { Feature } from '@/types.js';
import { openTrialExpireModal } from '@/stores/uiState';
import { track } from '@/services/analytics.js';

let navigationTimer: number;

const routes = [
  {
    path: '/:catchAll(.*)',
    name: 'not-found',
    component: () => import('./views/PageNotFoundView.vue'),
  },
  {
    path: '/',
    name: 'root',
    redirect: { name: 'home' },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('./views/LoginView.vue'),
    meta: { hideMenus: true, public: true, auth: true },
  },
  {
    path: '/loaduser',
    name: 'load-user',

    component: () => import('./views/LoadUserView.vue'),
    meta: { hideMenus: true, public: true },
  },
  {
    path: '/org/:orgUuid*',
    name: 'parent-route',

    children: [
      {
        path: ':catchAll(.*)',
        name: 'not-found-org-level',
        component: () => import('./views/PageNotFoundView.vue'),
      },
      {
        path: 'home',
        name: 'home',
        component: () => import('./views/Home/HomeView.vue'),
        meta: { hideCommonFilters: true },
      },

      {
        path: 'subscription',
        name: 'subscription',
        component: () => import('./views/Subscription/SubscriptionView.vue'),
        meta: { hideCommonFilters: true },
      },
      {
        path: 'monitors',
        name: 'monitors',
        component: () => import('./views/MonitorIndex/MonitorIndexView.vue'),
        meta: { hideCommonFilters: true },
      },
      {
        path: 'add-monitor',
        name: 'add-monitor',
        component: () => import('./views/AddMonitor/EditMonitorView.vue'),
        meta: { hideCommonFilters: true },
      },
      {
        path: 'edit-monitor/:uuid',
        name: 'edit-monitor',
        component: () => import('./views/AddMonitor/EditMonitorView.vue'),
        meta: { hideCommonFilters: true },
      },
      {
        path: 'storage',
        name: 'storages',
        component: () => import('./views/Storage/StorageView.vue'),
        meta: { hideCommonFilters: true },
      },
      {
        path: 'sessions',
        name: 'sessions',
        component: () => import('./views/Session/SessionIndexView.vue'),
      },
      {
        path: 'queries',
        name: 'queries',
        component: () => import('./views/QueryIndex/QueryIndexView.vue'),
      },
      {
        path: 'warehouses',
        name: 'warehouses',
        component: () =>
          import('./views/Compute/Warehouse/WarehouseIndexView.vue'),
        children: [
          {
            path: '',
            name: 'root-warehouses',
            redirect: {
              name: 'warehouse-config',
            },
          },

          {
            path: 'config',
            name: 'warehouse-config',
            component: () =>
              import('./views/Compute/Warehouse/WarehouseConfigView.vue'),
          },

          {
            path: 'analytics',
            name: 'warehouse-analytics',
            component: () =>
              import('./views/Compute/Warehouse/WarehouseAnalyticsView.vue'),
          },
        ],
      },
      {
        path: 'findings',
        name: 'findings',
        component: () => import('./views/Findings/Findings.vue'),
        children: [
          {
            path: 'open',
            name: 'open-findings',
            component: () => import('./views/Findings/OpenFindings.vue'),
            meta: { hideCommonFilters: true },
          },
          {
            path: '',
            name: 'open',
            redirect: {
              name: 'open-findings',
            },
            meta: { hideCommonFilters: true },
          },
          {
            path: 'resolved',
            name: 'resolved-findings',
            component: () => import('./views/Findings/ResolvedFindings.vue'),
            meta: { hideCommonFilters: true },
          },
          {
            path: 'dismissed',
            name: 'dismissed-findings',
            component: () => import('./views/Findings/DismissedFindings.vue'),
            meta: { hideCommonFilters: true },
          },
          {
            path: 'snoozed',
            name: 'snoozed-findings',
            component: () => import('./views/Findings/ResolvedFindings.vue'),
            meta: { hideCommonFilters: true },
          },
          {
            path: 'request',
            name: 'request',
            component: () => import('./views/Findings/OpenFindings.vue'),
            meta: { hideCommonFilters: true },
          },
        ],
      },
      {
        path: 'alerts',
        name: 'alerts',
        component: () => import('./views/Alerts/AlertsList.vue'),
        meta: { hideCommonFilters: true }, 
      },
      {
        path: 'wh-explore',
        name: 'wh-explore',
        component: () => import('./views/Compute/WarehouseExplorer.vue'),
        meta: { hideCommonFilters: true },
      },
      {
        path: 'integrations',
        name: 'integrations',
        component: () => import('./views/Integrations/IntegrationsView.vue'),
      },
      {
        path: 'pagerduty/install',
        name: 'pagerduty-install',
        component: () =>
          import('./views/Integrations/PagerdutyInstallView.vue'),
      },
      {
        path: 'pagerduty/config',
        name: 'pagerduty-config',
        component: () => import('./views/Integrations/PagerdutyView.vue'),
      },
      {
        path: 'warehouse/:warehouse',
        name: 'warehouse',
        component: () => import('./views/Compute/Warehouse/WarehouseView.vue'),
        meta: { hideCommonFilters: true },
      },
      {
        path: 'query/:id',
        name: 'query',
        component: () => import('./views/Query/QueryView.vue'),
        meta: { hideCommonFilters: true },
      },
      {
        path: 'query/:qs/lineage',
        name: 'query-lineage',
        component: () => import('./views/LineageView.vue'),
        meta: { hideCommonFilters: true },
      },
      {
        path: 'session/:id',
        name: 'session',
        component: () => import('./views/Session/SessionView.vue'),
        meta: { hideCommonFilters: true },
      },
      {
        path: 'session/:sessionId/lineage',
        name: 'session-lineage',
        component: () => import('./views/LineageView.vue'),
        meta: { hideCommonFilters: true },
      },
      {
        path: 'lineage/:sessionId',
        name: 'lineage',
        component: () => import('./views/LineageView.vue'),
        meta: { hideCommonFilters: true },
      },
      {
        path: 'database/:db/schema/:schema/table/:table',
        name: 'table-home',
        component: () => import('./views/Table/TableView.vue'),
        meta: { hideCommonFilters: true },
      },
      {
        path: 'database/:db/schema/:schema/table/:table/lineage',
        name: 'table-lineage',
        component: () => import('./views/LineageView.vue'),
        meta: { hideCommonFilters: true },
      },
    ],
  },
];

if (testing() || dev()) {
  routes.push({
    path: '/loginTest',
    name: 'login-test',
    component: () => import('./views/LoginTestView.vue'),
    meta: { hideMenus: true, public: true, auth: true },
  });
}

const adminRoutes = [
  {
    path: '/admin/home',
    name: 'admin-home',
    component: () => import('./views/Admin/Admin.vue'),
    meta: { hideCommonFilters: true },
  },
  {
    path: '/admin/trial-manager',
    name: 'trial-manager',
    component: () => import('./views/Admin/TrialManager.vue'),
    meta: { hideCommonFilters: true },
  },
  {
    path: '/admin/add-dp-analytics',
    name: 'add-dp-analytics',
    component: () => import('./views/Admin/NewDpAnalytics.vue'),
    meta: { hideCommonFilters: true },
  },
  {
    path: '/admin/add-dp',
    name: 'add-dp',
    component: () => import('./views/Admin/NewDp.vue'),
    meta: { hideCommonFilters: true },
  },
  {
    path: '/admin/set-saml',
    name: 'set-saml',
    component: () => import('./views/Admin/SamlSetup.vue'),
    meta: { hideCommonFilters: true },
  },
  {
    path: '/admin/whitelist-email',
    name: 'whitelist-email',
    component: () => import('./views/Admin/WhitelistEmail.vue'),
    meta: { hideCommonFilters: true },
  },
  {
    path: '/admin/test-send-monitor-email',
    name: 'test-send-monitor-email',
    component: () => import('./views/Admin/TestSendMonitorEmail.vue'),
    meta: { hideCommonFilters: true },
  },
  {
    path: '/admin/view-requests',
    name: 'view-requests',
    component: () => import('./views/Admin/RequestTable.vue'),
    meta: { hideCommonFilters: true },
  },
];
adminRoutes.forEach(r => Object.assign(r.meta, { admin: true }));

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [...routes, ...adminRoutes],
});

router.beforeEach((to, from, next) => {
  if (!to.query?.fromDate && from.query?.fromDate) {
    next({
      ...to,
      query: {
        fromDate: from.query.fromDate,
        toDate: from.query.toDate,
        ...to.query,
      },
    });
  } else {
    next();
  }
});
router.beforeEach((to, from, next) => {
  let orgUuid: string | null = Array.isArray(to.params.orgUuid ?? '')
    ? to.params.orgUuid[0]
    : (to.params.orgUuid as string);
  const currentUuid = currentOrg.value?.uuid;

  //this is for handling a problematic situation that affected bls internal users
  if (currentOrg.value && !currentOrg.value.uuid) {
    localStorage.clear();
    location.href = location.origin;
    return;
  }
  if (orgUuid && (orgUuid as string).split('-').length < 4) {
    orgUuid = null;
  }
  if (orgUuid && currentUuid !== orgUuid) switchOrg(orgUuid);

  const userHomeRoute = hasFeature(Feature.FINANCIALS) ? 'home' : 'queries';
  const trialRedirect =
    !activeAccount.value && // redirect for expired account
    to.name !== userHomeRoute && // avoid redirect loop
    to.name != 'load-user' &&
    !isSuperAdmin; // superadmin can always navigate.
  if (user.value) {
    if (to.meta?.auth) {
      const redirect: string = (to.query.redirect as string) || '/';
      next({ path: redirect });
    } else if (trialRedirect) {
      next({ name: userHomeRoute });
      openTrialExpireModal.value = true;
    } else if (to.meta.admin && !isSuperAdmin) {
      next({ name: 'home' });
    } else {
      if (orgUuid || to.meta.admin || to.meta.public) return next();
      const nextTo: any = {
        params: {
          ...to.params,
          orgUuid: currentUuid,
        },
        query: to.query,
      };
      if (!to.path.startsWith('/org')) {
        nextTo.path = `/org/${currentUuid}${to.path}`;
        nextTo.fullPath = `/org/${currentUuid}${to.fullPath}`;
      } else if (to.name) {
        nextTo.name = to.name as string;
      }
      return next(nextTo);
    }
  } else {
    if (!to.meta?.public) {
      next({
        name: 'login',
        query: {
          redirect: to.fullPath,
        },
      });
    } else next();
  }

  // Check if the route does not exist
  const isValidRoute = router.hasRoute(to.name as string);
  if (!isValidRoute) {
    next({ name: 'not-found' }); // show the 404 not found page
  }
});

router.afterEach(async (to, from) => {
  // Track "Page Exit" event for previous page
  if (to.path !== '/login') {
    // Skip '/login' because it is the initial landing page
    const eventProps: any = {
      // The page before '/loaduser' is Google SSO, thus track '/login' instead as it is the page that's one further step before.
      Page: to.name === 'load-user' ? 'login' : from.name,
      Path: to.path === '/loaduser' ? '/login' : from.path,
    };
    if (navigationTimer > 0) {
      eventProps['$duration'] = Date.now() - navigationTimer;
    }
    track('Page Exit', eventProps);
  }

  // Track "Page Enter" for current page
  track('Page Enter', {
    Page: to.name,
    Path: to.path,
  });

  navigationTimer = Date.now();
});

router.beforeEach((to, from, next) => {
  // if they cannot see financials and they're going 'home' redirect them to query list.
  const dest = to.name as string;
  if (
    !hasFeature(Feature.FINANCIALS) &&
    (dest === 'home' || dest?.includes('findings') || dest?.includes('monitor'))
  ) {
    next({
      name: 'queries',
      params: { orgUuid: currentOrg.value?.uuid },
    });
  } else next();
});

export default router;
