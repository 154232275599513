<template>
  <div class="mx-5">
    <div class="px-4">
      <span v-if="trialAccount">
        {{ daysToExpire() }} DAYS LEFT IN YOUR FREE TRIAL
      </span>
      <span v-else-if="!activeAccount">
        Trial expired at {{ expireDate() }}
      </span>
      <a
        v-if="!paidAccount"
        target="_blank"
        class="text-blue-400 uppercase underline"
        :href="`mailto:${contactEmail.email}?subject=${contactEmail.subject}`"
      >
        Upgrade Now
      </a>
    </div>
    <div
      v-if="!paidAccount"
      class="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700"
    >
      <div
        class="h-2.5 rounded-full"
        :class="trialAccount ? 'bg-yellow-500' : 'bg-red-500'"
        :style="{ width: daysToExpirePercentage() }"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  currentOrg,
  trialAccount,
  activeAccount,
  paidAccount,
} from '@/stores/userState.js';
import type { i18n } from 'i18next';
import { inject } from 'vue';
const intl = inject('i18n') as i18n;
const contactEmail = {
  email: 'bluesky-sales@getbluesky.io',
  subject: intl.t('TOPBAR.CONTACT_EMAIL_SUBJECT'),
};

const expireDate = (): string => {
  return new Date(currentOrg?.value?.trialEnd).toDateString();
};

const daysToExpire = (): number | undefined => {
  if (!currentOrg?.value?.trialEnd) return undefined;
  const today = Date.now();
  const expire = new Date(currentOrg?.value?.trialEnd?.toString()).getTime();
  const diff = Math.round((expire - today) / (1000 * 60 * 60 * 24));
  return diff;
};

const daysToExpirePercentage = (): string | undefined => {
  if (!activeAccount) return '100%';

  const expire = new Date(currentOrg?.value?.trialEnd?.toString()).getTime();
  const created = new Date(currentOrg?.value?.created?.toString()).getTime();
  const totalDays = Math.round((expire - created) / (1000 * 60 * 60 * 24));
  const daysLeft = daysToExpire();
  if (!daysLeft) return undefined;
  const percentage = (1 - daysLeft / totalDays) * 100 + '%';
  return percentage;
};
</script>
