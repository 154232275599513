<template>
  <toast />
  <loading-icon
    class="inset-0 m-auto h-8 w-8 absolute z-[1000] top-[40vh]"
    v-if="appLoading"
  />
  <main v-if="hideMenus"><views /></main>
  <main v-else :class="openModals > 0 ? 'hide-scroll' : ''">
    <top-bar />
    <chart-tooltip />

    <paywall />
    <drawer name="mainSidebar" class="main-drawer">
      <template #main>
        <views class="app-content" />
      </template>
      <template #side>
        <side-bar />
      </template>
    </drawer>
  </main>
</template>
<script setup lang="ts">
import {
  openModals,
  applyAppearanceMode,
  appLoading,
} from './stores/uiState.js';
import TopBar from '@/components/base/TopBar.vue';
import SideBar from '@/components/base/SideBar.vue';
import Drawer from '@/components/base/Drawer.vue';
import Views from '@/components/base/Views.vue';
import ChartTooltip from '@/components/ChartTooltip.vue';
import Toast from '@/components/base/Toast.vue';
import Paywall from '@/components/Paywall.vue';
import LoadingIcon from '@/components/base/LoadingIcon.vue';
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { track } from '@/services/analytics.js';
import { logError } from '@/services/errorTracker.js';
const router = useRouter();
const hideMenus = computed(() => !!router.currentRoute.value?.meta?.hideMenus);

applyAppearanceMode();
document.addEventListener('click', trackClicks);
function trackClicks(event: MouseEvent) {
  try {
    const buttonText = (event.target as HTMLElement).closest(
      'button, a'
    )?.textContent;
    if (!buttonText) return;

    track('Link Clicked', {
      Link: buttonText,
    });
  } catch (e) {
    logError(e);
  }
}
</script>

<style scoped>
main {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: relative;
}

.hide-scroll {
  overflow: hidden;
}
.main-drawer {
  margin-top: 4rem;
  height: calc(100% - 4rem);
}
</style>
