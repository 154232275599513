<template>
  <router-link
    :to="{
      name: 'table-home',
      params: { orgUuid: currentOrg?.uuid, db, schema, table },
    }"
    class="overflow-x-hidden"
  >
    <span v-if="fullName"
      ><span class="flex flex-col" :title="table.toLowerCase()"
        ><span class="text-ellipsis overflow-x-hidden block">{{
          table.toLowerCase()
        }}</span>
        <span class="flex"
          ><span class="tag"
            ><span class="font-normal">DB:&nbsp;&nbsp;</span
            >{{ db.toLowerCase() }}</span
          ><span class="tag"
            ><span class="font-normal">Schema:&nbsp;&nbsp;</span
            >{{ schema.toLowerCase() }}</span
          ></span
        ></span
      ></span
    >

    <string-renderer
      :value="props.fullyQualified ? [db, schema, table].join('.') : table"
      :max-width="maxWidth"
      v-else
    />
  </router-link>
</template>

<script setup lang="ts">
import StringRenderer from '@/components/renderers/cellRenderers/StringRenderer.vue';
import { computed } from 'vue';
import { currentOrg } from '@/stores/userState.js';
interface Props {
  value: string;
  row?: any;
  fullName?: boolean;
  fullyQualified?: boolean;
  maxWidth?: number;
}
const props = defineProps<Props>();

const db = computed(() =>
  (props.row?.DATABASE_NAME ?? props.value.split('.')[0])?.replaceAll('"', '')
);
const schema = computed(() =>
  (props.row?.SCHEMA_NAME ?? props.value.split('.')[1])?.replaceAll('"', '')
);
const table = computed(() => {
  let tableName;
  if (!props.row?.SCHEMA_NAME && props.value.split('.').length >= 3) {
    tableName = props.value.split('.')[2];
  } else tableName = props.value;
  return tableName?.replaceAll('"', '');
});
</script>

<style scoped></style>
