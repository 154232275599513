<template>
  <icon-copy class="button minimal p-0" v-if="!copied" @mousedown.stop="copy" />
  <icon-check v-else class="button minimal p-0" />
</template>

<script setup lang="ts">
import { ref } from 'vue';
import IconCheck from '@/components/icons/IconCheck.vue';
import IconCopy from '@/components/icons/IconCopy.vue';

// Either copy from the parent html content, or from the passed in `code` props.
const props = defineProps({
  copyFromParentTextContent: {
    type: Boolean,
    default: true
  },
  code: {
    type: String,
    default: null
  }
});

const copied = ref(false);
async function copy(e: MouseEvent) {
  let textToCopy = '';
  if (props.copyFromParentTextContent) {
    textToCopy = (e.currentTarget as HTMLElement).parentNode?.textContent ?? '';
  } else {
    textToCopy = props.code;
  }

  await navigator.clipboard.writeText(textToCopy);
  copied.value = true;
  setTimeout(() => (copied.value = false), 1500);
}
</script>
