<template>
  <nav
    class="fixed z-30 w-full h-16 bg-white border-b border-gray-200 dark:bg-black dark:border-gray-700"
  >
    <div class="py-3 px-3 lg:px-5 lg:pl-3">
      <div class="flex justify-between items-center">
        <div class="flex justify-start items-center">
          <drawer-button name="mainSidebar"><IconMenu /></drawer-button>
          <a href="/" class="flex mr-14 ml-4">
            <img src="@/assets/logo.png" class="mr-3 h-8" alt="Bluesky Logo" />
            <span
              class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white"
              ><img
                src="@/assets/logotext.png"
                class="mr-3 h-8 mt-1"
                alt="Snowflake Logo"
            /></span>
          </a>
        </div>
        <div class="flex items-center">
          <AccountState v-if="currentOrg" />
          <search-bar />
          <chart-setup v-if="!hideCommonFilters" />
          <div class="flex items-center mx-3">
            <dropdown>
              <template #label>
                <button
                  type="button"
                  class="p-0 flex text-sm bg-gray-200 rounded-full focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
                  id="user-menu-button-2"
                  aria-expanded="false"
                  data-dropdown-toggle="dropdown-2"
                >
                  <span class="sr-only">{{ $t('TOPBAR.OPEN_USER_MENU') }}</span>
                  <icon-account class="account-icon" />
                </button>
              </template>

              <div class="py-3 px-4 flex flex-col gap-3" role="none">
                <div>
                  <p class="text-sm text-gray-900 dark:text-white" role="none">
                    {{ user?.firstName }}&nbsp;{{ user?.lastName }}
                  </p>
                  <p
                    class="text-sm font-medium text-gray-900 truncate dark:text-gray-300"
                    role="none"
                  >
                    {{ user?.email }}
                  </p>
                </div>
                <div class="flex items-center justify-between">
                  <toggle v-model="experimental">Experimental</toggle>
                </div>

                <a
                  href="#"
                  @click="logout"
                  class="block py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                  >{{ $t('TOPBAR.SIGN_OUT') }}</a
                >
              </div>
            </dropdown>
            <div
              class="font-semibold text-xl ml-2"
              v-if="!isSuperAdmin || !publicView"
            >
              <span class="text-sm text-muted">{{ $t('COMMON.FROM') }}</span>
              {{ currentOrg?.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>
<script setup lang="ts">
import ChartSetup from '@/components/ChartSetup.vue';
import IconMenu from '@/components/icons/IconMenu.vue';
import DrawerButton from '@/components/base/DrawerButton.vue';
import Dropdown from '@/components/base/Dropdown.vue';
import IconAccount from '@/components/icons/IconAccount.vue';
import { user, currentOrg, isSuperAdmin } from '@/stores/userState.js';
import AccountState from '@/components/base/AccountState.vue';
import { api } from '@/services/apiClient.js';
import { inject } from 'vue';
import { useRouter } from 'vue-router';
import { ToastType } from '@/types';
import { showToast } from '@/helpers/toastHelper';

import {
  appearanceMode,
  experimental,
  hideCommonFilters,
  publicView,
} from '@/stores/uiState.js';
import { AppearanceMode } from '@/types.js';
import type { i18n } from 'i18next';
import { logError } from '@/services/errorTracker.js';
import SearchBar from '@/components/base/SearchBar.vue';
import Toggle from '@/components/base/Toggle.vue';
const intl = inject('i18n') as i18n;

const router = useRouter();
async function logout() {
  try {
    await api.get('/auth/logout');
    user.value = null;
    localStorage.clear();

    await router.push({ name: 'root' });
    await router.go(0);
  } catch (e) {
    logError(e);
    showToast(ToastType.ERROR, intl.t('ERROR.LOGOUT'));
  }
}
</script>
<style scoped>
:deep(.account-icon) {
  height: 32px;
}
.modes > div {
  @apply cursor-pointer;
}
.modes .active {
  @apply border-blue-500 font-bold;
}
</style>
