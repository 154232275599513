import { Temporal } from 'temporal-polyfill';
import * as d3 from 'd3';
import { pause } from '@/helpers/utils.js';
import { groupBy } from '@/helpers/chartHelper.js';
import { formatDateFromString } from './formattingHelper';
import { timeZone } from '@/stores/uiState.js';
export const dateToInstant = function (date: Temporal.PlainDate) {
  return date.toZonedDateTime(timeZone.value).toInstant();
};
export function toLocalTime(stringDate: string): Temporal.PlainDateTime {
  if (
    stringDate.indexOf('+000') > 0 &&
    stringDate.toLowerCase().indexOf('z') < 0
  )
    stringDate = snowflakeToIsoDateString(stringDate);
  return Temporal.Now.timeZone().getPlainDateTimeFor(
    Temporal.Instant.from(stringDate)
  );
}
export function startOfWeek(date: Temporal.PlainDate) {
  return date.subtract({ days: date.dayOfWeek });
}
export function endOfWeek(date: Temporal.PlainDate) {
  return date.add({ days: 7 - date.dayOfWeek });
}
export const startOfMonth = function (date: Temporal.PlainDate) {
  const { year, month } = date;
  return Temporal.PlainDate.from({ year, month, day: 1 });
};
export const startOfMonthInstant = function (date: Temporal.Instant) {
  const { year, month } = date.toZonedDateTimeISO(timeZone.value);
  return Temporal.Instant.from(
    Temporal.PlainDate.from({ year, month, day: 1 })
      .toZonedDateTime(timeZone.value)
      .toString()
  );
};
export const endOfMonth = function (date: Temporal.PlainDate) {
  const { year, month } = date;
  return Temporal.PlainDate.from({ year, month: month + 1, day: 0 });
};

export const startOfYear = function (date: Temporal.PlainDate) {
  const { year } = date;
  return Temporal.PlainDate.from({ year, month: 1, day: 1 });
};

export const nowDate = function (): Temporal.PlainDate {
  return Temporal.Now.plainDateISO(timeZone.value);
};

export const nowInstant = function () {
  return Temporal.Now.instant();
};

export const daysAgo = function (num: number): Temporal.Instant {
  return startOfDay(nowInstant().subtract({ hours: 24 * num }));
};
export const startOfToday = function (): Temporal.Instant {
  // get the day at 00:00
  return nowInstant()
    .toZonedDateTimeISO(timeZone.value)
    .startOfDay()
    .toInstant();
};
export const startOfDay = function (date: Temporal.Instant): Temporal.Instant {
  // get the day at 00:00
  return date.toZonedDateTimeISO(timeZone.value).startOfDay().toInstant();
};

export const endOfDay = function (date: Temporal.Instant): Temporal.Instant {
  // get the day at 23:59
  return date
    .toZonedDateTimeISO(timeZone.value)
    .add({ days: 1 })
    .startOfDay()
    .subtract({ seconds: 1 })
    .toInstant();
};

export function snowflakeToIsoDateString(snowDate: string) {
  const parts = snowDate.split(' ');
  const date = parts[0];
  const time = parts[1];
  return `${date}T${time}Z`;
}

export function dateDiff(first: Date, second: Date) {
  return Math.round(
    (second.getTime() - first.getTime()) / (1000 * 60 * 60 * 24)
  );
}

export function dayOfYear() {
  const now = new Date();
  const start = new Date(now.getUTCFullYear(), 0, 0);
  const diff = now.getTime() - start.getTime();
  const oneDay = 1000 * 60 * 60 * 24;
  return Math.floor(diff / oneDay);
}
export function currentQuarter() {
  return Math.ceil((new Date().getUTCMonth() + 1) / 3);
}
export function startOfQuarter(quarter: number): Date {
  return new Date(new Date().getUTCFullYear(), (quarter - 1) * 3, 1);
}
export function endOfQuarter(quarter: number): Date {
  const start = startOfQuarter(quarter);
  return new Date(start.getUTCFullYear(), start.getUTCMonth() + 3, 0);
}

export function utcDate(date: Date) {
  return new Date(
    date.toLocaleString('en-US', {
      timeZone: 'UTC',
    })
  );
}


function getClusterNumberFromGroup(group: any[]) {
  return group.every((item: any) => item.CLUSTER_NUMBER === group[0].CLUSTER_NUMBER) ? group[0].CLUSTER_NUMBER : null;
}

// Chop up the input events into blocks based on their time interval end points.
// `idColumn` defines a group key. e.g. if it's `ROW_NUM`, then each input event forms its own object.
// If `grouping` is specified, further group the slices based on that column.
//
// For now no caller sets createZeroBlocks to true.
export async function getBlocks(
  events: any[],
  periodEnd: Date,
  periodStart: Date,
  idColumn: string,
  createZeroBlocks = false,
  grouping?: string
) {
  document.body.classList.add('waiting');
  if ((events?.length ?? 0) === 0) {
    document.body.classList.remove('waiting');
    return [];
  }

  // Pivot each event into a pair of breakpoints start and end, and process these end points in time ordering.
  let breakpoints: any[] = [];
  events.forEach((e: any) => {
    breakpoints.push({ date: e.start, type: 'start', event: e });
    breakpoints.push({ date: e.end, type: 'end', event: e });
  });
  breakpoints = breakpoints.sort((a, b) => a.date - b.date);

  // For each time window formed by 2 consecutive start/end points from `events` (aka, two adjacement rows of `breakpoints`), 
  // it has an entry in `groups`, where `items` contains all the chopped up slices.
  let groups: any[] = [];
  // Tracks the current group we are processing.
  const currentGroup: Record<any, any> = {};
  let start = null;
  let i = 0;
  while (i < breakpoints.length) {
    const currentGroupValues = Object.values(currentGroup) as any[];
    const current = breakpoints[i];
    if (currentGroupValues.length > 0 && start !== null) {
      if (
        createZeroBlocks &&
        groups.length > 0 &&
        start > groups[groups.length - 1].end
      ) {
        groups.push({
          start: groups[groups.length - 1].end,
          end: d3.max<Date>([start, periodStart]),
          count: 0,
          items: [],
          cluster: getClusterNumberFromGroup(currentGroupValues),
        });
      }
      const count = currentGroupValues.reduce((total, item) => total + (item.COUNT ? Number(item.COUNT) : 1), 0);
      groups.push({
        start: new Date(Math.max(start.getTime(), periodStart.getTime())),
        end: new Date(
          Math.min(breakpoints[i].date.getTime(), periodEnd.getTime())
        ),
        count,
        items: [...currentGroupValues],
        cluster: getClusterNumberFromGroup(currentGroupValues),
      });
    }
    start = current.date;

    while (
      i < breakpoints.length &&
      breakpoints[i].date.getTime() === current.date.getTime()
    ) {
      const current = breakpoints[i];

      if (current.type === 'start') {
        currentGroup[current.event[idColumn]] = current.event;
      } else {
        delete currentGroup[current.event[idColumn]];
      }
      i++;
    }
  }

  // "Flush" the last outstanding group into `groups`.
  const currentGroupValues = Object.values(currentGroup) as any[];
  if (currentGroup.length > 0 && start !== null) {
    const count = currentGroupValues.reduce((total, item) => total + (item.COUNT ? Number(item.COUNT) : 1), 0);
    groups.push({
      start,
      end: periodEnd,
      count,
      items: [...currentGroupValues],
      cluster: getClusterNumberFromGroup(currentGroupValues),
    });
  }
  // if (events[0].BYTES_SCANNED)
  document.body.classList.remove('waiting');
  if (groups.length > 0 && grouping) {
    const newGroups: any[] = [];
    groups.forEach(group => {
      const grouped = groupBy(group.items, grouping);
      let startCount = 0;
      Object.entries(grouped)
        .sort((a, b) => Number(b[0]) - Number(a[0]))
        .forEach(entry => {
          const subgroup = { ...group };
          subgroup[grouping] = entry[0];
          subgroup.items = entry[1];
          subgroup.count = subgroup.items.reduce((total: number, item: any) => total + (item.COUNT ? Number(item.COUNT) : 1), 0);
          subgroup.startCount = startCount;
          newGroups.push(subgroup);
          startCount += subgroup.count;
        });
    });
    groups = newGroups;
  }
  return groups;
}

export function rangeFilter(from: Date, to: Date) {
  return (rangeItem: { start: Date; end: Date }) => {
    const start = rangeItem.start;
    const end = rangeItem.end;
    return (
      (start > from && start < to) ||
      (end > from && end < to) ||
      (end > to && start < from)
    );
  };
}
export function instantToPlainDate(instant: Temporal.Instant | Date) {
  const stringValue =
    'toISOString' in instant ? instant.toISOString() : instant.toString();
  return Temporal.PlainDate.from(stringValue.split('T')[0]);
}

export function formatDateSimple(date: Date) {
  return date.toISOString().split('T')[0];
}

export function formatDateWithMonthDay(date: Date) {
  return formatDateFromString(
    startOfWeek(instantToPlainDate(date)).toString()
  ).split(',')[0];
}

export function formatWeekRangeFromDate(date: Date) {
  const d = instantToPlainDate(date);
  const weeklyRange = `
    ${formatDateWithMonthDay(date)}
    -
    ${formatDateFromString(endOfWeek(d).toString())}`;
  return weeklyRange;
}

export const quarterInterval = {
  floor: (date: Date) => {
    const time = 1000 * 60 * 15;
    return new Date(date.getTime() - (date.getTime() % time));
  },
  offset: (date: Date, step: number) => {
    return new Date(date.getTime() + (step ?? 1) * 15 * 60 * 1000);
  },
  range: (start: Date, stop: Date, steps: number) => {
    return d3.utcMinutes(start, stop, (steps ?? 1) * 15);
  },
};
