<template>
  <router-link
    :to="{
      name: 'query',
      params: { orgUuid: currentOrg?.uuid, id: value },
    }"
    class="overflow-x-hidden"
  >
    <span v-if="extended">{{ value }}</span>
    <short-string-renderer :value="value" v-else
  /></router-link>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { currentOrg } from '@/stores/userState.js';
import ShortStringRenderer from '@/components/renderers/cellRenderers/ShortStringRenderer.vue';

interface Props {
  value: string;
  extended?: boolean;
}
const props = defineProps<Props>();
const url = computed(
  () =>
    `https://${currentOrg.value?.snowflakeUrl}/#/compute/history/queries/${props.value}/profile`
);
</script>
