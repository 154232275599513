<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M1.96808 17.4304V8.1246C1.96803 7.78156 2.05265 7.44381 2.21444 7.14132C2.37623 6.83883 2.61018 6.58095 2.89554 6.39057L10.1901 1.52819C10.5326 1.29968 10.9351 1.17773 11.3469 1.17773C11.7586 1.17773 12.1611 1.29968 12.5036 1.52819L19.7982 6.39057C20.0836 6.58095 20.3175 6.83883 20.4793 7.14132C20.6411 7.44381 20.7257 7.78156 20.7257 8.1246V17.4304M1.96808 17.4304C1.96808 17.9832 2.18766 18.5133 2.57852 18.9042C2.96938 19.295 3.4995 19.5146 4.05225 19.5146H18.6415C19.1942 19.5146 19.7244 19.295 20.1152 18.9042C20.5061 18.5133 20.7257 17.9832 20.7257 17.4304M1.96808 17.4304L9.00217 12.741M20.7257 17.4304L13.6916 12.741M9.00217 12.741L1.96808 8.05166M9.00217 12.741L10.1901 13.533C10.5326 13.7615 10.9351 13.8835 11.3469 13.8835C11.7586 13.8835 12.1611 13.7615 12.5036 13.533L13.6916 12.741M13.6916 12.741L20.7257 8.05166"
      stroke="currentColor"
      stroke-width="2.08418"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
