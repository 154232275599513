import Axios, { AxiosResponse } from 'axios';
import type { AxiosRequestConfig } from 'axios';
import { user } from '@/stores/userState.js';
import { dev, testing } from '@/helpers/utils.js';
import { track } from '@/services/analytics.js';
import { showToast } from '@/helpers/toastHelper.js';
import { ToastType } from '@/types.js';
const options: AxiosRequestConfig = {
  baseURL: import.meta.env.VITE_ROOT_API,
  timeout: 40000,
  headers: {},
};

if (dev() || testing()) {
  options.withCredentials = true;
}

const axios = Axios.create(options);

axios.interceptors.request.use(config => {
  const authToken = localStorage.getItem('authToken');
  if (authToken) config!.headers!.Authorization = authToken;
  return config;
});

function handleAuthNeeded(response: AxiosResponse<any, any>) {
  if (response?.data?.authNeeded || response?.data?.adminAuthNeeded) {
    const redirect = new URLSearchParams({
      redirect: location.pathname + location.search,
    });
    user.value = null;
    localStorage.clear();
    location.href = location.origin + '/login?' + redirect.toString();
  }
}

axios.interceptors.response.use(
  function (response) {
    handleAuthNeeded(response);
    if (response.data.insufficientPermissions) {
      showToast(
        ToastType.ERROR,
        'Failed to load due to insufficient permissions.',
        3
      );
      response.data = [];
    }
    return response;
  },
  async function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    handleAuthNeeded(error.response);
    if (error.config?.url !== '/use')
      track('API Error', {
        name: error.name,
        message: error.message,
        code: error.code,
        baseURL: error.config?.baseURL,
        URL: error.config?.url,
        method: error.config?.method,
        full_error_in_json: error.toJSON(),
      });

    return Promise.reject(error);
  }
);
export const api = axios;
