<template>
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 16 14"
    xml:space="preserve"
  >
    <path
      fill="#666666"
      d="M5,10h6.4c1.1-0.1,2-1,2-2.2V3.5c0.1-1.1-0.8-2.1-2-2.2H4c-1.1,0.1-2,1-2,2.2V12l1.1-1.2C3.6,10.3,4.3,10,5,10z
	 M5.2,4h3.3C8.8,4,9,4.2,9,4.5C9,4.8,8.8,5,8.5,5H5.2C4.9,5,4.7,4.8,4.7,4.5C4.7,4.2,4.9,4,5.2,4z M5.2,6.6h5c0.3,0,0.5,0.2,0.5,0.5
	c0,0.3-0.2,0.5-0.5,0.5h-5c-0.3,0-0.5-0.2-0.5-0.5C4.7,6.9,4.9,6.6,5.2,6.6z"
    />
    <path
      fill="currentColor"
      d="M3.9,11.5C4.2,11.2,4.6,11,5,11h6.3c0.8,0,1.6-0.4,2.2-1c0.6-0.6,0.9-1.4,0.8-2.2V3.5c0.1-1.7-1.3-3.1-3-3.2H4
	C2.3,0.4,1,1.8,1,3.5v9.2c0,0.2,0.1,0.4,0.2,0.6c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0,0.4-0.1,0.5-0.2L3.9,11.5z M2,3.5
	C2,2.3,2.9,1.4,4,1.3h7.4c1.1,0.1,2,1,2,2.2v4.3c0.1,1.1-0.8,2.1-2,2.2H5c-0.7,0-1.4,0.3-1.9,0.8L2,12V3.5z"
    />
    <path
      fill="currentColor"
      d="M5.2,5h3.3C8.8,5,9,4.8,9,4.5C9,4.2,8.8,4,8.5,4H5.2C4.9,4,4.7,4.2,4.7,4.5C4.7,4.8,4.9,5,5.2,5z"
    />
    <path
      fill="currentColor"
      d="M5.2,7.6h5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5h-5c-0.3,0-0.5,0.2-0.5,0.5C4.7,7.4,4.9,7.6,5.2,7.6z"
    />
  </svg>
</template>
