interface Position {
  top: number;
  left: number;
  bottom: number;
  right: number;
  width?: number;
  height?: number;
}
export function isEllipsisActive(e: HTMLElement): boolean {
  return e.offsetWidth < e.scrollWidth;
}
export function setCoords(e: HTMLElement, p: Position): void {
  e.style.position = 'absolute';
  e.style.left = p.left + 'px';
  e.style.top = p.top + 'px';
}

export function getCoords(e: HTMLElement): Position {
  const box = e.getBoundingClientRect();

  const body = document.body;
  const docEl = document.documentElement;

  const scrollTop = window.scrollY || docEl.scrollTop || body.scrollTop;
  const scrollLeft = window.scrollX || docEl.scrollLeft || body.scrollLeft;

  const clientHeight = docEl.clientHeight || body.clientHeight || 0;
  const clientWidth = docEl.clientWidth || body.clientWidth || 0;

  const clientTop = docEl.clientTop || body.clientTop || 0;
  const clientLeft = docEl.clientLeft || body.clientLeft || 0;

  const top = box.top + scrollTop - clientTop;
  const left = box.left + scrollLeft - clientLeft;
  const bottom = clientHeight - (top + box.height);
  const right = clientWidth - (left + box.width);

  return {
    top: Math.round(top),
    left: Math.round(left),
    bottom: Math.round(bottom),
    right: Math.round(right),
    width: box.width,
    height: box.height,
  };
}

export function getLength(element: HTMLElement, lengthProperty: string) {
  return Number(
    window
      .getComputedStyle(element)
      .getPropertyValue(lengthProperty)
      .slice(0, -2)
  );
}

export function getCssVar(
  varName: string,
  element: HTMLElement | null = null
): string {
  return getComputedStyle(element ?? document.body).getPropertyValue(varName);
}

export function getAdjustedPosition(
  element: HTMLElement,
  target?: Position
): Position {
  const rect = element.getBoundingClientRect();
  if (target === undefined) {
    target = { top: rect.top, left: rect.left };
  }
  let top = target.top + (target.height ?? 0);
  let left = target.left + (target.width ?? 0) + 5;
  if (
    target.left - rect.width - 5 < 0 &&
    target.left + rect.width > window.innerWidth
  ) {
    //center the modal if wide
    left = (window.innerWidth - rect.width) / 2;
  } else {
    // Corrections if out of window
    if (target.left + rect.width > window.innerWidth)
      left = target.left - rect.width - (target.width ?? 0) - 15;
  }

  if (target.top + rect.height > window.innerHeight)
    top = Math.max(0, target.top - rect.height - (target.height ?? 0));
  return { top, left };
}

export function isWide(element: HTMLElement, thresholdRatio = 0.5): boolean {
  const rect = element.getBoundingClientRect();
  return rect.width > window.innerWidth * thresholdRatio;
}

export function isTall(element: HTMLElement, thresholdRatio = 0.5): boolean {
  const rect = element.getBoundingClientRect();
  return rect.height > window.innerHeight * thresholdRatio;
}

export const defaultFontFamily =
  'Inter, ui-sans-serif, system-ui, -apple-system, system-ui, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji';
