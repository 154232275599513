import router from '@/routes.js';

export function setUrlParams(params: Record<string, any>) {
  const searchParams = getUrlParams();
  for (const param in params) {
    searchParams.set(param, JSON.stringify(params[param]));
  }
  updateUrlWithParams(searchParams);
}

export function setUrlParam(key, value) {
  const searchParams = getUrlParams();
  searchParams.set(key, JSON.stringify(value));
  updateUrlWithParams(searchParams);
}
export function getUrlParams() {
  return new URLSearchParams(window.location.search);
}
export function getUrlParam(key: string) {
  const searchParams = getUrlParams();
  let value = searchParams.get(key);
  try {
    value = JSON.parse(value as string);
  } catch (e) {
    //not json, which is fine
  }
  return value;
}
function updateUrlWithParams(searchParams: URLSearchParams) {
  router.replace({ query: Object.fromEntries(searchParams) });
}
