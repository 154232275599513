export enum FindingArea {
  QUERY,
  STORAGE,
  WAREHOUSE,
  SNOWPIPE,
}
export enum FindingGroup {
  ORPHAN,
  BACKUP,
  CLUSTERING,
  STALE,
  UTIL,
  MULTI_CLUSTER,
  POLICY,
  TIMEOUT,
  FAILED,
  ZERO_IMPACT,
  IDENTITY_TRANSFORMATION,
  AGGREGATE,
  // EXPLODING_JOIN,
  LARGE_WRITE_AMPLIFICATION,
  MISSING_JOIN_CONDITION,
  UNION_WITHOUT_ALL,
  FREQUENT_INFO_SCHEMA_READS,
  FREQUENT_INSERTS,
  NON_INCR_CTAS,
  INCR_AGG_PIPELINE,
  FREQUENCY,
  WH_MATCH,
  PRUNING,
  VIEWS,
  SNOWPIPE_FREQUENT_SMALL_FILES,
  HIGH_COST_COLUMN_METADATA_RETRIEVAL,
  INEFFICIENT_FILE_COPY,
  INEFFICIENT_DATA_UNLOAD,
  // SUBOPTIMAL_WAREHOUSE_FOR_QUERY,
  DATA_RETENTION_INSIGHTS,
}
export interface FindingInfo {
  area: FindingArea;
  group: FindingGroup;
  name: string;
  extended?: boolean;
  owner?: string;
  risk?: number;
  effort?: number;
}
// TODO: double check if risk,effort,owner are accurate & up to date
export const findingCategories: Record<string, FindingInfo> = {
  OPTIMIZE_WAREHOUSE_SIZE: {
    area: FindingArea.WAREHOUSE,
    group: FindingGroup.UTIL,
    name: 'OPTIMIZE_WAREHOUSE_SIZE',
    extended: true,
    risk: 2,
    effort: 2,
    owner: 'VERTICAL',
  },
  ADVANCED_OPTIMIZE_WAREHOUSE_SIZE: {
    area: FindingArea.WAREHOUSE,
    group: FindingGroup.UTIL,
    name: 'ADVANCED_OPTIMIZE_WAREHOUSE_SIZE',
    extended: true,
    risk: 4,
    effort: 4,
    owner: 'VERTICAL',
  },
  SUBOPTIMAL_WH_SIZE: {
    area: FindingArea.QUERY,
    group: FindingGroup.WH_MATCH,
    name: 'SUBOPTIMAL_WH_SIZE',
    risk: -1,
    effort: -1,
    owner: 'PLATFORM',
  },
  // DETECT_EXPLODING_JOIN: {
  //   area: FindingArea.QUERY,
  //   group: FindingGroup.EXPLODING_JOIN,
  //   name: 'DETECT_EXPLODING_JOIN',
  //   extended: true,
  //   risk: 2,
  //   effort: 2,
  //   owner: 'VERTICAL',
  // },
  SNOWPIPE_FREQUENT_SMALL_FILES: {
    area: FindingArea.SNOWPIPE,
    group: FindingGroup.SNOWPIPE_FREQUENT_SMALL_FILES,
    name: 'SNOWPIPE_FREQUENT_SMALL_FILES',
    extended: true,
    risk: 2,
    effort: 3,
    owner: 'VERTICAL',
  },
  HIGH_COST_COLUMN_METADATA_RETRIEVAL: {
    area: FindingArea.QUERY,
    group: FindingGroup.HIGH_COST_COLUMN_METADATA_RETRIEVAL,
    name: 'HIGH_COST_COLUMN_METADATA_RETRIEVAL',
    extended: true,
    risk: 1,
    effort: 2,
    owner: 'VERTICAL',
  },
  FAILED_QUERY: {
    area: FindingArea.QUERY,
    group: FindingGroup.FAILED,
    name: 'FAILED_QUERY',
    extended: true,
    risk: 1,
    effort: 2,
    owner: 'VERTICAL',
  },
  NON_TRANSIENT_TABLE: {
    area: FindingArea.STORAGE,
    group: FindingGroup.BACKUP,
    name: 'NON_TRANSIENT_TABLE',
    risk: -1,
    effort: -1,
    owner: 'VERTICAL',
  },
  OPTIMAL_CLUSTERING_KEY: {
    area: FindingArea.STORAGE,
    group: FindingGroup.CLUSTERING,
    name: 'OPTIMAL_CLUSTERING_KEY',
    extended: true,
    risk: 2,
    effort: 1,
    owner: 'PLATFORM',
  },
  FREQUENCY_MISMATCH: {
    area: FindingArea.QUERY,
    group: FindingGroup.FREQUENCY,
    name: 'FREQUENCY_MISMATCH',
    risk: -1,
    effort: -1,
    owner: 'VERTICAL',
  },
  LARGE_WRITE_AMPLIFICATION: {
    area: FindingArea.QUERY,
    group: FindingGroup.LARGE_WRITE_AMPLIFICATION,
    name: 'LARGE_WRITE_AMPLIFICATION',
    risk: -1,
    effort: -1,
    owner: 'VERTICAL',
  },
  WAREHOUSE_MULTI_CLUSTER_SIZE_OPTIMIZATION: {
    area: FindingArea.WAREHOUSE,
    group: FindingGroup.MULTI_CLUSTER,
    name: 'WAREHOUSE_MULTI_CLUSTER_SIZE_OPTIMIZATION',
    risk: -1,
    effort: -1,
    owner: 'PLATFORM',
  },
  ZERO_IMPACT_QUERY: {
    area: FindingArea.QUERY,
    group: FindingGroup.ZERO_IMPACT,
    name: 'ZERO_IMPACT_QUERY',
    extended: true,
    risk: 1,
    effort: 2,
    owner: 'VERTICAL',
  },
  IDENTITY_TRANSFORMATION: {
    area: FindingArea.QUERY,
    group: FindingGroup.IDENTITY_TRANSFORMATION,
    name: 'IDENTITY_TRANSFORMATION',
    risk: -1,
    effort: -1,
    owner: 'VERTICAL',
  },
  MISSING_JOIN_CONDITION: {
    area: FindingArea.QUERY,
    group: FindingGroup.MISSING_JOIN_CONDITION,
    name: 'MISSING_JOIN_CONDITION',
    risk: -1,
    effort: -1,
    owner: 'VERTICAL',
  },
  AGGREGATE_WITHOUT_REDUCING_ROW_COUNT: {
    area: FindingArea.QUERY,
    group: FindingGroup.AGGREGATE,
    name: 'AGGREGATE_WITHOUT_REDUCING_ROW_COUNT',
    risk: -1,
    effort: -1,
    owner: 'VERTICAL',
  },
  UNION_WITHOUT_ALL: {
    area: FindingArea.QUERY,
    group: FindingGroup.UNION_WITHOUT_ALL,
    name: 'UNION_WITHOUT_ALL',
    risk: -1,
    effort: -1,
    owner: 'VERTICAL',
  },
  INSUFFICIENT_PARTITION_PRUNING: {
    area: FindingArea.QUERY,
    group: FindingGroup.PRUNING,
    name: 'INSUFFICIENT_PARTITION_PRUNING',
    risk: -1,
    effort: -1,
    owner: 'VERTICAL',
  },
  INSUFFICIENT_COLUMN_PRUNING: {
    area: FindingArea.QUERY,
    group: FindingGroup.PRUNING,
    name: 'INSUFFICIENT_COLUMN_PRUNING',
    risk: -1,
    effort: -1,
    owner: 'VERTICAL',
  },
  STALE_TABLE_READ: {
    area: FindingArea.QUERY,
    group: FindingGroup.STALE,
    name: 'STALE_TABLE_READ',
    extended: true,
    risk: 2,
    effort: 2,
    owner: 'VERTICAL',
  },
  NON_INCREMENTAL_TABLE: {
    area: FindingArea.STORAGE,
    group: FindingGroup.BACKUP,
    name: 'NON_INCREMENTAL_TABLE',
    risk: -1,
    effort: -1,
    owner: 'VERTICAL',
  },
  AUTO_RECLUSTERING_TUNING: {
    area: FindingArea.STORAGE,
    group: FindingGroup.CLUSTERING,
    name: 'AUTO_RECLUSTERING_TUNING',
    extended: true,
    risk: 2,
    effort: 1,
    owner: 'PLATFORM',
  },
  DATA_RETENTION_INSIGHTS: {
    area: FindingArea.STORAGE,
    group: FindingGroup.DATA_RETENTION_INSIGHTS,
    name: 'DATA_RETENTION_INSIGHTS',
    extended: true,
    risk: 2,
    effort: 1,
    owner: 'VERTICAL',
  },
  MERGE_SIMILAR_TABLE_SCANS: {
    area: FindingArea.STORAGE,
    group: FindingGroup.VIEWS,
    name: 'MERGE_SIMILAR_TABLE_SCANS',
    extended: true,
    risk: 2,
    effort: 3,
    owner: 'VERTICAL',
  },
  INCR_MTRL_HYBRID_READ: {
    area: FindingArea.STORAGE,
    group: FindingGroup.AGGREGATE,
    name: 'INCR_MTRL_HYBRID_READ',
    extended: true,
    risk: 3,
    effort: 4,
    owner: 'VERTICAL',
  },
  FREQUENT_INFO_SCHEMA_READS: {
    area: FindingArea.QUERY,
    group: FindingGroup.FREQUENT_INFO_SCHEMA_READS,
    name: 'FREQUENT_INFO_SCHEMA_READS',
    extended: true,
    risk: 3,
    effort: 3,
    owner: 'VERTICAL',
  },
  FREQUENT_INSERTS: {
    area: FindingArea.STORAGE,
    group: FindingGroup.FREQUENT_INSERTS,
    name: 'FREQUENT_INSERTS',
    extended: true,
    risk: 3,
    effort: 3,
    owner: 'VERTICAL',
  },
  INEFFICIENT_FILE_COPY: {
    area: FindingArea.QUERY,
    group: FindingGroup.INEFFICIENT_FILE_COPY,
    name: 'INEFFICIENT_FILE_COPY',
    extended: true,
    risk: 3,
    effort: 3,
    owner: 'VERTICAL',
  },
  INCR_AGG_PIPELINE: {
    area: FindingArea.STORAGE,
    group: FindingGroup.INCR_AGG_PIPELINE,
    name: 'INCR_AGG_PIPELINE',
    extended: true,
    risk: 2,
    effort: 2,
    owner: 'VERTICAL',
  },
  MATERIALIZE_VIEW: {
    area: FindingArea.STORAGE,
    group: FindingGroup.VIEWS,
    name: 'MATERIALIZE_VIEW',
    extended: true,
    risk: 2,
    effort: 4,
    owner: 'VERTICAL',
  },
  // SUBOPTIMAL_WAREHOUSE_FOR_QUERY: {
  //   area: FindingArea.QUERY,
  //   group: FindingGroup.SUBOPTIMAL_WAREHOUSE_FOR_QUERY,
  //   name: 'SUBOPTIMAL_WAREHOUSE_FOR_QUERY',
  //   extended: true,
  //   risk: 3,
  //   effort: 3,
  //   owner: 'PLATFORM',
  // },
  NON_INCR_CTAS: {
    area: FindingArea.QUERY,
    group: FindingGroup.NON_INCR_CTAS,
    name: 'NON_INCR_CTAS',
    extended: true,
    risk: 3,
    effort: 4,
    owner: 'VERTICAL',
  },
  INEFFICIENT_DATA_UNLOAD: {
    area: FindingArea.QUERY,
    group: FindingGroup.INEFFICIENT_DATA_UNLOAD,
    name: 'INEFFICIENT_DATA_UNLOAD',
    extended: true,
    risk: 3,
    effort: 3,
    owner: 'VERTICAL',
  },

  WAREHOUSE_SCALING_POLICY: {
    area: FindingArea.WAREHOUSE,
    group: FindingGroup.POLICY,
    name: 'WAREHOUSE_SCALING_POLICY',
    risk: -1,
    effort: -1,
    owner: 'PLATFORM',
  },
  WAREHOUSE_AUTOSUSPEND_TUNING: {
    area: FindingArea.WAREHOUSE,
    group: FindingGroup.UTIL,
    name: 'WAREHOUSE_AUTOSUSPEND_TUNING',
    extended: true,
    risk: 1,
    effort: 1,
    owner: 'PLATFORM',
  },
  WAREHOUSE_STMT_TIMEOUT: {
    area: FindingArea.WAREHOUSE,
    group: FindingGroup.TIMEOUT,
    name: 'WAREHOUSE_STMT_TIMEOUT',
    risk: 2,
    effort: 1,
    owner: 'PLATFORM',
  },
  ORPHAN_TABLE: {
    area: FindingArea.STORAGE,
    group: FindingGroup.ORPHAN,
    name: 'ORPHAN_TABLE',
    extended: true,
    risk: 2,
    effort: 1,
    owner: 'VERTICAL',
  },
  // WAREHOUSE_SPACE_SHIFTING: {
  //   area: FindingArea.WAREHOUSE,
  //   group: FindingGroup.UTIL,
  //   name: 'WAREHOUSE_SPACE_SHIFTING',
  //   risk: 4,
  //   effort: 4,
  //   owner: 'VERTICAL',
  // },
  WAREHOUSE_TIME_SHIFTING: {
    area: FindingArea.WAREHOUSE,
    group: FindingGroup.UTIL,
    name: 'WAREHOUSE_TIME_SHIFTING',
    risk: -1,
    effort: -1,
    owner: 'VERTICAL',
  },
};

/*
Our backend sf table `derived_optimization_findings_by_day` contains many
findings of categories that were manually inserted.

On our findings page, we only want to show users the findings categories that we're 
confident in. The whitelist below is this list.
*/

// TODO: double check
export const whitelistFindingCategories: Record<string, FindingInfo> = {
  OPTIMIZE_WAREHOUSE_SIZE: {
    area: FindingArea.WAREHOUSE,
    group: FindingGroup.UTIL,
    name: 'OPTIMIZE_WAREHOUSE_SIZE',
    extended: true,
    risk: 2,
    effort: 2,
    owner: 'PLATFORM',
  },
  ADVANCED_OPTIMIZE_WAREHOUSE_SIZE: {
    area: FindingArea.WAREHOUSE,
    group: FindingGroup.UTIL,
    name: 'ADVANCED_OPTIMIZE_WAREHOUSE_SIZE',
    extended: true,
    risk: 4,
    effort: 4,
    owner: 'PLATFORM',
  },
  // WAREHOUSE_SPACE_SHIFTING: {
  //   area: FindingArea.WAREHOUSE,
  //   group: FindingGroup.UTIL,
  //   name: 'WAREHOUSE_SPACE_SHIFTING',
  //   risk: 4,
  //   effort: 4,
  //   owner: 'VERTICAL',
  // },
  FAILED_QUERY: {
    area: FindingArea.QUERY,
    group: FindingGroup.FAILED,
    name: 'FAILED_QUERY',
    extended: true,
    risk: 1,
    effort: 2,
    owner: 'VERTICAL',
  },
  STALE_TABLE_READ: {
    area: FindingArea.QUERY,
    group: FindingGroup.STALE,
    name: 'STALE_TABLE_READ',
    extended: true,
    risk: 2,
    effort: 2,
    owner: 'VERTICAL',
  },
  OPTIMAL_CLUSTERING_KEY: {
    area: FindingArea.STORAGE,
    group: FindingGroup.CLUSTERING,
    name: 'OPTIMAL_CLUSTERING_KEY',
    extended: true,
    risk: 2,
    effort: 1,
    owner: 'PLATFORM',
  },
  ZERO_IMPACT_QUERY: {
    area: FindingArea.QUERY,
    group: FindingGroup.ZERO_IMPACT,
    name: 'ZERO_IMPACT_QUERY',
    extended: true,
    risk: 1,
    effort: 2,
    owner: 'VERTICAL',
  },
  INEFFICIENT_FILE_COPY: {
    area: FindingArea.QUERY,
    group: FindingGroup.INEFFICIENT_FILE_COPY,
    name: 'INEFFICIENT_FILE_COPY',
    extended: true,
    risk: 3,
    effort: 3,
    owner: 'VERTICAL',
  },
  INEFFICIENT_DATA_UNLOAD: {
    area: FindingArea.QUERY,
    group: FindingGroup.INEFFICIENT_DATA_UNLOAD,
    name: 'INEFFICIENT_DATA_UNLOAD',
    extended: true,
    risk: 3,
    effort: 3,
    owner: 'VERTICAL',
  },
  // SUBOPTIMAL_WAREHOUSE_FOR_QUERY: {
  //   area: FindingArea.QUERY,
  //   group: FindingGroup.SUBOPTIMAL_WAREHOUSE_FOR_QUERY,
  //   name: 'SUBOPTIMAL_WAREHOUSE_FOR_QUERY',
  //   extended: true,
  //   risk: 3,
  //   effort: 3,
  //   owner: 'PLATFORM',
  // },
  // DETECT_EXPLODING_JOIN: {
  //   area: FindingArea.QUERY,
  //   group: FindingGroup.EXPLODING_JOIN,
  //   name: 'DETECT_EXPLODING_JOIN',
  //   extended: true,
  //   risk: 2,
  //   effort: 2,
  //   owner: 'VERTICAL',
  // },
  FREQUENT_INFO_SCHEMA_READS: {
    area: FindingArea.QUERY,
    group: FindingGroup.FREQUENT_INFO_SCHEMA_READS,
    name: 'FREQUENT_INFO_SCHEMA_READS',
    extended: true,
    risk: 3,
    effort: 3,
    owner: 'VERTICAL',
  },
  AUTO_RECLUSTERING_TUNING: {
    area: FindingArea.STORAGE,
    group: FindingGroup.CLUSTERING,
    name: 'AUTO_RECLUSTERING_TUNING',
    extended: true,
    risk: 2,
    effort: 1,
    owner: 'PLATFORM',
  },
  DATA_RETENTION_INSIGHTS: {
    area: FindingArea.STORAGE,
    group: FindingGroup.DATA_RETENTION_INSIGHTS,
    name: 'DATA_RETENTION_INSIGHTS',
    extended: true,
    risk: 2,
    effort: 1,
    owner: 'VERTICAL',
  },
  WAREHOUSE_AUTOSUSPEND_TUNING: {
    area: FindingArea.WAREHOUSE,
    group: FindingGroup.UTIL,
    name: 'WAREHOUSE_AUTOSUSPEND_TUNING',
    extended: true,
    risk: 1,
    effort: 1,
    owner: 'PLATFORM',
  },
  ORPHAN_TABLE: {
    area: FindingArea.STORAGE,
    group: FindingGroup.ORPHAN,
    name: 'ORPHAN_TABLE',
    extended: true,
    risk: 2,
    effort: 1,
    owner: 'VERTICAL',
  },

  FREQUENT_INSERTS: {
    area: FindingArea.STORAGE,
    group: FindingGroup.FREQUENT_INSERTS,
    name: 'FREQUENT_INSERTS',
    extended: true,
    risk: 3,
    effort: 3,
    owner: 'VERTICAL',
  },
  INCR_AGG_PIPELINE: {
    area: FindingArea.STORAGE,
    group: FindingGroup.INCR_AGG_PIPELINE,
    name: 'INCR_AGG_PIPELINE',
    extended: true,
    risk: 2,
    effort: 2,
    owner: 'VERTICAL',
  },
  MATERIALIZE_VIEW: {
    area: FindingArea.STORAGE,
    group: FindingGroup.VIEWS,
    name: 'MATERIALIZE_VIEW',
    extended: true,
    risk: 2,
    effort: 4,
    owner: 'VERTICAL',
  },
  INCR_MTRL_HYBRID_READ: {
    area: FindingArea.STORAGE,
    group: FindingGroup.AGGREGATE,
    name: 'INCR_MTRL_HYBRID_READ',
    extended: true,
    risk: 3,
    effort: 4,
    owner: 'VERTICAL',
  },
  SNOWPIPE_FREQUENT_SMALL_FILES: {
    area: FindingArea.SNOWPIPE,
    group: FindingGroup.SNOWPIPE_FREQUENT_SMALL_FILES,
    name: 'SNOWPIPE_FREQUENT_SMALL_FILES',
    extended: true,
    risk: 2,
    effort: 2,
    owner: 'VERTICAL',
  },
};
export function categoriesForGroup(group: FindingGroup) {
  return Object.values(findingCategories).filter(
    (c: FindingInfo) => c.group === group
  );
}

export function categoriesForArea(area: FindingArea) {
  return Object.values(findingCategories).filter(
    (c: FindingInfo) => c.area === area
  );
}
