<template>
  <div
    ref="element"
    @mouseover="onMouseOver"
    @mouseleave="onMouseOut"
    @click="onClick"
  >
    <!-- Pass the trigger element in slot below -->
    <slot />
    <teleport to="body">
      <div
        class="transition-opacity duration-300 z-[1000]"
        @click.stop="() => null"
        v-if="show"
      >
        <div
          role="tooltip"
          ref="tooltip"
          :style="{ left, top }"
          :class="contentClass"
          class="tooltip inline-block absolute text-sm text-black bg-white rounded-lg border border-gray-200 my-shadow dark:text-gray-200 dark:border-gray-600 dark:bg-gray-800"
        >
          <div class="w-fit">
            <!-- Pass a content slot to display in popover -->
            <slot name="content" :close="close">
              <!-- alternatively, fallback to displaying content prop below -->
              <p>{{ content }}</p>
            </slot>
          </div>
        </div>
      </div>
    </teleport>
  </div>
</template>

<script setup lang="ts">
import { getAdjustedPosition } from '@/helpers/domHelper.js';

document.addEventListener('click', hideIfClickedOut);
import { nextTick, ref } from 'vue';
import { logError } from '@/services/errorTracker.js';
const show = ref(false);
const element = ref();
const tooltip = ref();
const left = ref('0px');
const top = ref('0px');
interface Props {
  content?: string;
  contentClass?: string;
  requireClick?: boolean;
  showByDefault?: boolean;
  beforePopoverShow?: () => Promise<void>;
}
const props = defineProps<Props>();

defineExpose({ close, open, reposition });

if(props.showByDefault) {
  showPopover();
}

async function showPopover() {
  try {
    if (typeof props.beforePopoverShow === 'function') {
      await props.beforePopoverShow();
    }
  } catch (e) {
    logError(e);
  }
  show.value = true;
  reposition();
}

function reposition() {
  const originalPosition = element.value.getBoundingClientRect();
  left.value = `${originalPosition.left}px`;
  top.value = `${originalPosition.bottom}px`;
  nextTick(() => {
    const position = getAdjustedPosition(tooltip.value, {
      left: originalPosition.left,
      top: originalPosition.bottom,
    });
    left.value = `${position.left}px`;
    top.value = `${position.top}px`;
  });
}
function open() {
  showPopover();
}
function close() {
  show.value = false;
}
function onClick() {
  showPopover();
}
function onMouseOver() {
  if (!props.requireClick) showPopover();
}
function onMouseOut() {
  if (!props.requireClick) show.value = false;
}
function hideIfClickedOut(event: MouseEvent) {
  if (!element.value?.contains(event.target)) show.value = false;
}
</script>

<style scoped>
.tooltip {
  transition-property: left, top;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}
</style>
