<template>
  <popover :require-click="true" v-if="value" ref="pop">
    <div
      class="flex w-full items-center font-semibold rounded-lg"
      v-if="standAlone"
      @click.stop="loadFindings"
    >
      <div
        class="border rounded-lg p-1 w-full flex items-center cursor-pointer"
        :class="`border-${color}-300 dark:border-${color}-800`"
      >
        <icon-bell
          class="h-10 w-10 mr-2 p-2 rounded-lg"
          :class="`bg-${color}-100 dark:bg-${color}-900 text-${color}-500`"
        />Finding:
        {{ intl.t(`FINDING.${value.CATEGORY}.NAME`) }}
      </div>
    </div>
    <button
      v-else
      class="text-xs w-28 relative flex"
      @click.stop="loadFindings"
      :style="{
        background: color,
        color: getCssVar('--color-background'),
      }"
    >
      {{
        admin ? 'Admin View' : value?.type === 2 ? 'Anomaly' : 'Optimization'
      }}
      <div
        class="absolute inset-0 flex items-center justify-center"
        v-if="loading"
      >
        <loading class="h4 w-4" />
      </div>
      <div class="h-4 w-4 ml-1" v-if="row.BEHIND_PAYWALL">
        <icon-lock class="h-4 w-4" />
      </div>
    </button>
    <template #content>
      <optimization-tooltip-renderer
        :data="findingData"
        :findings="allFindings"
        @resize="pop.reposition"
      />
    </template>
  </popover>
</template>

<script setup lang="ts">
import { computed, inject, ref, watch } from 'vue';
import { getCssVar } from '@/helpers/domHelper.js';
import Popover from '@/components/base/Popover.vue';
import OptimizationTooltipRenderer from '@/components/renderers/tooltipRenderers/OptimizationTooltipRenderer.vue';
import { api } from '@/services/apiClient.js';
import { currentOrg } from '@/stores/userState.js';
import Loading from '@/components/base/LoadingIcon.vue';
import { logError } from '@/services/errorTracker.js';
import { paywallData } from '@/stores/uiState.js';
import IconLock from '@/components/icons/IconLock.vue';
import IconBell from '@/components/icons/IconBell.vue';

interface Props {
  value: any;
  row: any;
  admin?: boolean;
  standAlone?: boolean;
}
const props = defineProps<Props>();

const color = computed(() => {
  const saving = props.value?.saving ?? props.value?.ESTIMATE_ANNUAL_SAVING_USD ?? props.value?.INITIAL_SAVINGS_EST;
  if (saving) return saving > 10000 ? 'red' : 'orange';

  return props.value?.impact > 50 ? 'red' : 'orange';
});
const pop = ref();
const findingData = ref();
const allFindings = ref();
const loading = ref(false);
const intl: i18n = inject('i18n') as i18n;
if (props.standAlone) {
  findingData.value = props.value;
  loading.value = false;
}
async function loadFindings() {
  loading.value = true;
  try {
    if (!props.value.findings) {
      findingData.value = props.value;
      loading.value = false;
      document.body.click();
      pop.value.open();
      return;
    }
    const admin = props.admin ? '/admin' : '';
    const findings = (
      await api.get(`${admin}/findings`, {
        params: {
          ids: props.value.findings.join(','),
          org: currentOrg.value?.uuid,
        },
      })
    ).data.rows;
    loading.value = false;
    if (findings.length === 0) {
      paywallData.value = props.value.findings;
      return;
    }
    findingData.value = findings[0];
    allFindings.value = findings;
    document.body.click();
    pop.value.open();
  } catch (e) {
    loading.value = false;
    logError(e);
  }
}
</script>

<style scoped>
/*
border-orange-300
dark:border-orange-800

border-red-300 dark:border-red-800
bg-orange-50 dark:bg-orange-900

bg-red-50
dark:bg-red-900

text-red-500
text-orange-500
*/
</style>
