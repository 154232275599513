<template>
  <IconComment
    v-if="!fullVersion"
    class="w-5 h-5 cursor-pointer"
    :class="{ active: curComment && !(Object.keys(curComment).length === 1 && curComment.hasOwnProperty('context')) }"
    style="margin-bottom: -6px"
    @click="onClick"
  />
  <button
    v-else
    class="py-2.5 text-sm align-center border-lg bg-sky-500 text-white"
    :class="buttonClass"
    @click="onClick"
  >
    <IconComment
      class="stroke-white stroke-[0.8px] mr-2"
      style="width: 21px;height: 21px;flex-shrink: 0;"
    />
    <span>
      Comments ({{ 
        Object.keys(curComment).length === 1 && curComment.hasOwnProperty('context')
          ? 0
          : (curComment ? 1 : 0) + curComment?.replies?.length
      }})
    </span>
  </button>
  <comment-box
    :onclickout="close"
    v-if="showCommentBox"
    :comment="curComment"
    :comment-type="CommentType.COMMENT"
    :entity-id="props.entity.id"
    :entity-type="props.entity.type"
    :disable-extend-animation="true"
    :place-top-right="true"
    @close="close"
    class="w-96"
  ></comment-box>
</template>

<script setup lang="ts">
import { inject, ref } from 'vue';
import { currentOrg } from '@/stores/userState.js';
import IconComment from '@/components/icons/IconComment.vue';
import { api } from '@/services/apiClient';
import { showToast } from '@/helpers/toastHelper';
import CommentBox from '@/components/base/CommentBox.vue';
import { CommentData, ToastType, CommentEntityType, CommentType } from '@/types';
import { logError } from '@/services/errorTracker';
import { useRouter } from 'vue-router';
import 'clickout-event';

const intl = inject('i18n') as any;
const router = useRouter();

export interface CommentedEntityProps {
  id: string;
  type: number;
  value: CommentData;
  header?: string;
}

interface Props {
  entity: CommentedEntityProps; 
  // if full version is true, we display the full comment button
  // otherwise, only display a comment icon
  fullVersion?: boolean;
  buttonClass: string | '';
}
const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const curComment = ref(
  props.entity?.value 
  ? {...props.entity?.value, context: getCommentHeader()}
  : { context: getCommentHeader() }
);

let showCommentBoxDefault = false;
try { 
  const urlParams = new URLSearchParams(window.location.search);
  const urlEntityId = urlParams.get('commentEntityId');
  const urlEntityType = urlParams.get('commentEntityType');
  showCommentBoxDefault = urlEntityId == props.entity?.id 
    && urlEntityType == props.entity?.type
} catch(e) {
  showCommentBoxDefault = false
}
const showCommentBox = ref(showCommentBoxDefault);

defineExpose({ close });

interface Emits {
  (eventName: 'close', saved: boolean): void;
}

function onClick() {
  showCommentBox.value = true;
}

function getCommentHeader() {
  return props.entity?.header;
}
async function close(updated: boolean) {
  showCommentBox.value = false;
  if(updated) {
    // refresh this comment to get updated content
    await reloadCommentBox();
  }
  emit('close', updated);
}

async function reloadCommentBox() {
  const newComment = await getCommentForEntity();
    if (newComment === undefined) {
      curComment.value = { context: getCommentHeader() };
    } else {
      if(newComment.length === 0) {
        // comment was archived
        curComment.value = { context: getCommentHeader() };
      } else {
        // comment was edited
        curComment.value = {...newComment[0], context: getCommentHeader()}
      } 
    }
}
async function getCommentForEntity() {
  try {
    const res = await api.get('/comment', {
      params: {
        entity_id: props.entity.id,
        entity_type: props.entity.type,
      },
    });
    return res.data;
  } catch (e) {
    logError(e);
    showToast(
      ToastType.ERROR,
      intl.t('ERROR.CHECK_COMMENT_ENTITIES_FAILED'),
      3
    );
  }
}
</script>

<style scoped>
svg {
  fill-opacity: 0.3;
  fill: none;
}
svg.active {
  fill-opacity: 1;
  fill: none;
}
</style>
