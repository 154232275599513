import { CostUnit } from '@/types.js';
import { Temporal } from 'temporal-polyfill';
import { costPerCredit } from '@/stores/userState.js';
import { costUnit } from '@/stores/chartState.js';
import { timeZone } from '@/stores/uiState.js';
import twitter from 'twitter-text';

// Returns a number indicating the byte count in a particular unit (e.g. KB, MB),
// and a string representing the unit.
function formatBytesHelper(bytes: number, decimals): [number, string] {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  if (!+bytes) return [0.0, sizes[0]];

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;

  if (bytes >= 1) {
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return [parseFloat((bytes / Math.pow(k, i)).toFixed(dm)), sizes[i]];
  }
  return [bytes.toFixed(dm), sizes[0]];
}

export function formatBytes(bytes: number, decimals = 2): string {
  const [size, unit] = formatBytesHelper(bytes, decimals);
  return `${size} ${unit}`;
}

// For now this is only used by extended lineage feature, which has been unlaunched.
export function formatBytesFormatted(bytes: number, decimals = 2): string {
  const [size, unit] = formatBytesHelper(bytes, decimals);
  return `${size} <span class="unit">${unit}</span>`;
}

export function isIsoDate(str: string) {
  if (!str) return false;
  if (str[str.length - 1] != 'Z') str = `${str}Z`;
  const d = Date.parse(str);
  if (isNaN(d)) return false;
  const dateString = new Date(d).toISOString();
  return str.indexOf(dateString.split('Z')[0]) == 0;
}

const basicCurrencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumSignificantDigits: 2,
  maximumSignificantDigits: 2,
});
const simpleCurrencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
});
const numberFormatter = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 2,
});
const smallNumberFormatter = new Intl.NumberFormat('en-US', {
  minimumSignificantDigits: 2,
  maximumSignificantDigits: 2,
});
const simpleNumberFormatter = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 0,
});

export function formatNumber(value: number) {
  return value > 1
    ? numberFormatter.format(value)
    : smallNumberFormatter.format(value);
}
export function formatNumberSimple(value: number) {
  return simpleNumberFormatter.format(value);
}
export function formatBasicCurrency(value: number) {
  return basicCurrencyFormatter.format(value);
}
export function formatCurrency(value: number) {
  return currencyFormatter.format(value);
}
export function formatCurrencySimple(value: number) {
  return simpleCurrencyFormatter.format(value);
}
export function formatCurrencyShort(value: number) {
  if (value >= 1000000000) {
    return '~' + formatCurrencySimple(value / 1000000000) + 'B';
  } else if (value >= 1000000) {
      return '~' + formatCurrencySimple(value / 1000000) + 'M';
  } else if (value >= 1000) {
      return '~' + formatCurrencySimple(value / 1000) + 'K';
  } else {
      return '~' + formatCurrencySimple(value);
  }
}
export function formatCost(value: number) {
  return costUnit.value === CostUnit.CREDIT
    ? formatNumber(value)
    : formatCurrency(value);
}

export function formatPercentage(value: number, precision = 2) {
  if (value === null) return '';
  return parseFloat((value * 100).toFixed(precision)) + '%';
}

export function formatDateFromString(date: string) {
  if (!date) return '';
  return formatPlainDate(Temporal.PlainDate.from(date));
}

export function formatTimeFromString(date: string) {
  if (!date) return '';
  return formatTime(Temporal.Instant.from(date));
}

export function formatPlainDateFromString(date: string) {
  if (!date) return '';

  return new Date(date).toLocaleString('en-US', {
    dateStyle: 'short',
    timeStyle: 'long',
    timeZone: timeZone.value,
  });
}
export function formatPlainDate(date: Temporal.PlainDate | Temporal.Instant) {
  if (!date) return '';

  return date.toLocaleString('en-US', {
    year: 'numeric',
    day: 'numeric',
    month: 'short',
    timeZone: timeZone.value,
  });
}

export function formatDateTime(date: Temporal.Instant) {
  if (!date) return '';
  return date.toLocaleString('en-US', {
    // year: 'numeric',
    day: 'numeric',
    month: 'short',
    hour: 'numeric',
    minute: 'numeric',
    timeZone: timeZone.value,
  });
}

export function formatDateTimeFromString(date: string) {
  if (!date) return '';
  return new Date(date).toLocaleString('en-US', {
    day: 'numeric',
    month: 'short',
    hour: '2-digit',
    minute: 'numeric',
    hour12: false,
    timeZone: timeZone.value,
    timeZoneName: 'short',
  });
}

/*
Clustering key is returned from backend in format like "LINEAR(key1, key2, key3)".
This function extracts the keys within LINEAR().
*/
export function formatClusteringKey(rawClusteringKey: string) {
  if (!rawClusteringKey) return '';
  const regex = /LINEAR\((.*)\)/;
  const match = rawClusteringKey.match(regex);
  if (match && match[1]) {
    return match[1];
  }
  return null;
}

export function formatTime(date: Temporal.Instant) {
  if (!date) return '';
  return date.toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    timeZone: timeZone.value,
  });
}

export function creditToDollar(credits: number) {
  return credits * costPerCredit.value;
}

/**
 * This function takes number of seconds as input and convert to day + hour + minute + second.
 * For example, 60 becomes "1 m", 61 -> "1m 1s", 3600 -> "1h", 90061 -> "1d 1h 1m 1s".
 *
 * @param totalSeconds {number | string }
 * @return duration string in the format of day, hour, minute, second, e.g. "1d 1h 1m 1s"
 */

export function humanReadableDuration(totalSeconds: number | string): string {
  const WHITESPACE = ' ';
  totalSeconds = Number(totalSeconds);

  if (totalSeconds === 0) {
    return '0s';
  }
  const day = Math.floor(totalSeconds / 86400);
  const hour = Math.floor((totalSeconds % 86400) / 3600);
  const minute = Math.floor((totalSeconds % 3600) / 60);
  const second =
    totalSeconds >= 1
      ? Math.round((totalSeconds % 3600) % 60)
      : Math.round(totalSeconds * 100) / 100;

  const dayDisplay =
    day > 0 ? day.toString() + 'd' + (hour > 0 ? WHITESPACE : '') : '';
  const hourDisplay =
    hour > 0 ? hour.toString() + 'h' + (minute > 0 ? WHITESPACE : '') : '';
  const minuteDisplay =
    minute > 0 ? minute.toString() + 'm' + (second > 0 ? WHITESPACE : '') : '';
  const secondDisplay = second > 0 ? second.toString() + 's' : '';

  return dayDisplay + hourDisplay + minuteDisplay + secondDisplay;
}

export function nan(num: any) {
  return num === null || isNaN(num);
}
export const warehouseSizeCreditMap: Record<string, number> = {
  'X-Small': 1,
  Small: 2,
  Medium: 4,
  Large: 8,
  'X-Large': 16,
  '2X-Large': 32,
  '3X-Large': 64,
  '4X-Large': 128,
  '5X-Large': 256,
  '6X-Large': 512,
};

export function getNameForWhSize(size: number) {
  const result = Object.entries(warehouseSizeCreditMap).find(
    entry => entry[1] === size
  );
  if (result) return result[0];
  else return '-';
}

export const addHintsForRemovedPII = (queryText: string): string => {
  const redacted = 'REDACTED';

  try {
    // Replace all occurrences of empty strings with '[REDACTED]' surrounded by single quotes
    return (
      queryText?.replace(/(^|\W)('')(\W|$)/g, `$1'[${redacted}]'$3`) ??
      queryText
    );
  } catch (error) {
    console.error('Error occurred while adding hints for removed PII:', error);
    return queryText; // Return the original query text in case of error
  }
};

/*
This function gets the date of most recent Sunday
*/
export const getMostRecentSunday = (d: string): string => {
  const date = Temporal.PlainDate.from(d);

  // Check if the current day is already a Sunday
  if (date.dayOfWeek === 7) {
    return date.toString(); // If it's Sunday, return the date as it is
  }
  // Find the most recent Sunday by subtracting days from the current date
  const isoWeekStart = date.subtract({ days: date.dayOfWeek });
  return isoWeekStart.toString();
};

export function formatComment(content: string): string {
  let result = twitter.autoLink(content, {
    hashtagClass: 'entity',
    usernameIncludeSymbol: true,
    usernameClass: 'username',
    usernameUrlBase: '#/',
    hashtagUrlBase: 'find/',
  });
  result = result.replaceAll(
    'class="username"',
    'class="username" onclick="return false;"'
  );
  return result;
}
