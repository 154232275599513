import { api } from '@/services/apiClient';
import { currentOrg } from '@/stores/userState.js';

export async function getCommentedEntities(
  entity_type: number,
  entity_ids: string[]
): Promise<string[]> {
  const resp = await api.post('/getCommentsByType', {
    entity_type: entity_type,
    entity_ids: JSON.stringify(entity_ids),
  });
  return resp.data;
}
