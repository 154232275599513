<template>
<div class="p-2 bg-white w-full">
  <div
    class="flex justify-between items-center font-medium text-sm overflow-hidden transition-fast"
    style="margin-bottom: 12px;"
  >
    <div class="text-black truncate">
      @{{ author }}
    </div>
    <div class="flex">
      <div class="text-gray-400 text-xs">
        {{ date }}
      </div>
    </div>
  </div>
  <div v-html="processed" class="mb-4 whitespace-normal"/>
  <hr>
</div>

</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { formatComment } from '@/helpers/formattingHelper.js';

const processed = computed(() =>
  formatComment(props.content) ?? ''
);

interface Props {
  author: string;
  content: string;
  date: string;
}

const props = defineProps<Props>();
</script>

<style scoped></style>
