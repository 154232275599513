<template>
  <div class="flex justify-between px-4">
    <select
      class="pt-1 pb-1 mr-1 font-semibold"
      v-model="department"
      v-if="isDemoOrg"
    >
      <option selected :value="null">Department: All</option>
      <option v-for="dept of departments" :value="dept.name">
        {{ dept.name }}
      </option>
    </select>
    <global-date-selector />
    <popover :require-click="true" ref="costPopover" v-if="false">
      <button class="soft w-40 text-sm">
        <span
          v-if="costUnit === CostUnit.CREDIT"
          class="w-full inline-flex justify-center items-center"
        >
          <snow-icon class="w-3 h-3" />
          <span class="text-sm pl-1">
            {{ $t('COMMON.CREDIT') }}&nbsp;(${{
              currentOrg?.costPerCredit / 100
            }})
          </span>
        </span>
        <span v-else class="w-full inline-flex justify-center items-center">
          <dollar-icon class="w-4 h-4" />
          <span class="text-sm">{{ $t('CURRENCY_WIDGET.US_DOLLAR') }}</span>
        </span>
      </button>
      <template #content="{ close }">
        <div class="text-muted font-semibold py-2 px-3">
          {{ $t('CURRENCY_WIDGET.COST_UNIT') }}
        </div>
        <ul class="grid gap-2 md:grid-cols-2 w-max py-2 px-1">
          <li>
            <input
              type="radio"
              id="dollar-unit"
              name="cost-unit"
              :value="CostUnit.CREDIT"
              v-model="costUnit"
              class="hidden peer"
              required
            />
            <label for="dollar-unit" class="toggle-button">
              <snow-icon class="w-3 h-3" />
              <span class="text-sm pl-2">
                {{ $t('COMMON.CREDIT') }}&nbsp;(${{
                  currentOrg?.costPerCredit / 100
                }})
              </span>
            </label>
          </li>
          <li>
            <input
              type="radio"
              id="credit-unit"
              name="cost-unit"
              v-model="costUnit"
              :value="CostUnit.DOLLAR"
              class="hidden peer"
            />
            <label for="credit-unit" class="toggle-button">
              <dollar-icon class="w-4 h-4" />
              <span class="text-sm pl-1">{{
                $t('CURRENCY_WIDGET.US_DOLLAR')
              }}</span>
            </label>
          </li>
        </ul>
      </template>
    </popover>
  </div>
</template>

<script setup lang="ts">
import SnowIcon from '@/components/icons/IconSnow.vue';
import DollarIcon from '@/components/icons/IconDollar.vue';
import { CostUnit, ToastType } from '@/types.js';
import {
  costUnit,
  dateShortcut,
  department,
  fromDate,
  toDate,
} from '@/stores/chartState.js';
import { currentOrg } from '@/stores/userState.js';
import Popover from '@/components/base/Popover.vue';
import { inject, ref, watch } from 'vue';

import { isDemoOrg } from '@/stores/uiState.js';
import GlobalDateSelector from '@/components/GlobalDateSelector.vue';
watch(costUnit, () => costPopover.value.close());
const departments = [
  { id: 1, name: 'Sales' },
  { id: 2, name: 'Memberships' },
  { id: 3, name: 'Retail' },
  { id: 4, name: 'Manufacturing' },
  { id: 5, name: 'Inventory' },
  { id: 6, name: 'Data and Technology' },
];
const costPopover = ref();
const i18n = inject('i18n') as any;
</script>

<style scoped>
.toggle-button {
  @apply w-36 inline-flex justify-center items-center border-2 p-2 text-gray-500 rounded-lg border-gray-200 cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-white peer-checked:border-black dark:peer-checked:border-white peer-checked:text-black peer-checked:bg-white dark:peer-checked:bg-gray-800 hover:text-gray-600 hover:bg-gray-50 dark:text-gray-400 bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-800 peer-checked:font-semibold;
}
</style>
