<template>
  <div
    class="flex flex-col bg-white pb-2 transition-fast rounded-lg overflow-hidden shadow-xl overflow-y-auto max-h-[60vh]"
    :class="[
      extended ? 'w-64 z-50' : 'w-48 z-40',
      props.placeTopRight ? 'fixed top-16 right-[25px]' : '',
    ]"
    @mouseover="!disableExtendAnimation ? (extended = true) : null"
    @mouseleave="!disableExtendAnimation ? (extended = false) : null"
  >
    <!-- header -->
    <div
      class="flex justify-between items-center p-2 font-semibold text-white text-sm bg-gray-500"
    >
      <div class="text-xs" v-if="comment?.context">
        {{ comment.context }}
      </div>
      <div class="text-xs" v-else>Comments:</div>
      <icon-close
        @click="cancel"
        class="h-4 cursor-pointer transition-fast"
        :class="extended ? 'opacity-100 w-4' : 'opacity-0 w-0'"
      />
    </div>
    <!-- Add comment (i.e. reply) button & text input for comment -->
    <div class="p-2">
      <button
        v-if="
          isComment() &&
          comment &&
          !(
            Object.keys(comment).length === 1 &&
            comment.hasOwnProperty('context')
          )
        "
        class="primary text-xs mt-1 mb-4 w-full"
        @click="showAddComment = replying = true"
      >
        Add Comment
      </button>
      <div v-if="isComment() && replying">
        <comment-text-input
          v-model="newComment"
          class="mb-2 bg-gray-50"
          :editing="true"
          v-if="showAddComment"
        />
        <div class="flex justify-between mt-4" v-if="showAddComment">
          <button
            :class="newComment.length ? 'primary' : 'btn-ghost'"
            @click="submit"
            class="small"
            :disabled="!newComment.length"
          >
            Submit
          </button>
          <button class="secondary small" @click="cancelAddReply">
            Cancel
          </button>
        </div>
      </div>
    </div>
    <!-- root comment / annotation -->
    <div class="p-2">
      <div
        class="flex justify-between items-center font-medium text-sm overflow-hidden transition-fast"
        :style="{
          height: extended ? '20px' : 0,
          opacity: extended ? 1 : 0,
          'margin-bottom': extended ? '12px' : 0,
        }"
      >
        <div class="text-black truncate">
          @{{ (comment?.createdByEmail || user?.email).split('@')[0] }}
        </div>
        <div
          class="text-gray-400 text-xs"
          v-if="
            comment &&
            !(
              Object.keys(comment).length === 1 &&
              comment.hasOwnProperty('context')
            )
          "
        >
          <span v-if="isAnnotation()">Last Modified:</span>
          {{
            formatDateFromString(
              (
                comment?.modifiedAt ??
                comment?.createdAt ??
                new Date().toISOString()
              ).split('T')[0]
            )
          }}
        </div>
        <div class="text-gray-400 text-xs" v-else>
          {{ formatDateFromString(new Date().toISOString().split('T')[0]) }}
        </div>
      </div>
      <div v-if="comment" class="whitespace-normal">
        <div v-html="processed" v-if="!editing" />
        <div
          class="overflow-hidden transition-fast"
          :style="{
            height:
              isAnnotation() &&
              extended &&
              !editing &&
              !(
                Object.keys(comment).length === 1 &&
                comment.hasOwnProperty('context')
              )
                ? '46px'
                : 0,
            opacity: extended ? 1 : 0,
            'margin-top': extended ? '12px' : 0,
          }"
        >
          <div
            class="flex justify-between mt-4"
            v-if="
              isAnnotation() &&
              !editing &&
              !(
                Object.keys(comment).length === 1 &&
                comment.hasOwnProperty('context')
              )
            "
          >
            <button class="primary small" @click="editing = true">Edit</button>
            <button class="btn-ghost small" @click="archive">Archive</button>
          </div>
        </div>
      </div>
      <hr v-if="isComment() && comment" />
      <!-- add comment mode -->
      <div
        v-if="
          !comment ||
          (Object.keys(comment).length === 1 &&
            comment.hasOwnProperty('context'))
        "
        class=""
      >
        <comment-text-input
          v-model="newComment"
          class="mb-2 bg-gray-50"
          :editing="true"
        />
        <div class="flex justify-between mt-4">
          <button
            :class="newComment.length ? 'primary' : 'btn-ghost'"
            @click="submit"
            class="small"
            :disabled="!newComment.length"
          >
            Submit
          </button>
          <button class="secondary small" @click="cancel">Cancel</button>
        </div>
      </div>
      <!-- edit comment mode -->
      <div v-if="isAnnotation() && editing">
        <comment-text-input
          v-model="curComment"
          class="mb-2 bg-gray-50"
          :editing="true"
        />
        <div class="flex justify-between mt-4">
          <button
            @click="edit"
            class="small"
            :class="curComment.length ? 'primary' : 'btn-ghost'"
            :disabled="!curComment.length"
          >
            Save
          </button>
          <button class="secondary small" @click="cancel">Cancel</button>
        </div>
      </div>
    </div>
    <comment-reply
      v-if="isComment()"
      v-for="reply in replies"
      :content="reply?.content"
      :author="reply?.createdByEmail.split('@')[0]"
      :date="
        formatDateFromString(
          (
            reply?.modifiedAt ??
            reply?.createdAt ??
            new Date().toISOString()
          ).split('T')[0]
        )
      "
    />
  </div>
</template>
<script setup lang="ts">
import CommentTextInput from '@/components/inputs/CommentTextInput.vue';
import CommentReply from '@/components/base/CommentReply.vue';
import { type CommentData, type EntityType, CommentType } from '@/types.js';
import IconClose from '@/components/icons/IconClose.vue';
import { CommentEntityType } from '@/types.js';
import { computed, ref } from 'vue';
import { api } from '@/services/apiClient.js';
import { showToast } from '@/helpers/toastHelper.js';
import { ToastType } from '@/types.js';
import { currentOrg, user } from '@/stores/userState.js';
import { logError } from '@/services/errorTracker.js';
import {
  formatComment,
  formatDateFromString,
} from '@/helpers/formattingHelper.js';
import { track } from '@/services/analytics.js';
interface Props {
  comment?: CommentData;
  commentType: CommentType;
  entityType?: EntityType | number;
  entityId?: string;
  info?: any;
  disableExtendAnimation?: boolean;
  placeTopRight?: boolean;
}

const props = defineProps<Props>();
interface Emits {
  (eventName: 'close', saved: boolean): void;
}

const emit = defineEmits<Emits>();
// reply ability should be only allowed for comment, disabled for annotation
const replies = ref(props.comment?.replies ?? '');
const curComment = ref(props.comment?.content ?? '');
const newComment = ref('');
const editing = ref(false);
const extended = ref(props.disableExtendAnimation || false);
const showAddComment = ref(isComment());
const replying = ref(false);
const processed = computed(() =>
  props.comment?.content ? formatComment(props.comment.content) : ''
);

if (!isAnnotation() && !isComment) {
  throw "CommentBox.vue requires props.commentType to be either 'ANNOTATION' or 'COMMENT'.";
}

async function submit() {
  if (!newComment.value.trim()?.length) {
    showToast(ToastType.WARNING, 'Cannot add comment because it is empty', 5);
    newComment.value = '';
    return;
  }
  try {
    const response = await api.post(`/comment`, {
      content: newComment.value,
      entity_type:
        typeof props.entityType === 'number'
          ? props.entityType
          : CommentEntityType[props.entityType?.toUpperCase()],
      entity_id: props.entityId,
      parent_uuid: props.comment?.uuid,
      info: props.info,
      org: currentOrg.value?.uuid,
    });
    showToast(ToastType.SUCCESS, 'Successfully added comment', 5);
    track('Comment Left', { 'Comment Text': newComment.value });
  } catch (e) {
    logError(e);
    showToast(ToastType.ERROR, 'Failed to add comment. Please try again.', 5);
  }
  newComment.value = '';
  emit('close', true);
}
async function archive() {
  try {
    const response = await api.patch(`/comment/${props.comment.uuid}`, {
      isArchive: true,
      org: currentOrg.value?.uuid,
    });

    showToast(ToastType.SUCCESS, 'Successfully archived comment', 5);
  } catch (e) {
    logError(e);
    showToast(
      ToastType.ERROR,
      'Failed to archive comment. Please try again.',
      5
    );
  }
  newComment.value = '';
  emit('close', true);
}

async function edit() {
  if (!curComment.value.trim()?.length) {
    showToast(ToastType.WARNING, 'Cannot edit comment because it is empty', 5);
    newComment.value = '';
    return;
  }
  try {
    const response = await api.patch(`/comment/${props.comment?.uuid}`, {
      content: curComment.value,
      isArchive: false,
      org: currentOrg.value?.uuid,
    });
    showToast(ToastType.SUCCESS, 'Successfully edited comment', 5);
  } catch (e) {
    logError(e);
    showToast(ToastType.ERROR, 'Failed to edit comment. Please try again.', 5);
  }
  newComment.value = curComment.value;
  emit('close', true);
}

function cancel() {
  if (editing.value) {
    editing.value = false;
    return;
  }
  newComment.value = '';
  emit('close', false);
}

function cancelAddReply() {
  showAddComment.value = false;
}
function isComment() {
  return props.commentType === CommentType.COMMENT;
}
function isAnnotation() {
  return props.commentType === CommentType.ANNOTATION;
}
</script>

<style scoped></style>
