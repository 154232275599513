<template>
  <div v-html="description" />
</template>
<script setup lang="ts">
import { computed, inject } from 'vue';
import { i18n } from 'i18next';
const intl: i18n = inject('i18n') as i18n;

interface Props {
  data: any;
}
const props = defineProps<Props>();

const description = computed(() =>
  intl.t(`HOME.FINDINGS.DESCRIPTIONS.${props.data.CATEGORY}`, {
    table_name: props.data?.INFO?.table_name || '',
    qs_level_columns_scanned_count:
      props.data?.INFO?.qs_level_columns_scanned_count || '',
    table_level_columns_scanned_count_avg:
      props.data?.INFO?.table_level_columns_scanned_count_avg || '',
  })
);
</script>
