<template>
  <div class="dui_dropdown dui_dropdown-end">
    <label tabindex="0" class="inline-flex"><slot name="label" /></label>
    <div
      tabindex="0"
      class="dui_dropdown-content dui_menu shadow bg-white dark:bg-gray-900 rounded-box w-fit h-fit"
    >
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped></style>
