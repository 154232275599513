<template>
  <div class="dui_drawer" :class="{ 'dui_drawer-mobile': on && largeScreen }">
    <input :id="name" type="checkbox" class="dui_drawer-toggle" v-model="on" />
    <div class="dui_drawer-content flex flex-col items-top justify-top">
      <slot name="main" />
    </div>
    <div class="dui_drawer-side">
      <label :for="name" class="dui_drawer-overlay"></label>
      <slot name="side" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { largeScreen } from '@/stores/uiState.js';

defineProps(['name']);

const on = ref(largeScreen.value);
</script>

<style scoped></style>
