<template>
  <div 
    class="mt-2"
    v-if="props.data?.INFO && props.data?.INFO.hasOwnProperty('ORDER QS') && props.data?.INFO['ORDER QS'].length > 0"
  >
    <span class="whitespace-pre-wrap m">In addition, we recommend updating the ORDER BY clause for the following queries.</span>
    <div>
      <div class="mt-4 mb-2 mx-2 font-semibold">Queries to update ORDER BY</div>
      <table class="dui_table table border-collapse relative w-full">
        <thead>
          <tr>
            <th>Query Signature</th>
            <th>Recommended ORDER BY</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="q in props.data?.INFO['ORDER QS']" :key="q.QS">
            <td class="link"><signature-renderer :value="q.QS" /></td>
            <td>
              <short-string-renderer :value="q['ORDER BY']"></short-string-renderer>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, inject } from 'vue';
import { i18n } from 'i18next';
import SignatureRenderer from '@/components/renderers/cellRenderers/SignatureRenderer.vue';
import ShortStringRenderer from '@/components/renderers/cellRenderers/ShortStringRenderer.vue';

const intl: i18n = inject('i18n') as i18n;
interface Props {
  data: any;
}
const props = defineProps<Props>();
</script>