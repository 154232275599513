<template>
  <li class="items-center flex" :aria-label="menuItem.label">
    <div v-if="menuItem.route" class="icon w-full">
      <router-link
        :to="{ name: menuItem.route, params: { orgUuid: currentOrg?.uuid } }"
        class="menu-item flex items-center w-full"
      >
        <Component :is="menuItem.icon" v-if="menuItem.icon" />
        <span
          class="flex-1 pl-2 text-ellipsis overflow-x-hidden"
          v-html="menuItem.label"
        />
        <div
          v-for="badge in menuItem.badges"
          :key="badge.id"
          :class="{
            'red-badge': badge.status === IndicatorLevel.RED,
            'orange-badge': badge.status === IndicatorLevel.YELLOW,
          }"
        >
          {{ badge.value }}
        </div>
        <div v-if="menuItem.path === '/alerts'">
          <AlertsCount v-if="user" />
        </div>
      </router-link>
      <div class="children" :class="{ open: isOpen }">
        <ul>
          <template v-for="item in menuItem.children">
            <side-bar-item
              :menu-item="item"
              :key="item.label"
              v-if="!item.hidden"
            />
          </template>
        </ul>
      </div>
    </div>
    <div v-else class="w-full" :class="{ category: menuItem.isCategory }">
      <a href="#" @click="toggle" class="flex items-center menu-item">
        <Component :is="menuItem.icon" class="pr-2" />
        <span class="flex-1" v-html="menuItem.label"></span>
      </a>
      <div class="children" :class="{ open: isOpen }">
        <ul>
          <template v-for="item in menuItem.children">
            <side-bar-item
              :menu-item="item"
              :key="item.label"
              v-if="!item.hidden"
            />
          </template>
        </ul>
      </div>
    </div>
  </li>
</template>

<script setup lang="ts">
import type { MenuItem } from '@/types.js';
import { currentOrg } from '@/stores/userState.js';
import { ref } from 'vue';
import { IndicatorLevel } from '@/types.js';
import AlertsCount from '@/components/base/AlertsCount.vue';
import { user } from '@/stores/userState.js';

interface Props {
  menuItem: MenuItem;
}
defineProps<Props>();
const isOpen = ref(true);
function toggle() {
  isOpen.value = !isOpen.value;
}
</script>
<style scoped>
:deep(svg) {
  @apply dark:text-gray-400 flex-shrink-0 w-5 h-5 transition duration-75 hover:text-gray-900 dark:fill-gray-200 dark:hover:text-white;
}
.menu-item {
  @apply w-full p-2 text-base font-normal text-gray-900 rounded-lg hover:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-700;
}
.children {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  margin-left: 2rem;
}
.children.open {
  max-height: 200px;
}
.router-link-active {
  font-weight: 600;
  @apply bg-sky-100 dark:bg-sky-800;
}
.red-badge {
  @apply bg-red-100 text-red-800 text-xs font-semibold ml-2 px-1.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-red-100 dark:border-red-400;
}
.orange-badge {
  @apply bg-orange-100 text-orange-800 text-xs font-semibold ml-2 px-1.5 py-0.5 rounded dark:bg-gray-700 dark:text-orange-400 border border-orange-100 dark:border-orange-400;
}
.category > .menu-item {
  @apply uppercase text-xs text-gray-500 dark:text-gray-200 font-semibold mt-2;
}
.category > .children {
  margin-left: 0;
}
</style>
