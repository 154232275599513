<template>
  <div>
    <span v-html="description" />
  </div>
</template>

<script setup lang="ts">
import { computed, inject } from 'vue';
import { i18n } from 'i18next';
import CodeTooltipRenderer from '@/components/renderers/tooltipRenderers/CodeTooltipRenderer.vue';
import {
  autoSuspend,
  maxCluster,
  warehouseTimeout,
} from '@/helpers/queryHelper.js';
const intl: i18n = inject('i18n') as i18n;

interface Props {
  data: any;
}
const props = defineProps<Props>();
const description = computed(() => {
  const type = props.data?.INFO?.type ?? 'Aggressive Autoscaling';
  return intl.t(`HOME.FINDINGS.DESCRIPTIONS.${props.data.CATEGORY}.${type}`, {
    entity: props.data?.ROOT_CAUSE_ENTITY || '',
  });
});
</script>
