<template>
  <router-link
    v-if="user"
    :to="{ name: 'alerts', params: { orgUuid: currentOrg?.uuid } }"
  >
    <div
      v-if="alertsCount > 0"
      class="px-2 py-1 text-gray-100 rounded-full bg-red-600 flex items-center justify-center text-xs"
    >
      {{ formatNumberSimple(alertsCount) }}
    </div>
  </router-link>
</template>

<script setup lang="ts">
import { api } from '@/services/apiClient';
import { globalEventBus } from '@/stores/uiState';
import { user, currentOrg } from '@/stores/userState';
import { onMounted, ref } from 'vue';
import { formatNumberSimple } from '@/helpers/formattingHelper';
const alertsCount = ref(0);

loadData();

defineExpose({ loadData });
onMounted(() => {
  globalEventBus.on('reloadAlertCount', () => loadData());
});

async function loadData() {
  const resp = await api.get('/alertsCount', {
    params: { org: currentOrg.value?.uuid },
  });
  alertsCount.value = resp.data.count;
}
</script>

<style scoped></style>
