<template>
  <popover :require-click="true" :beforePopoverShow="initDateSelections">
    <button class="soft mr-1 text-sm">
      {{ formatPlainDate(fromDate) }} &mdash; {{ formatPlainDate(toDate) }}
    </button>
    <template #content="{ close }">
      <div class="flex py-2 px-3 justify-evenly space-x-2">
        <button
          class="soft toggle-link"
          :class="{ active: selectedShortcut === DateShortcuts.Days7 }"
          @click="() => setDays(7)"
        >
          {{ $t('DATE_WIDGET.7_DAYS') }}
        </button>
        <button
          class="soft toggle-link"
          :class="{ active: selectedShortcut === DateShortcuts.Days30 }"
          @click="() => setDays(30)"
        >
          {{ $t('DATE_WIDGET.30_DAYS') }}
        </button>
        <button
          class="soft toggle-link"
          :class="{ active: selectedShortcut === DateShortcuts.Days90 }"
          @click="() => setDays(90)"
        >
          {{ $t('DATE_WIDGET.90_DAYS') }}
        </button>
        <button
          class="soft toggle-link"
          :class="{ active: selectedShortcut === DateShortcuts.Days365 }"
          @click="() => setDays(365)"
        >
          {{ $t('DATE_WIDGET.1_YEAR') }}
        </button>
      </div>
      <div class="w-full flex items-center justify-center text-xs">
        <icon-clock class="w-4 mr-1" />
        Times set to {{ timeZone }}.
      </div>
      <div class="flex items-center justify-center py-4 px-1">
        <span class="text-muted font-semibold">{{ $t('COMMON.FROM') }}</span>
        <date-picker
          v-model="fromDateDraft"
          class="mx-4"
          @click="selectedShortcut = null"
        />
        <span class="text-muted font-semibold">to</span>
        <date-picker
          v-model="toDateDraft"
          class="mx-4"
          @click="selectedShortcut = null"
        />
        <button
          class="primary"
          @click="
            () => {
              updateDates();
              close();
            }
          "
        >
          {{ $t('COMMON.UPDATE') }}
        </button>
      </div>
    </template>
  </popover>
</template>
<script setup lang="ts">
import Popover from '@/components/base/Popover.vue';
import DatePicker from '@/components/base/DatePicker.vue';
import { inject, ref } from 'vue';
import { dateShortcut, fromDate, toDate } from '@/stores/chartState.js';
import {
  dateToInstant,
  endOfDay,
  nowDate,
  nowInstant,
  startOfDay,
  startOfMonth,
  startOfYear,
} from '@/helpers/dateHelper.js';
import { showToast } from '@/helpers/toastHelper.js';
import { ToastType } from '@/types.js';
import { formatPlainDate } from '@/helpers/formattingHelper.js';
import { DateShortcuts } from '@/types.js';
import IconClock from '@/components/icons/IconClock.vue';
import { timeZone } from '../stores/uiState.js';

const i18n = inject('i18n') as any;
const toDateDraft = ref(toDate.value);
const fromDateDraft = ref(fromDate.value);
const selectedShortcut = ref(dateShortcut.value);

function initDateSelections(): Promise<void> {
  return new Promise((resolve, reject) => {
    selectedShortcut.value = null;
    toDateDraft.value = toDate.value;
    fromDateDraft.value = fromDate.value;
    resolve();
  });
}
function setDays(days: number) {
  selectedShortcut.value = DateShortcuts[`Days${days}`];
  toDateDraft.value = endOfDay(nowInstant());
  fromDateDraft.value = startOfDay(nowInstant().subtract({ hours: 24 * days }));
}
function setMonthToNow() {
  selectedShortcut.value = DateShortcuts.MonthToNow;
  toDateDraft.value = endOfDay(nowInstant());
  fromDateDraft.value = startOfDay(dateToInstant(startOfMonth(nowDate())));
}
function setYearToNow() {
  selectedShortcut.value = DateShortcuts.YearToNow;
  toDateDraft.value = endOfDay(nowInstant());
  fromDateDraft.value = startOfDay(dateToInstant(startOfYear(nowDate())));
}
function updateDates() {
  // Show warning & prevent changes if user selected a start date > end date.
  if (
    fromDateDraft.value.epochMilliseconds > toDateDraft.value.epochMilliseconds
  ) {
    showToast(ToastType.WARNING, i18n.t('WARNING.START_DATE_GT_END_DATE'), 5);
    return;
  }
  dateShortcut.value = selectedShortcut.value;
  toDate.value = endOfDay(toDateDraft.value);
  fromDate.value = startOfDay(fromDateDraft.value);
}
</script>

<style scoped>
.toggle-button {
  @apply w-36 inline-flex justify-center items-center border-2 p-2 text-gray-500 rounded-lg border-gray-200 cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-white peer-checked:border-black dark:peer-checked:border-white peer-checked:text-black peer-checked:bg-white dark:peer-checked:bg-gray-800 hover:text-gray-600 hover:bg-gray-50 dark:text-gray-400 bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-800 peer-checked:font-semibold;
}
.toggle-link {
  @apply text-xs m-1 py-2 px-3 font-normal;
}
</style>
