import {
  endOfDay,
  startOfDay,
  startOfMonthInstant,
} from '@/helpers/dateHelper';
import { load, store } from '@/services/localStorage.js';
import { nextTick, ref, watch } from 'vue';

import { CostUnit, DateShortcuts } from '@/types.js';
import { Temporal } from 'temporal-polyfill';
import type { TooltipInfo } from '@/types.js';
import { getUrlParam, setUrlParam } from '@/helpers/urlHelper.js';
import router from '@/routes.js';

export const chartTooltipData = ref<TooltipInfo | null>(null);

const fromDateKey = 'fromDate';
const toDateKey = 'toDate';
const dateShortcutKey = 'dateShortcut';
const costUnitKey = 'costUnit';
const departmentKey = 'department';
const today = endOfDay(Temporal.Now.zonedDateTimeISO('UTC').toInstant());

// Initialize "From Date"
const serializedFromDate = getUrlParam(fromDateKey) ?? load(fromDateKey);
export const fromDate = ref(
  serializedFromDate
    ? Temporal.Instant.from(serializedFromDate)
    : Temporal.Instant.from(
        startOfDay(today.subtract({ hours: 24 * 7 }))
      )
);

// Initialize "To Date"
const serializedToDate = getUrlParam(toDateKey) ?? load(toDateKey);
export const toDate = ref(
  serializedToDate ? Temporal.Instant.from(serializedToDate) : today
);

export const department = ref(load(departmentKey) ?? null);

// Initialize "Date Shortcut"
export const dateShortcut = ref(load(dateShortcutKey) ?? DateShortcuts.Days7);

// Initialize "Cost Unit"
export const costUnit = ref<CostUnit>(/*load(costUnitKey) ??*/ CostUnit.DOLLAR);

watch(fromDate, newDate => {
  const date = startOfDay(newDate);
  setUrlParam(fromDateKey, date);
  store(fromDateKey, date);
});
watch(toDate, newDate => {
  const date = endOfDay(newDate);
  setUrlParam(toDateKey, date);
  store(toDateKey, date);
});
watch(router.currentRoute, () => {
  setUrlParam(toDateKey, toDate.value);
  setUrlParam(fromDateKey, fromDate.value);
});
watch(dateShortcut, newDateShortcut => store(dateShortcutKey, newDateShortcut));
watch(costUnit, newUnit => store(costUnitKey, newUnit));
watch(department, newDep => store(departmentKey, newDep));
