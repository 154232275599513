<template>
  <div>
    <span class="font-semibold">{{ $t('COMMON.RECOMMENDATION') }}:&nbsp;</span
    ><span v-html="recommendation" />
  </div>
</template>

<script setup lang="ts">
import { computed, inject } from 'vue';
import { i18n } from 'i18next';
import CodeTooltipRenderer from '@/components/renderers/tooltipRenderers/CodeTooltipRenderer.vue';
import { autoSuspend, warehouseTimeout } from '@/helpers/queryHelper.js';
const intl: i18n = inject('i18n') as i18n;

interface Props {
  data: any;
}
const props = defineProps<Props>();
const recommendation = computed(() =>
  intl.t(`HOME.FINDINGS.FIXES.${props.data.CATEGORY}`, {
    entity: props.data.ROOT_CAUSE_ENTITY,
    to: props.data.INFO['Stmt timeout in secs'],
  })
);

const code = computed(() =>
  warehouseTimeout(props.data.INFO, props.data.ROOT_CAUSE_ENTITY)
);
</script>
